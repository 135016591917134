<div class="text-center">
  <ng-container *ngIf="!widerruf">
    <h5>Vielen Dank!</h5>
    <p>
      Sie haben Ihre Teilnahme am Versorgungsvertrag erfolgreich bestätigt.<br>
      Ihre Teilnahmeerklärung können Sie innerhalb der nächsten 14 Tage herunterladen.<br>
      Sie erhalten gleich auch noch eine E-Mail zur Bestätigung.
    </p>
  </ng-container>
  <ng-container *ngIf="widerruf">
    <h5>Sie sind eingeschrieben.</h5>
    <p>
      Sie haben Ihre Teilnahme am Versorgungsvertrag erfolgreich bestätigt.<br>
      Ihre Teilnahmeerklärung können Sie ab dem {{state?.sign_datetime|amDateFormat:'DD.MM.YYYY'}} 14 Tage lang
      herunterladen.<br>
      Innerhalb dieses Zeitraums können Sie hier Ihre Teilnahme widerrufen.
    </p>
  </ng-container>
  <div>
    <button class="btn btn-primary" [disabled]="!teilnahmeerklaerung" (click)="downloadTneClicked.emit()">
      Teilnahmeerklärung<span>&nbsp;<fa-icon *ngIf="!teilnahmeerklaerung" [icon]="loadingIcon" animation="spin-pulse"></fa-icon></span>
    </button><br class="d-md-none"/>
    <button *ngIf="widerruf" class="btn btn-primary ms-md-3 mt-3 mt-md-0" (click)="widerrufClicked.emit()">
      Teilnahme widerrufen
    </button><br class="d-md-none"/>
    <button class="btn btn-primary ms-md-3 mt-3 mt-md-0" (click)="abmeldenClicked.emit()">
      Abmelden
    </button>
  </div>
</div>
