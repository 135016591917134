import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'san-address-daten-eingabe',
  templateUrl: './address-daten-eingabe.component.html',
  styleUrls: ['./address-daten-eingabe.component.scss']
})
export class AddressDatenEingabeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
