<div class="modal-body">
  <p class="p-3 text-center">
    <button type="button" class="close p-2" aria-label="schließen"
            (click)="alsWeggeklicktMarkieren()">
      <span aria-hidden="true">&times;</span>
    </button>
  </p>
  <p class="text-center">
    <img src="assets/logos/lexonline-rot-grau.png" alt="Lex Online" class="img-fluid logo">
  </p>
  <h4 class="p-3 text-center">
    Die neue Standard Version von Lex Online ist ab sofort für Sie aktiviert.
  </h4>
  <p class="p-3 text-justify">
    Wir arbeiten derzeit an der Veröffentlichung einer kostenpflichtigen <b>Premium-Version</b> unseres Dokumentenservice <b>Lex
    Online</b>.
    Unser Lex Online <b>Premium Angebot</b> wird gegenüber der Standard-Version <b>viele zusätzliche Funktionen</b> bieten,
    <b>wie beispielsweise einen automatisierten Hinweis auf neue Dokumente, eine Volltextsuche, ein umfassendes Dokumentenarchiv und einige weitere Funktionen für die professionelle Nutzung</b>. Bleiben
    Sie also
    immer „up-to-date“ und gehören Sie zu den ersten, die Informationen über Initiativen aus dem gesundheitspolitischen politischen Berlin erhalten.
    Nutzen Sie die zusätzlichen Möglichkeiten unseres Premium-Angebots für Ihre Arbeit!
  </p>
  <p class="p-3 text-justify">
    Wenn Sie schon jetzt Interesse an der <b>kostenpflichtigen Premium-Version von Lex Online haben</b>, schreiben Sie
    uns auf
    <a href="mailto:lexonline@sanakey.de">lexonline@sanakey.de</a>. Wir vereinbaren dann gerne einen individuellen
    Termin.
  </p>
  <p class="p-3 text-center">
    <span class="form-check">
      <label>
      <input type="checkbox" [(ngModel)]="bestaetigen">
        &nbsp;Nicht noch ein Mal anzeigen</label>
    </span>
    <span class="btn btn-primary" (click)="schliessen(bestaetigen);geheZuLexOnline()">Zu Lex Online</span>
  </p>
  <!--  <div class="pics">-->
  <!--    <img class="pic" src="assets/popups/difa-fachaerztetag-2021/Duo.jpg">-->
  <!--  </div>-->
</div>
