import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PreviewResponse} from "../../api/client.service";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'san-widerruf-partner',
  templateUrl: './widerruf-partner.component.html',
  styleUrls: ['./widerruf-partner.component.scss']
})
export class WiderrufPartnerComponent implements OnInit {
  @Input() token: string;
  @Input() pin: string;
  @Input() state: PreviewResponse;
  @Input() sending = false;

  @Output() bestaetigenClicked = new EventEmitter();
  @Output() abmeldenClicked = new EventEmitter();

  spinnerIcon = faSpinner;

  constructor() {
  }

  ngOnInit(): void {
  }

  onBestaetigenClicked() {
    if (this.sending) return;
    this.bestaetigenClicked.emit()
  }

}
