import {Ordering} from './reducer.includes';
import {ArztEinschreibungOrdering} from '../versorgungsvertraege/api/models';

export class PaginatedResult<T, O> {
  ordering: Ordering<O>;
  pageNumber: number;
  pageSize: number;
  count: number;
  previous: string;
  next: string;
  results: T[];
}

