<h4>Herzlich Willkommmen im Sanakey-Portal!</h4>
<p>
  Bitte mel&shy;den Sie sich mit Ihren persön&shy;lichen Daten an.
  An&shy;schließ&shy;end können Sie Ihre digitale Teil&shy;nahme&shy;erklärung verwalten. Sie können z.B. Ihre Teil&shy;nahme&shy;erklärung
  her&shy;unter&shy;laden, sowie Ihre Teil&shy;nahme am Ver&shy;sor&shy;gungs&shy;ver&shy;trag wider&shy;rufen.
  Bitte geben Sie zuerst Ihre Ver&shy;sicherten&shy;nummer (diese fin&shy;den Sie z.B. auf Ihrer Ver&shy;sicherten&shy;karte),
  Ihr Geb&shy;urts&shy;datum und Ihren Nach&shy;namen ein.
</p>
<div class="d-flex justify-self-center align-self-center flex-column login-container-width" [formGroup]="form">
  <div class="w-100">
    <label>Krankenversichertennummer</label>
    <san-tooltip
      #tooltip
      text="Ihre Versichertennummer finden Sie z.B. auf der Vorderseite Ihrer Krankenversichertenkarte. Diese ist 10-stellig und beginnt mit einem Großbuchstaben gefolgt von 9 Ziffern."></san-tooltip>
    <input class="form-control" type="text" placeholder="Ihre Krankenversichertennummer"
           formControlName="versicherten_nummer">
  </div>
  <div class="text-center">
    <san-form-input-error
      [visible]="!form.controls.versicherten_nummer.pristine && form.controls.versicherten_nummer.errors && form.controls.versicherten_nummer.errors.required"
      message="Bitte geben Sie Ihre Versichertennummer an"></san-form-input-error>
    <san-form-input-error
      [visible]="!form.controls.versicherten_nummer.pristine && form.controls.versicherten_nummer.errors && form.controls.versicherten_nummer.errors.gueltigeKVNR"
      message="Die angegebene Versichertennummer ist nicht gültig"></san-form-input-error>
  </div>
  <div class="w-100 mt-3">
    <label>Geburtsdatum</label>
    <input class="form-control" type="date" placeholder="Ihr Geburtsdatum" formControlName="geburtsdatum">
  </div>
  <div class="text-center">
    <san-form-input-error
      [visible]="!form.controls.geburtsdatum.pristine && form.controls.geburtsdatum.errors && form.controls.geburtsdatum.errors.required"
      message="Bitte geben Sie Ihr Geburtsdatum an"></san-form-input-error>
  </div>
</div>
<table class="table table-borderless mb-0">
  <tr>
    <td>
      <input [disabled]="sending" id="cbxBedingungen" type="checkbox" [(ngModel)]="akzeptiertBedingungen"
             (click)="btnAnmelden.scrollIntoView({behavior: 'smooth'})">
    </td>
    <td>
      <label for="cbxBedingungen">
        Mit Ein&shy;ga&shy;be meiner Kranken&shy;ver&shy;sich&shy;erten&shy;nummer, mein&shy;es Geb&shy;urts&shy;datums
        und der An&shy;mel&shy;dung be&shy;stä&shy;ti&shy;ge ich, dass ich die <a
        [href]="hrefDatenschutzbestimmungen" target="_blank">Da&shy;ten&shy;schutz&shy;be&shy;stim&shy;mun&shy;gen</a>
        und <a [href]="hrefNutzungsbedingungen" target="_blank">Nut&shy;zungs&shy;be&shy;din&shy;gun&shy;gen</a> des Sa&shy;na&shy;key
        Por&shy;tals ge&shy;le&shy;sen und ak&shy;zep&shy;tiert ha&shy;be.
      </label>
    </td>
  </tr>
</table>
<div class="text-center">
  <button #btnAnmelden class="btn btn-primary" [disabled]="sending || !form.valid"
          (click)="onAnmeldenClicked()">
    Anmelden<span *ngIf="sending">&nbsp;<fa-icon [icon]="spinnerIcon" animation="spin-pulse"></fa-icon></span>
  </button>
</div>
