import {
  AfterContentChecked,
  AfterContentInit, AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {HideInfoIconAction, ShowInfoIconAction} from '../reducers/header.reducer';
import {Router} from '@angular/router';
import {BenutzermanagementAPIService} from '../api/benutzermanagement/benutzermanagement-api.service';
import {AppToastService} from '../toast/app-toast.service';
import {Actions, ofType} from '@ngrx/effects';
import {AuthinfoActionTypes, getBenutzerInfoFeatureState} from '../reducers/authinfo.reducer';
import {faLock, faUserPlus} from '@fortawesome/free-solid-svg-icons';
import {LoginBoxComponent} from './login-box/login-box.component';
import {take} from 'rxjs/operators';
import {FooterModalsService} from '../shared/footer-modals.service';
import {Observable, of, Subscription} from 'rxjs';
import {BilderResponseElement} from '../api/benutzermanagement/models';

@Component({
  selector: 'san-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(public modalService: NgbModal, private store: Store, private router: Router,
              private benutzermanagementApi: BenutzermanagementAPIService, private toastService: AppToastService,
              private actions: Actions, private footerService: FooterModalsService) {
  }
  @HostBinding('class.flex-fill') cFlexFill = true;
  @HostBinding('class.bg-light') cBgLight = true;
  @HostBinding('class.p-1') cPaddingMobile = true;
  @HostBinding('class.px-md-5') cPadding = true;
  @HostBinding('class.pt-md-2') cPaddingT = true;
  @HostBinding('class.overflow-x-hidden') cOverflowXHidden = true;

  @ViewChild('top') topElem;

  newUserIcon = faUserPlus;
  passwordIcon = faLock;

  public carouselTileItems: BilderResponseElement[] = [];

  private loginModalRef: NgbModalRef;
  private authDataActions$: Subscription;
  nVisibleCarouselItems = 3;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calculateVisibleCarouselItems(event.target.innerWidth);
  }

  ngAfterViewInit(): void {
    this.topElem.nativeElement.scrollIntoView(); // FIXME: why does angular scroll to bottom???? (without this)
  }

  ngOnInit(): void {
    this.store.dispatch(new ShowInfoIconAction());
    if (this.benutzermanagementApi.getNextAfterLogin()) {
      this.openLoginModal();
    }
    this.benutzermanagementApi.getKarussellBilder().subscribe(value => {
      this.carouselTileItems = value;
    });
    this.authDataActions$ = this.store.select(getBenutzerInfoFeatureState).subscribe(value => {
      if (value.auth.isAuthenticated && !this.loginModalRef) {
        this.router.navigate(['dashboard']);
      }
    });
    this.calculateVisibleCarouselItems(window.innerWidth);
  }

  ngOnDestroy(): void {
    this.store.dispatch(new HideInfoIconAction());
    this.authDataActions$?.unsubscribe();
  }

  openLoginModal() {
    this.loginModalRef = this.modalService.open(LoginBoxComponent, {centered: true, backdrop: 'static'});
    const lbcomp = this.loginModalRef.componentInstance as LoginBoxComponent;
    lbcomp.showTitle = 'Anmeldung';
    lbcomp.allowClose = true;
    lbcomp.setUsernameFocus();
  }

  openDatenschutz() {
    this.footerService.openDatenschutz();
  }

  private calculateVisibleCarouselItems(innerWidth: number) {
    // Bootstrap:
    // xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px
    if (innerWidth < 768) {
      this.nVisibleCarouselItems = 1;
    } else if (innerWidth < 992) {
      this.nVisibleCarouselItems = 2;
    } else {
      this.nVisibleCarouselItems = 3;
    }
  }
}
