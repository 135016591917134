<div class="d-flex flex-column h-100 w-100">
  <div class="step-numbers p-1 p-md-3 flex-shrink-0" *ngIf="showSteps">
    <div *ngFor="let step of steps;  index as i; last as last"
         [ngClass]="{'flex-grow-1': !last, 'flex-shrink-1': last, 'active': currentStepIndex == i, 'disabled': step.disabled, 'error': step.error}"
         [class.autoBubbleSize]="autoBubbleSize" [class.autoFontSize]="autoFontSize"
         class="step-number-container d-flex flex-row align-items-center">
      <div class="step-number d-flex align-items-center justify-content-center" [class.with-label]="step.label"
           (click)="!steps[i].disabled && goToStep(i)">
        <span class="the-step-number" *ngIf="step.showNumberOrIcon && !step.icon">{{i + 1}}</span>
        <span class="the-step-icon" *ngIf="step.showNumberOrIcon && step.icon"><fa-icon [icon]="step.icon"
                                                                                        [pulse]="step.iconPulse"></fa-icon></span>
        <span class="the-step-label-whitespace" *ngIf="step.showNumberOrIcon && step.label">&nbsp;</span>
        <span class="the-step-label" *ngIf="step.label">{{step.label}}</span>
      </div>
      <div *ngIf="!last" class="step-numbers-divider m-1 m-md-2"></div>
    </div>
  </div>
  <div class="step-container m-3 flex-grow-1">
    <div class="step-window position-relative h-100 d-flex flex-row"
         [ngStyle]="{width: (nSteps * 100) + '%', left: (-currentStepIndex * 100) + '%'}">
      <div class="step-content" *ngFor="let step of steps;last as last;index as index"
           [class.on]="visibility[index]"
           [class.off]="!visibility[index]"
           [ngStyle]="{width: (100 / nSteps) + '%'}">
        <ng-container *ngTemplateOutlet="step.template"></ng-container>
      </div>
    </div>
  </div>
  <!-- Spacer -->
  <div class="d-none d-lg-block flex-grow-1"></div>
  <!-- Bottom buttons (forward & back, abort, ...) -->
  <div class="my-0 m-lg-3 p-2 d-flex flex-row flex-shrink-0" *ngIf="!hideBottomButtons">
    <span *ngIf="leftButtonText" (click)="onLeftButtonClicked()" class="btn btn btn-primary"
       [ngClass]="{disabled: leftButtonDisabled}">{{leftButtonText}}</span>
    <div class="flex-fill"></div>
    <button *ngIf="rightButtonText" (click)="onRightButtonClicked()" class="btn btn btn-primary" type="submit"
            [ngClass]="{disabled: rightButtonDisabled, animated:rightButtonAnimated}">
      <fa-icon class="position-absolute" [@vis]="rightButtonAnimated ? 'visible' : 'hidden'" [icon]="iconSpinner"
               animation="spin-pulse"></fa-icon>
      <span [@mr]="rightButtonAnimated ? 'visible' : 'hidden'">{{rightButtonText}}</span>
    </button>
  </div>
</div>
