<div *ngIf="loading">
  Lade...
</div>
<san-stepper #stepperComponent [showSteps]="false"
             [hideBottomButtons]="true"
             [leftButtonText]="leftButtonText" [rightButtonText]="rightButtonText">
  <san-step>
    <p>
      {{anrede}},
    </p>
    <p>
      ab sofort ist die Angabe Ihrer Praxis-Telefonnummer notwendig.
    </p>
    <p>
      Bitte tragen Sie diese hier ein, bevor Sie das Sanakey-Portal weiter nutzen.
    </p>
    <p>
      <san-stammdaten #stammdatenComponent
                      [speichernBtnVisible]="false" [onlyKeys]="abzufragendeKeys"
                      (data-ready)="onDataReady($event)" [forceRequired]="this.forceRequiredKeys"
                      (saved)="onDataSaved()" (saveError)="onDataSaveError()"
      ></san-stammdaten>
    </p>
    <p>
      <button [disabled]="stammdatenComponent.processingAnyData"
              class="btn btn-primary"
              (click)="speichern()">Telefonnummer hinterlegen<span *ngIf="stammdatenComponent.processingAnyData">
      &nbsp;<fa-icon [icon]="faSpinner" animation="spin-pulse"></fa-icon></span></button>
    </p>
    <p>
      Vielen Dank!
    </p>
    <div>
      Für Rückfragen stehen wir gern zur Verfügung:
      <a href="mailto:service@sanakey-portal.de">service@sanakey-portal.de</a>
    </div>
  </san-step>
  <san-step>
  </san-step>
</san-stepper>
