<div class="row">
  <a class="col-8 offset-2 offset-lg-0 col-lg-4 p-4" target="_blank" href="https://www.sanakey.de/">
    <img src="assets/pictures/landing/logos/aevgd.svg" alt="ÄVGD" height="210">
  </a>
  <a class="col-8 offset-2 offset-lg-0 col-lg-4 p-4" target="_blank" href="https://difa-vf.de/">
    <img src="assets/logos/difa.png" alt="DIFA" height="210">
  </a>
  <a class="col-8 offset-2 offset-lg-0 col-lg-4 p-4" target="_blank" href="https://difa-research.de/">
    <img src="assets/pictures/landing/logos/difa-research.svg" alt="DIFA Research" height="210">
  </a>
</div>
