import {AbstractControl} from '@angular/forms';
import {isValidIBAN} from 'ibantools';


export function notEquals(value: any) {
  return (control: AbstractControl) => {
    if (control && control.value === value) {
      return {
        notEquals: true,
      };
    }
    return null;
  };
}

export function ibanValidator(control: AbstractControl) {
  if (control && control.value && control.value.toUpperCase) {
    const value = control.value.toUpperCase().replaceAll(' ', '');
    const valid = isValidIBAN(value);
    if (!valid) {
      return {
        iban: true
      };
    }
  }
  return null;
}
