import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PreviewResponse} from '../../api/client.service';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'san-zustimmung-bestaetigung-digi-tne-zwei',
  templateUrl: './zustimmung-bestaetigung-digi-tne-zwei.component.html',
  styleUrls: ['./zustimmung-bestaetigung-digi-tne-zwei.component.scss']
})
export class ZustimmungBestaetigungDigiTneZweiComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  @Input() versicherten_nummer: string;
  @Input() geburtsdatum: string;
  @Input() teilnahmeerklaerung = false;
  @Input() widerruf = false;
  @Input() state: PreviewResponse;

  @Output() abmeldenClicked = new EventEmitter();
  @Output() widerrufClicked = new EventEmitter();
  @Output() downloadTneClicked = new EventEmitter();

  loadingIcon = faSpinner;

  constructor() {
  }

  ngOnInit(): void {
  }

}
