import {Component, HostBinding, OnInit} from '@angular/core';
import {faBars, faEuroSign, faFileAlt, faPlay, faPlus, faSpinner} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'san-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  @HostBinding('class.d-block') dblock = true;
  @HostBinding('class.p-3') pad = true;
  @HostBinding('class.h-100') h100 = true;

  spinnerIcon = faSpinner;
  iconAdd = faPlus;
  iconStart = faPlay;
  iconBars = faBars;
  iconEuros = faEuroSign;
  iconDokumente = faFileAlt;

  constructor() { }

  ngOnInit(): void {
  }

}
