import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'san-fehler-arzt-nicht-eingeschrieben',
  templateUrl: './fehler-arzt-nicht-eingeschrieben.component.html',
  styleUrls: ['./fehler-arzt-nicht-eingeschrieben.component.scss']
})
export class FehlerArztNichtEingeschriebenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
