<h4>Herzlich Willkommmen im Sanakey-Portal!</h4>
<p>
  Sie kön&shy;nen sich nun in den Ver&shy;sor&shy;gungs&shy;ver&shy;trag ein&shy;schrei&shy;ben. Bit&shy;te ge&shy;ben
  Sie zu&shy;erst Ih&shy;ren per&shy;sön&shy;li&shy;chen PIN ein (die&shy;sen ha&shy;ben Sie per SMS er&shy;hal&shy;ten)
  und be&shy;stä&shy;ti&shy;gen mit „An&shy;mel&shy;den“. <br>
  An&shy;schlie&shy;ßend er&shy;hal&shy;ten Sie al&shy;le In&shy;for&shy;ma&shy;tio&shy;nen
  zum Ver&shy;sor&shy;gungs&shy;ver&shy;trag, den The&shy;ra&shy;pi&shy;ein&shy;hal&shy;ten und die Teil&shy;nah&shy;me&shy;be&shy;din&shy;gun&shy;gen.
</p>
<div class="d-flex justify-content-center">
  <div class="pin-input-container">
    <label>PIN (per SMS erhalten)</label>
    <code-input [isCodeHidden]="false"
                [codeLength]="5"
                [isCharsCode]="true"
                inputType="text"
                (codeChanged)="onCodeChanged($event)"
                (codeCompleted)="onCodeCompleted($event)">
    </code-input>
  </div>
</div>
<div class="text-center">
  <san-form-input-error
    [visible]="wrongCredentials"
    message="Die Daten sind nicht korrekt oder die Einschreibung wurde zwischenzeitlich deaktiviert"></san-form-input-error>
</div>
<table class="table table-borderless mb-0">
  <tr>
    <td>
      <input [disabled]="sending" id="cbxBedingungen" type="checkbox" [(ngModel)]="akzeptiertBedingungen"
             (click)="btnAnmelden.scrollIntoView({behavior: 'smooth'})">
    </td>
    <td>
      <label for="cbxBedingungen">
        Mit Ein&shy;ga&shy;be der PIN und der An&shy;mel&shy;dung be&shy;stä&shy;ti&shy;ge ich, dass ich die <a [href]="hrefDatenschutzbestimmungen" target="_blank">Da&shy;ten&shy;schutz&shy;be&shy;stim&shy;mun&shy;gen</a>
        und <a [href]="hrefNutzungsbedingungen" target="_blank">Nut&shy;zungs&shy;be&shy;din&shy;gun&shy;gen</a> des Sa&shy;na&shy;key Por&shy;tals ge&shy;le&shy;sen und ak&shy;zep&shy;tiert
        ha&shy;be.
      </label>
    </td>
  </tr>
</table>
<div class="text-center">
  <button #btnAnmelden class="btn btn-primary" [disabled]="sending || !code || code.length !== 5 || !akzeptiertBedingungen"
          (click)="onAnmeldenClicked()">
    Anmelden<span *ngIf="sending">&nbsp;<fa-icon [icon]="spinnerIcon" animation="spin-pulse"></fa-icon></span>
  </button>
</div>
