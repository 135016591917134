import {Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {interval, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'san-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent<T> implements OnInit, OnChanges, OnDestroy {
  @Input() elements: T[]; // the input elements
  @Input() template: TemplateRef<any>; // the input elements
  @Input() visibleElements = 1; // the amount of visible elements
  @Input() transmissionDuration = 400; // the switch from current to next state takes this time ~1/speed
  @Input() waitingDuration = 2000; // [ms] switch to next images(s) after this time

  elementWidth = 100; // [%] width of the element w.r.t. the parent
  containerWidth = 100; // [%] width of the container w.r.t. the parent
  realElementWidth = 100; // [%] width of the element w.r.t. the "container"

  marginLeft = 0; // [%]
  runAnimation = false; // true iff the margin left should be animated

  private interval: Observable<number>;
  private intervalSubscription: Subscription;

  constructor() {
  }

  ngOnDestroy(): void {
    this.intervalSubscription?.unsubscribe();
  }

  ngOnInit() {
    this.initInterval();
  }

  initInterval() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    this.interval = interval(this.waitingDuration + this.transmissionDuration);
    this.intervalSubscription = this.interval.subscribe(this.nextTick.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.visibleElements || changes.elements) && this.elements && this.elements.length) {
      this.elementWidth = 100 / this.visibleElements;
      this.containerWidth = this.elements.length * this.elementWidth;
      this.realElementWidth = 100 / this.elements.length;
    }

    if (changes.waitingDuration || changes.transmissionDuration) {
      this.initInterval();
    }
  }

  private nextTick() {
    if (this.elements.length <= this.visibleElements) {
      return;
    }
    this.runAnimation = true;
    this.marginLeft = -this.elementWidth;
    setTimeout(() => {
      this.runAnimation = false;
      this.marginLeft = 0;
      const head = this.elements.splice(0, 1);
      this.elements.push(head[0]);
    }, this.transmissionDuration);
  }

  PauseInterval() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }
  }

  ResumeInterval() {
    this.initInterval();
  }
}
