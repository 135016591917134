import {Injectable} from '@angular/core';
import {SideBarComponent} from './side-bar/side-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SideBarService {
  private instance: SideBarComponent;

  constructor() {
  }

  setInstance(param: SideBarComponent) {
    this.instance = param;
  }

  toggle() {
    this.instance.active = !this.instance.active;
  }

  hide() {
    this.instance.active = true;
  }

  show() {
    this.instance.active = false;
  }
}
