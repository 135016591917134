import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {PreviewResponse} from "../../api/client.service";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'san-widerruf-bestaetigung',
  templateUrl: './widerruf-bestaetigung.component.html',
  styleUrls: ['./widerruf-bestaetigung.component.scss']
})
export class WiderrufBestaetigungComponent implements OnInit {
  @Input() teilnahmeerklaerung: boolean;
  @Input() state: PreviewResponse;
  @Output() downloadTneClicked = new EventEmitter();
  @Output() abmeldenClicked = new EventEmitter();

  loadingIcon = faSpinner;

  constructor() {
  }

  ngOnInit(): void {
  }

}
