import {Injectable} from '@angular/core';

export interface ToastConfig {
    header: string;
    body: string;
    toastType: string;
    delay?: number;
}

@Injectable({
    providedIn: 'root'
})
export class AppToastService {
    toasts: ToastConfig[] = [];

    show(header: string, body: string, toastType: 'info' | 'success' | 'warning' | 'danger' = 'info', delay = 5000) {
        this.toasts.push({header, body, toastType, delay});
    }

    remove(toast) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }

    constructor() {
    }
}
