import {Injectable} from '@angular/core';
import {Actions, createEffect, CreateEffectMetadata, ofType} from '@ngrx/effects';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {EMPTY, Observable, pipe} from 'rxjs';
import {
  ExtradataInstanceActionTypes,
  ExtradataInstancesPageLoadedAction,
  KrankenkassenDataActionTypes,
  LastExtradataInstancesPageLoadedAction,
  LoadedKrankenkassenDataAction,
  LoadedVisibleExtradataPageAction,
  LoadExtradataInstancesPageAction,
  LoadingExtradataInstancesPageAction,
  LoadingKrankenkassenDataAction,
  LoadingVisibleExtradataPageAction,
  LoadKrankenkassenDataAction,
  LoadVisibleExtradataPageAction,
  RefreshExtradataInstancesAction,
  VisibleExtradataActionTypes
} from './userdata.reducer';
import {BenutzermanagementAPIService} from '../api/benutzermanagement/benutzermanagement-api.service';
import {AuthinfoActionTypes, SetAuthenticatedAction} from './authinfo.reducer';
import {ClientService} from "../versorgungsvertraege/api/client.service";
import {KrankenkassenData} from "../api/benutzermanagement/models";

@Injectable()
export class ExtradataInstanceEffects {

    // Effects

    // // noinspection JSUnusedGlobalSymbols
    // afterAuthenticated$ = createEffect(() => this.actions$.pipe(
    //   ofType(AuthinfoActionTypes.SetAuthenticated),
    //   map(r => {
    //     this.store.dispatch(new RefreshExtradataInstancesAction());
    //     this.store.dispatch(new LoadVisibleExtradataPageAction());
    //     return r;
    //   })
    // ));

    // noinspection JSUnusedGlobalSymbols
    fetchExtradataInstances$: Observable<ExtradataInstancesPageLoadedAction> & CreateEffectMetadata;

    // noinspection JSUnusedGlobalSymbols
    refreshExtradataInstances$: Observable<LoadExtradataInstancesPageAction> & CreateEffectMetadata;

    // noinspection JSUnusedGlobalSymbols
    fetchVisibleExtradata: Observable<LoadedVisibleExtradataPageAction> & CreateEffectMetadata;

    constructor(private actions$: Actions, private store: Store, private benutzerApi: BenutzermanagementAPIService) {
        this.fetchExtradataInstances$ = createEffect(() => this.actions$.pipe(
            ofType<LoadExtradataInstancesPageAction>(ExtradataInstanceActionTypes.LoadExtradataInstances),
            map((r) => {
                this.store.dispatch(new LoadingExtradataInstancesPageAction(r.page, r.pageSize, r.ordering));
                return r;
            }),
            mergeMap((r) => this.benutzerApi.getUserdata(r.page, r.pageSize, r.ordering).pipe(
                map(p => {
                    if (p.next) {
                        this.store.dispatch(new LoadExtradataInstancesPageAction(p.pageNumber + 1, p.pageSize, p.ordering));
                    } else {
                        this.store.dispatch(new LastExtradataInstancesPageLoadedAction(p.pageNumber, p.pageSize, p.ordering));
                    }
                    return p;
                }),
                map(s => new ExtradataInstancesPageLoadedAction(s)),
                catchError(() => EMPTY),
            )),
        ));
        this.refreshExtradataInstances$ = createEffect(() => this.actions$.pipe(
            ofType<RefreshExtradataInstancesAction>(ExtradataInstanceActionTypes.RefreshExtradataInstances),
            map(r => new LoadExtradataInstancesPageAction(1, r.pageSize, r.ordering))));

        // noinspection JSUnusedGlobalSymbols
        this.fetchVisibleExtradata = createEffect(() => this.actions$.pipe(
            ofType<LoadVisibleExtradataPageAction>(VisibleExtradataActionTypes.LoadVisibleExtradatas),
            map((r) => {
                this.store.dispatch(new LoadingVisibleExtradataPageAction());
                return r;
            }),
            mergeMap((r) => this.benutzerApi.getVisibleExtradata().pipe(
                map(s => new LoadedVisibleExtradataPageAction(s)),
                catchError(() => EMPTY),
            )),
        ));
    }
}

@Injectable()
export class KrankenkassenDataEffects {
  fetchKrankenkassenData$: Observable<LoadedKrankenkassenDataAction> & CreateEffectMetadata;

  constructor(private actions$: Actions, private store: Store, private benutzerApi: BenutzermanagementAPIService) {
    this.fetchKrankenkassenData$ = createEffect(() => this.actions$.pipe(
      ofType<LoadKrankenkassenDataAction>(KrankenkassenDataActionTypes.LoadKrankenkassenData),
      map((r) => {
        this.store.dispatch(new LoadingKrankenkassenDataAction());
        return r;
      }),
      mergeMap((r) => this.benutzerApi.getKrankenkasse(r.ik).pipe(
        map((s: KrankenkassenData) => {
          return new LoadedKrankenkassenDataAction(s);
        }),
        catchError(() => EMPTY),
      )),
    ));
  }
}
