import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ArztDatenResponse} from '../../api/client.service';
import {FormControl} from '@angular/forms';

@Component({
  selector: ' san-tnzeingabe',
  templateUrl: './tnzeingabe.component.html',
  styleUrls: ['./tnzeingabe.component.scss']
})
export class TNZEingabeComponent implements OnInit {
  @Input() state: ArztDatenResponse;
  @Input() tnzBeginn: FormControl;

  @Output() ready = new EventEmitter();
  @Output() zurueck = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  checkReady() {
    // tslint:disable-next-line:no-unused-expression
    this.tnzBeginn.valid && this.ready.emit();
  }
}
