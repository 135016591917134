<div class="modal-body">
  <ng-container *ngIf="state === DIFASciencPopupState.welcome">
    <h3>Versorgungsforschung von Fachärzten für Fachärzte durch den BVHNO</h3>
    <div class="container">
      <div class="row flex-lg-row flex-column-reverse">
        <div class="col-12 col-lg-8">
          <p>
            Unterstützen auch Sie Ihren Berufsverband beim Aufbau einer fächerübergreifenden
            Versorgungsforschungsplattform in ärztlicher Hand.
          </p>

          <p>
            <b>Unsere Daten zu unserem Nutzen</b> in der berufspolitischen Argumentation und zur Verbesserung unserer
            Patientenversorgung. <br/>
            <b>Kein weiteres Zutun</b> nach Anschluss nötig!<br/>
            Datenschutz durch De-Identifizierung von Arzt und Patienten!<br/>
            Die Teilnahme ist <b>kostenfrei</b>.<br/>
            Weitere Informationen über DIFA <em>Science</em> finden Sie
            <a href="https://cloud.sanakey-portal.de/s/KijJAdfXCt2LKfm" target="_blank">>hier<</a>.
          </p>

          <p>
            <span class="text-danger"><b>Sie sind nicht der teilnehmende Arzt?</b></span>
            Dann klicken Sie bitte <a href="javascript:void(0)" (click)="onEMailPaketBtnClick()">>hier<</a>, um die
            entsprechenden Informationen
            per E-Mail zu erhalten und Ihren Praxisinhaber zu informieren.
          </p>
          <button class="d-lg-none btn btn-primary mt-sm-3" (click)="onWeiterBtnClick()">
            Weiter
          </button>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column px-3">
          <div class="px-3 px-lg-0">
            <img class="mw-100" src="assets/popups/difascience-info/logo-bvhno.png">
          </div>
          <div class="py-3 px-5">
            <img class="mw-100" src="assets/popups/difascience-info/Foto_Dr_med_Dirk_Heinrich.jpg">
          </div>
          <div class="d-none d-lg-block flex-grow-1"></div>
          <div class="d-none d-lg-flex justify-content-end">
            <button class="btn btn-primary d-lg-block" (click)="onWeiterBtnClick()">
              Weiter
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="state === DIFASciencPopupState.info">
    <h3>Warum DIFA <em>Science</em>?</h3>
    <div class="container">
      <div class="row flex-lg-row flex-column-reverse">
        <div class="col-12 col-lg-8">
          <div class="container">
            <div class="row">
              <div class="col d-flex flex-column">
                <h4><img class="difaicon" src="assets/popups/difascience-info/DIFA_Icon_nutzenfueralle.png">&nbsp;<span>Nutzen für alle</span>
                </h4>
                <ul class="dashed">
                  <li>Ärzteeigener Datenbestand über alle im SpiFa vertretenen Facharztgruppen</li>
                  <li>Agieren statt Reagieren - keine Einflussnahme Dritter</li>
                  <li>Quantitative Aussagen zu Versorgungsqualität und Wirtschaftlichkeit</li>
                  <li>Datenbasierte Argumente für die Berufspolitik</li>
                  <li>Fachübergreifende wissenschaftliche Auswertungen</li>
                </ul>
                <h4><img class="difaicon" src="assets/popups/difascience-info/DIFA_Icon_inbaerztehand.png">&nbsp;<span>In Ärztehand</span>
                </h4>
                <ul class="dashed">
                  <li>Beaufsichtigung der Datenverwendung durch Ihren Berufsverband</li>
                  <li>Keine Weitergabe der Daten an Institutionen außerhalb DIFAs</li>
                </ul>
                <div class="d-none d-lg-block flex-lg-grow-1"></div>
                <div class="d-none d-lg-block">
                  <button class="btn btn-secondary d-lg-block" (click)="onInfoZurueckBtnClick()">
                    Zurück
                  </button>
                </div>
              </div>
              <div class="col">
                <h4><img class="difaicon" src="assets/popups/difascience-info/DIFA_Icon_ausihrerpraxis.png">&nbsp;<span>Aus Ihrer Praxis</span>
                </h4>
                <ul class="dashed">
                  <li>Daten- und analysebasierte Erkenntnisse für Ihre Fachgruppe, Ihre Praxis, und Ihre Patienten</li>
                  <li>Mehr Transparenz durch Analyse des Versorgungsgeschehens</li>
                  <li>Ergänzung der medizinischen Forschung durch Erkenntisse aus dem Versorgungsalltag</li>
                  <li>Überprüfung der Vorsorgungspfade durch fachgruppenübergreifende Perspektive</li>
                </ul>
                <h4><img class="difaicon" src="assets/popups/difascience-info/DIFA_Icon_ihrnutzen.png">&nbsp;<span>Ihr Nutzen</span>
                </h4>
                Regelmäßige Reports ...
                <ul class="dashed">
                  <li>zum Leistungsgeschehen in Ihrer Praxis</li>
                  <li>Ihres Verschreibungsverhaltens</li>
                  <li>zu Vergleichszahlen aus Ihrer Fachgruppe</li>
                  <li>der Patientenverteilung nach GOÄ / EBM</li>
                </ul>
                <div class="d-lg-none">
                  <button class="btn btn-primary mt-sm-3" (click)="onHierInformierenBtnClick()">
                    Hier informieren
                  </button>
                  <button class="btn btn-primary mb-1 mt-sm-3" (click)="onJetztTeilnehmenBtnClick()">
                    Jetzt teilnehmen
                  </button>
                  <button class="btn btn-secondary mt-sm-3" (click)="onSpaeterTeilnehmenBtnClick()">
                    Später teilnehmen
                  </button>
                  <button class="btn btn-secondary mb-1 mt-sm-3" (click)="onInfoZurueckBtnClick()">
                    Zurück
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 d-flex flex-column px-3 d-flex flex-column px-3">
          <div class="px-3 px-lg-0">
            <img class="mw-100" src="assets/popups/difascience-info/logo-bvhno.png">
          </div>
          <div class="py-3 px-5">
            <img class="mw-100" src="assets/logos/difa.png">
          </div>
          <div class="d-none d-lg-block flex-grow-1"></div>
          <button class="btn btn-primary d-lg-block" (click)="onHierInformierenBtnClick()">
            Hier informieren
          </button>
          <div class="d-none d-lg-block flex-grow-1 mt-3"></div>
          <button class="btn btn-primary d-mb-block mb-1" (click)="onJetztTeilnehmenBtnClick()">
            Jetzt teilnehmen
          </button>
          <button class="btn btn-secondary d-mb-block mb-1" (click)="onSpaeterTeilnehmenBtnClick()">
            Später teilnehmen
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="state === DIFASciencPopupState.dismiss">
    <div>
      <p>
        Alle <b>Informationen</b> zur Teilnahme an DIFA <em>Science</em> finden Sie im
        <b>Sanakey-Portal</b> unter der Rubrik <b>„Onkogister/DIFA <em>Science</em>“</b>.
      </p>
      <p>
        Machen auch Sie mit und werden Teil der <b>Versorgungsforschung von Fachärzten für Fachärzte</b>.
      </p>
      <p>
        <b>Kontakt:</b><br/>
        <a href="https://www.difa-vf.de">www.difa-vf.de</a><br/>
        Telefon: +49 (0) 30 - 3100 78 955<br/>
        <a href="mailto:info@difa-vf.de">info@difa-vf.de</a>
      </p>
      <div class="flex-row mt-3 d-flex justify-content-between">
        <button class="btn btn-secondary" (click)="onSchliessenClicked()">
          schließen
        </button>
        <button class="btn btn-primary" (click)="onZurueckClicked()">
          zurück
        </button>
      </div>
    </div>
  </ng-container>
</div>
