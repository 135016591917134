import {Component, Input, OnInit} from '@angular/core';
import {faPhoneAlt, faClock, faEnvelope} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'san-visitenkarte',
  templateUrl: './visitenkarte.component.html',
  styleUrls: ['./visitenkarte.component.scss']
})
export class VisitenkarteComponent implements OnInit {
  @Input() logo = 'assets/logos/sanakey-portal.png';
  @Input() abteilung = '';
  @Input() email = '';
  @Input() telefonnummer = '';
  @Input() servicezeiten = '';
  @Input() organisation = 'Sanakey Contract GmbH';
  @Input() strasse = 'Robert-Koch-Platz 9';
  @Input() plz = '10115';
  @Input() ort = 'Berlin';
  @Input() secondEmail = '';
  emailIcon = faEnvelope;
  telefonIcon = faPhoneAlt;
  servicezeitenIcon = faClock;
  vCard: any;

  constructor() {
  }

  ngOnInit(): void {
    this.vCard = {
      kind: 'org',
      name: {
        firstNames: this.organisation,
        lastNames: this.abteilung,
      },
      email: [this.email],
      title: this.abteilung,
      organization: this.organisation,
      note: this.servicezeiten,
      version: '2.1',
      address: [{
        street: this.strasse,
        postalCode: this.plz,
        locality: this.ort,
      }],
      telephone: [this.telefonnummer]
    };
    if (this.secondEmail) {
      this.vCard.email.push(this.secondEmail);
    }
  }

}
