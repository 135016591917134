import {Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  faBars,
  faEuroSign,
  faExclamationTriangle,
  faFileAlt,
  faPlay,
  faPlus,
  faSpinner
} from '@fortawesome/pro-solid-svg-icons';
import {StepperComponent} from '../../shared/stepper/stepper.component';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ClientService, PartnerPreviewResponse, PreviewResponse} from '../api/client.service';
import {StepComponent} from '../../shared/stepper/step/step.component';
import {Store} from '@ngrx/store';
import {HideCustomLogoAction, HideInfoIconAction, ShowCustomLogoAction} from '../../reducers/header.reducer';
import {AppToastService} from '../../toast/app-toast.service';
import {HttpResponse} from '@angular/common/http';
import {interval, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'san-partner-einschreibung',
  templateUrl: './partner-einschreibung.component.html',
  styleUrls: ['./partner-einschreibung.component.scss']
})
export class PartnerEinschreibungComponent implements OnInit, OnDestroy {
  @HostBinding('class.d-block') dblock = true;
  @HostBinding('class.p-3') pad = true;
  @HostBinding('class.h-100') h100 = true;

  @ViewChild('stepperComponent') stepperComponent: StepperComponent;
  // Steps:
  @ViewChild('stepPin') stepPin: StepComponent;
  @ViewChild('stepZustimmung') stepZustimmung: StepComponent;
  @ViewChild('stepZustimmungBestaetigung') stepZustimmungBestaetigung: StepComponent;
  @ViewChild('stepWiderruf') stepWiderruf: StepComponent;
  @ViewChild('stepWiderrufBestaetigung') stepWiderrufBestaetigung: StepComponent;
  @ViewChild('stepUngueltigeEinschreibung') stepUngueltigeEinschreibung: StepComponent;

  loading = true;

  spinnerIcon = faSpinner;
  iconAdd = faPlus;
  iconStart = faPlay;
  iconBars = faBars;
  iconEuros = faEuroSign;
  iconDokumente = faFileAlt;
  iconExclamation = faExclamationTriangle;

  sending = false;
  wrongCredentials = false;

  token: string = null;
  pin: string = null;
  state: PartnerPreviewResponse = null;

  showLinkToWiderruf = true;

  private interval: Observable<number>;
  private intervalSub$: Subscription;

  constructor(private activatedRoute: ActivatedRoute, private apiClient: ClientService, private store: Store,
              private toastService: AppToastService, private router: Router) {
    // noinspection PointlessArithmeticExpressionJS
    this.interval = interval(1 /* min */ * 2 /* s */ * 1000 /* ms */);
  }

  ngOnDestroy(): void {
    this.intervalSub$?.unsubscribe();
    this.store.dispatch(new HideCustomLogoAction());
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((value: Params) => {
      if (value.token) {
        this.token = value.token;
      }
      setTimeout(() => {
        this.loading = false;
      }, 50);
    });
  }


  sendCredentials(credentials: { pin: string }) {
    if (this.sending) {
      return;
    }
    this.pin = credentials.pin.toUpperCase();
    this.wrongCredentials = false;
    this.sending = true;
    this.apiClient.partnerSelbstEinschreibungPreview({
      link_token: this.token, pin: this.pin.toUpperCase()
    }).subscribe((value) => {
      // token & pin correct

      if (!this.state && value.krankenkasse.logo) {
        this.store.dispatch(new ShowCustomLogoAction(value.krankenkasse.logo, value.krankenkasse.abkuerzung));
      }

      this.state = value;

      if ((value.signed || value.canceled) && !value.teilnahmeerklaerung_abrufbar) {
        this.startStatePolling();
      }

      this.sending = false;
      if (value.sign_datetime) {
        if (value.sign_cancel_datetime) {
          // show Widerruf Bestätigung
          this.stepperComponent.goToStepViaComponent(this.stepWiderrufBestaetigung, true);
        } else {
          // show Zustimmung Bestätigung && Widerruf && Widerruf Bestätigung
          this.stepperComponent.goToStepViaComponent(this.stepZustimmungBestaetigung, true);
        }
      } else {
        // !value.sign_datetime
        if (value.sign_cancel_datetime) {
          // canceled before signed -> invalid
          this.stepperComponent.goToStepViaComponent(this.stepUngueltigeEinschreibung, true);
        } else {
          // show Zustimmung && Zustimmung Bestätigung
          this.stepperComponent.goToStepViaComponent(this.stepZustimmung, true);
        }
      }
    }, error => {
      // token & pin NOT correct
      this.sending = false;
      this.wrongCredentials = true;
    });
  }

  einschreiben() {
    if (this.sending) {
      return;
    }
    this.sending = true;

    this.apiClient.partnerSelbstEinschreibungSign({
      partner_akzeptiert_datenschutzbestimmungen: true,
      partner_akzeptiert_sanakeyportal_datenschutzbestimmungen: true,
      partner_akzeptiert_sanakeyportal_nutzungsbedingungen: true,
      partner_akzeptiert_teilnahmebedingungen: true,
      link_token: this.token, pin: this.pin.toUpperCase(),
    }).subscribe((value) => {
      this.showLinkToWiderruf = false;
      this.stepperComponent.goToStepViaComponent(this.stepZustimmungBestaetigung, true);
      this.startStatePolling();
    }, error => {
      this.toastService.show('Ein Fehler ist aufgetreten',
        'Die Einschreibung konnte nicht bestätigt werden. Bitte versuchen Sie es später erneut', 'danger');
    });
  }

  private refreshState(credentials: { pin: string }) {
    this.apiClient.partnerSelbstEinschreibungPreview({
      link_token: this.token, pin: this.pin.toUpperCase()
    }).subscribe((value) => {
      this.state = value;
      if (value.teilnahmeerklaerung_abrufbar) {
        this.intervalSub$?.unsubscribe();
      }
    });
  }

  abmelden() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate(['/', 'partnerenselbsteinschreibung']);
    });
    this.toastService.show('Abgemeldet',
      'Sie wurden abgemeldet. Bitte schließen Sie diesen Tab/dieses Fenster nun',
      'success'
    );
  }

  widerruf() {
    this.stepperComponent.goToStepViaComponent(this.stepWiderruf, true);
  }

  widerrufBestaetigen() {
    if (this.sending) {
      return;
    }
    this.sending = true;

    this.apiClient.partnerSelbstEinschreibungCancel({
      link_token: this.token, pin: this.pin.toUpperCase(),
    }).subscribe((value) => {
      this.stepperComponent.goToStepViaComponent(this.stepWiderrufBestaetigung, true);
      this.startStatePolling();
    }, error => {
      this.toastService.show('Ein Fehler ist aufgetreten',
        'Die Einschreibung konnte nicht widerrufen werden. Bitte versuchen Sie es später erneut', 'danger');
    });
  }

  downloadTNE() {
    this.apiClient.partnerSelbstEinschreibungTNE({
      link_token: this.token, pin: this.pin.toUpperCase()
    }).subscribe((value) => {
      this.downloadData(value.b64data, value.filename);
    });
  }

  private downloadData(pdfb64: string, filename: string) {
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${pdfb64}`;
    link.download = filename;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

    setTimeout(() => {
      link.remove();
    }, 100);
  }

  private startStatePolling() {
    this.intervalSub$?.unsubscribe();
    this.intervalSub$ = this.interval.subscribe(() => {
      this.refreshState({pin: this.pin.toUpperCase()});
    });
  }
}
