import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'san-blocking-loader',
  templateUrl: './blocking-loader.component.html',
  styleUrls: ['./blocking-loader.component.scss']
})
export class BlockingLoaderComponent implements OnChanges {
  @Input() fullWidth = true;
  @Input() fullHeight = true;
  @Input() active = false;
  @Input() nachricht = 'Die Daten werden geladen ...';

  @HostBinding('class.active') hasActiveClass = false;

  loadingIcon = faSpinner;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active?.currentValue) {
      this.hasActiveClass = true;
    } else {
      this.hasActiveClass = false;
    }
  }
}
