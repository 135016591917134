import {Injectable} from '@angular/core';
import {
  AuthData,
  AuthDeviceData,
  AuthDeviceSetupResponse201,
  AuthResponse,
  BilderResponseElement,
  CredentialsCorrectResponse,
  DataValidationErrorResponse, Device,
  DeviceRegistrationRequiredResponse, DifaRegistrationRequest,
  DoctorRegistrationRequest,
  DoctorRegistrationResponse,
  ExtraData,
  ExtradataInstance,
  ExtradataInstanceOrdering,
  ExtradataInstancePage,
  ExtraEnum,
  FeldFehler,
  FullExtraData,
  GeneralErrorResponse,
  Jwt, KrankenkassenData, PasswordStrengthDataResponse,
  Product, PwdChangeResponse,
  RegistrationResponseTypes,
  RegistrationStateRequest,
  RegistrationStateResponse, ResetPasswordConfirmResponse,
  ResetPasswordData,
  ResetPasswordResponse,
  SetupDeviceData,
  ValidateUsernameData
} from './models';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {catchError, delay, map, take, tap} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  AuthDataFromStorageLoadedAction, LoadProductsAction,
  SetAuthenticatedAction,
  SetUnauthenticatedAction
} from '../../reducers/authinfo.reducer';
import {PaginatedResult} from '../../shared/models.includes';
import {Ordering} from '../../shared/reducer.includes';
import {B64FileResponse} from '../../patienten-selbst-einschreibung/api/client.service';

@Injectable({
  providedIn: 'root'
})
export class BenutzermanagementAPIService {
  static STORAGE_TYPE = window.localStorage;
  static STORAGE_KEY_AUTH_INFORMATION = 'Sanakey.BenutzerManagement.TokenInformation';

  baseURL = environment.benutzermanagement;
  private authInformation: AuthResponse = null;
  private authHeaders: { Authorization: string } = {Authorization: '-'};
  private nextAuthTokenCheckOnlyAfter: Date = new Date();
  public lastUsedUsername = '';
  private nextAfterLogin = null;

  constructor(private httpClient: HttpClient, private store: Store) {
    this.setAuthFromStorage();
  }

  setNextAfterLogin(url: string) {
    this.nextAfterLogin = url;
  }

  getNextAfterLogin() {
    return this.nextAfterLogin;
  }

  clearNextAfterLogin() {
    this.nextAfterLogin = null;
  }

  authHeadersObj() {
    return {...this.authHeaders};
  }

  authHeadersList(): { key: string, value: string }[] {
    const r = [];
    for (const k of Object.keys(this.authHeaders)) {
      r.push({key: k, value: this.authHeaders[k]});
    }
    return r;
  }

  authDevice(loginData: AuthDeviceData): Observable<DeviceRegistrationRequiredResponse | CredentialsCorrectResponse | any> {
    return this.httpClient.post(`${this.baseURL}/auth/device/`, loginData, {observe: 'response'}).pipe(map((r => {
      if (r.status === 200) {
        return Object.assign(new CredentialsCorrectResponse(), r.body as CredentialsCorrectResponse);
      }
      if (r.status === 201) {
        return Object.assign(new DeviceRegistrationRequiredResponse(), r.body as DeviceRegistrationRequiredResponse);
      }
      return r;
    })));
  }

  setupDevice(loginData: SetupDeviceData, authenticated = false): Observable<AuthDeviceSetupResponse201 | any> {
    let headers: any = {};
    if (authenticated) {
      headers = {headers: {...this.authHeaders}};
    }
    return this.httpClient.post(`${this.baseURL}/auth/device/setup/`, loginData, {
      ...headers,
      observe: 'response'
    }).pipe(map((r => {
      // @ts-ignore
      if (r.status === 201) {
        // @ts-ignore
        return Object.assign(new AuthDeviceSetupResponse201(), r.body as AuthDeviceSetupResponse201);
      }
      return r;
    })));
  }

  activateDevice(loginData: AuthData, authenticated = false): Observable<AuthResponse | any> {
    let headers: any = {};
    if (authenticated) {
      headers = {headers: {...this.authHeaders}};
    }
    return this.httpClient.post(`${this.baseURL}/auth/device/verify/`, loginData, {
      ...headers,
      observe: 'response'
    }).pipe(map((r => {
      // @ts-ignore
      if (r.status === 200) {
        // @ts-ignore
        const response = Object.assign(new AuthResponse(), r.body as AuthResponse);
        this.persistAuthInformation(response);
        return response;
      }
      return r;
    })));
  }

  auth(loginData: AuthData): Observable<AuthResponse | any> {
    this.lastUsedUsername = loginData.username;
    return this.httpClient.post(`${this.baseURL}/auth/token/`, loginData, {observe: 'response'}).pipe(map((r => {
      if (r.status === 200) {
        const t = new Date();
        t.setSeconds(t.getSeconds() + 20);
        this.nextAuthTokenCheckOnlyAfter = t;
        const response = Object.assign(new AuthResponse(), r.body as AuthResponse);
        this.persistAuthInformation(response);
        return response;
      }
      return r;
    })));
  }

  validateUsername(loginData: ValidateUsernameData): Observable<any> {
    return this.httpClient.post(`${this.baseURL}/auth/username/`, loginData, {observe: 'response'});
  }

  private persistAuthInformation(response) {
    this.setAuthInfoFromResponse(response);
    if (BenutzermanagementAPIService.STORAGE_TYPE) {
      if (!response) {
        BenutzermanagementAPIService.STORAGE_TYPE.removeItem(BenutzermanagementAPIService.STORAGE_KEY_AUTH_INFORMATION);
      } else {
        BenutzermanagementAPIService.STORAGE_TYPE.setItem(
          BenutzermanagementAPIService.STORAGE_KEY_AUTH_INFORMATION,
          JSON.stringify(this.authInformation));
      }
    }
  }

  private setAuthInfoFromResponse(response, initialCheck = false) {
    if (!response) {
      this.authInformation = null;
      this.authHeaders.Authorization = '-';
      this.store.dispatch(new SetUnauthenticatedAction());
    } else {
      this.authInformation = response;
      this.authHeaders.Authorization = `Token ${this.authInformation.token.key}`;
      this.isAuthenticated().pipe(take(1)).subscribe((r) => {
        if (r) {
          let username = this.lastUsedUsername;
          if (!username) {
            username = this.authInformation.username;
          }
          this.store.dispatch(new SetAuthenticatedAction(username, this.authInformation.device_id));
          if (initialCheck) {
            this.store.dispatch(new AuthDataFromStorageLoadedAction());
          }
        } else {
          this.persistAuthInformation(null);
        }
      });
    }
  }

  resetPassword(data: ResetPasswordData): Observable<ResetPasswordResponse> {
    return this.httpClient.post(`${this.baseURL}/password/request_reset_passwort/`, data, {observe: 'response'})
      .pipe(map((r => {
        return ResetPasswordResponse.OK;
      })))
      .pipe(catchError((r) => {
        if (r.status === 200) {
          return throwError(ResetPasswordResponse.OK);
        } else if (r.status === 400) {
          return throwError(ResetPasswordResponse.DataInvalid);
        } else if (r.status === 402) {
          return throwError(ResetPasswordResponse.EmailAmbigious);
        } else if (r.status === 410) {
          return throwError(ResetPasswordResponse.ServiceUnavailable);
        }
        return throwError(ResetPasswordResponse.ServiceUnavailable);
      }));
  }

  resetPasswordStrengthData(data: { username: string, token: string, }) {
    return this.httpClient.post<PasswordStrengthDataResponse>(
      `${this.baseURL}/password/request_reset_strength_data/`,
      data,
      {observe: 'response'}
    ).pipe(map(value => {
      return value.body as PasswordStrengthDataResponse;
    }));
  }

  resetPasswordConfirm(data: { new_password: string, username: string, token: string, }) {
    return this.httpClient.post(
      `${this.baseURL}/password/confirm_request_reset/`,
      data,
      {observe: 'response'}
    )
      .pipe(map((r => {
        return ResetPasswordConfirmResponse.OK;
      })))
      .pipe(catchError((r) => {
        if (r.status === 200) {
          return throwError(ResetPasswordConfirmResponse.OK);
        } else if (r.status === 406) {
          return throwError(ResetPasswordConfirmResponse.DataInvalid);
        } else if (r.status === 409) {
          return throwError(ResetPasswordConfirmResponse.PasswordTooWeak);
        } else if (r.status === 410) {
          return throwError(ResetPasswordConfirmResponse.TokenExpired);
        }
        return throwError(ResetPasswordConfirmResponse.ServiceUnavailable);
      }));
  }

  fetchEnum(namespace: string, name: string, pageUrl: string = null): Observable<PaginatedResult<ExtraEnum, undefined>> {
    if (pageUrl) {
      return this.httpClient.get<PaginatedResult<ExtraEnum, undefined>>(pageUrl);
    }
    return this.httpClient.get<PaginatedResult<ExtraEnum, undefined>>(
      `${this.baseURL}/userdatatypes/enum/?name=${name}&namespace=${namespace}`);
  }

  fetchBerufsverbaende(pageUrl: string = null): Observable<PaginatedResult<ExtraEnum, undefined>> {
    if (pageUrl) {
      return this.httpClient.get<PaginatedResult<ExtraEnum, undefined>>(pageUrl);
    }
    return this.httpClient.get<PaginatedResult<ExtraEnum, undefined>>(
      `${this.baseURL}/userdatatypes/enum/?name=Verbandsmitgliedschaft/Verbandsname&namespace=Sanakey`);
  }

  fetchTitel(pageUrl: string = null): Observable<PaginatedResult<ExtraEnum, undefined>> {
    if (pageUrl) {
      return this.httpClient.get<PaginatedResult<ExtraEnum, undefined>>(pageUrl);
    }
    return this.httpClient.get<PaginatedResult<ExtraEnum, undefined>>(
      `${this.baseURL}/userdatatypes/enum/?name=Titel&namespace=Sanakey`);
  }

  registerDoctor(request: DoctorRegistrationRequest): Observable<RegistrationResponseTypes> {
    return this.httpClient.post(`${this.baseURL}/registration/doctor/`, request, {observe: 'response'})
      .pipe(map(r => {
        // if (r.status === 200) {
        return Object.assign(new DoctorRegistrationResponse(), r.body as DoctorRegistrationResponse);
      }))
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          // data validation error
          if (err.error) {
            if (Array.isArray(err.error) && err.error.length > 0) {
              const errorMessage = err.error[0] as string;
              if (errorMessage.includes('R.104')) {
                return throwError(new DataValidationErrorResponse([
                  new FeldFehler('lanr', [errorMessage]),
                  new FeldFehler('bsnr', [errorMessage]),
                ]));
              } else if (errorMessage.includes('R.105')) {
                return throwError(new DataValidationErrorResponse([
                  new FeldFehler('gRecaptchaResponse', [errorMessage]),
                ]));
              } else if (errorMessage.includes('R.106')) {
                return throwError(new DataValidationErrorResponse([
                  new FeldFehler('password', [errorMessage]),
                ]));
              }
            } else if (typeof err.error === 'object' && err.error) {
              const ff: FeldFehler[] = [];
              for (const feldName of Object.keys(err.error)) {
                ff.push(new FeldFehler(feldName, err.error[feldName]));
              }
              return throwError(new DataValidationErrorResponse(ff));
            }
            return throwError(new GeneralErrorResponse());
          }
          return throwError(new GeneralErrorResponse());
        }
        return throwError(new GeneralErrorResponse());
      }));
  }

  checkRegisterState(request: RegistrationStateRequest): Observable<RegistrationStateResponse> {
    return this.httpClient.get(`${this.baseURL}/registration/check_activation/${request.check_key}/`, {observe: 'response'})
      .pipe(map(r => {
        // if (r.status === 200) {
        return Object.assign(new RegistrationStateResponse(), r.body as RegistrationStateResponse);
      }))
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === 423) {
          // data validation error
          if (err.error) {
            const robj = Object.assign(new RegistrationStateResponse(), err.error as RegistrationStateResponse);
            return throwError(robj);
          }
          return throwError(new GeneralErrorResponse());
        }
        return throwError(new GeneralErrorResponse());
      }));
  }

  generateRegisterDocUrl(checkKey: string) {
    return `${this.baseURL}/registration/check_activation/${checkKey}/register_doc.pdf`;
  }

  activateAccount(param: { userid: string; token: string }) {
    return this.httpClient.post(`${this.baseURL}/registration/activation/`, param, {observe: 'response'});
  }

  isAuthenticated(): Observable<boolean> {
    return new Observable<boolean>(n => {
      if (!this.authInformation) {
        n.next(false);
        return;
      }
      if (this.nextAuthTokenCheckOnlyAfter > new Date()) {
        n.next(true);
        return;
      }
      this.getTokenState().subscribe(r => {
        const t = new Date();
        t.setSeconds(t.getSeconds() + 20);
        this.nextAuthTokenCheckOnlyAfter = t;
        n.next(true);
      }, e => {
        console.log('isAuthenticated: token invalid');
        console.log(e);
        n.next(false);
      });
    });
  }

  private getTokenState() {
    return this.httpClient.get(`${this.baseURL}/user/check_auth/`, {headers: {...this.authHeaders}});
  }

  logOut() {
    return new Observable<boolean>(sub => {
      this.persistAuthInformation(null);
      this.store.dispatch(new SetUnauthenticatedAction());
      sub.next(true);
    }).pipe(delay(500));
  }

  getProducts(): Observable<PaginatedResult<Product, undefined>> {
    return this.httpClient.get(`${this.baseURL}/product/`, {headers: {...this.authHeaders}}).pipe(map(r => {
      return Object.assign(new PaginatedResult<Product, undefined>(), r as PaginatedResult<Product, undefined>);
    }));
  }

  private setAuthFromStorage() {
    if (BenutzermanagementAPIService.STORAGE_TYPE) {
      const authInfo = BenutzermanagementAPIService.STORAGE_TYPE.getItem(BenutzermanagementAPIService.STORAGE_KEY_AUTH_INFORMATION);
      if (authInfo) {
        this.authInformation = JSON.parse(authInfo);
        this.setAuthInfoFromResponse(this.authInformation, true);
      }
    }
  }

  requestJwt(UUID: string): Observable<Jwt> {
    return this.httpClient.post(`${this.baseURL}/product/jwt/`, {aud: UUID}, {headers: {...this.authHeaders}})
      .pipe(map(r => {
        // if (r.status === 200) {
        return Object.assign(new Jwt(), r as Jwt);
      }));
  }

  getUserdata(pageNumber: number = 1,
              pageSize: number = 59,
              ordering: Ordering<ExtradataInstanceOrdering> = null,
              search: string = null,
              filter: string[] = null): Observable<ExtradataInstancePage> {
    let searchString = '';
    if (search) {
      searchString = `&search=${search}`;
    }
    let filterString = '';
    if (filter && filter.length) {
      const namespaces = new Set();
      const names = new Set();
      for (const key of filter) {
        const parts = key.split('/');
        const namespace = parts[0];
        parts.splice(0, 1);
        const name = parts.join('/');

        namespaces.add(namespace);
        names.add(name);
      }
      filterString = `&extradata__namespace__in=${Array.from(namespaces).join(',')}`
        + `&extradata__name__in=${Array.from(names).join(',')}`;
    }
    return this.httpClient.get(
      `${this.baseURL}/userdata/?page=${pageNumber}&page_size=${pageSize}${searchString}${filterString}`,
      {headers: {...this.authHeaders}}).pipe(map(r => {
      const res = Object.assign(new PaginatedResult<ExtradataInstance, ExtradataInstanceOrdering>(), r) as ExtradataInstancePage;
      res.ordering = ordering;
      res.pageNumber = pageNumber;
      res.pageSize = pageSize;
      return res;
    }));
  }

  getVisibleExtradata() {
    return this.httpClient.get(`${this.baseURL}/userdata/visible/`, {headers: {...this.authHeaders}})
      .pipe(map((r: { results: ExtraData[] }) => {
        return r.results.map(el => Object.assign(new FullExtraData(), el as FullExtraData));
      }));
  }

  updateUserdata(edi: { id: number }, value: any, byValue = false, options: { alternative_value?: any } = {}) {
    const body = {
      value, by_value: byValue, alternative_value: undefined,
    };
    if (options && options.alternative_value) {
      body.alternative_value = options.alternative_value;
    }
    return this.httpClient.put(`${this.baseURL}/userdata/${edi.id}/`, body,
      {headers: {...this.authHeaders}});
  }

  createUserdata(extraData: { name: string, namespace: string }, value: any, byValue = false) {
    return this.httpClient.post(`${this.baseURL}/userdata/`, {
      value,
      extradata__name: extraData.name,
      extradata__namespace: extraData.namespace,
      by_value: byValue,
    }, {headers: {...this.authHeaders}})
      .pipe(map(r => Object.assign(new ExtradataInstance(), r as ExtradataInstance)));
  }

  deleteUserdata(edi: ExtradataInstance) {
    return this.httpClient.delete(`${this.baseURL}/userdata/${edi.id}/`, {headers: {...this.authHeaders}});
  }

  getKarussellBilder() {
    return this.httpClient.get<BilderResponseElement[]>(`${this.baseURL}/frontend/karussellbild/`);
  }

  getPopups(): Observable<string[]> {
    return this.httpClient.get(`${this.baseURL}/popup/`,
      {headers: {...this.authHeaders}}).pipe(map((r: any) => {
      return r.popups as string[];
    }));
  }

  setPopupViewed(name: string, dismissed: boolean, bemerkung: string = null): Observable<any> {
    return this.httpClient.post(`${this.baseURL}/popup/`,
      {name, dismissed},
      {headers: {...this.authHeaders}});
  }

  registerDifa(data: DifaRegistrationRequest) {
    for (const k of Object.keys(data)) {
      if ([undefined, ''].indexOf(data[k]) >= 0) {
        delete data[k];
      }
    }

    return this.httpClient.post(`${this.baseURL}/registration/onkogister/doctor/`,
      data, {observe: 'response'})
      .pipe(map(r => {
        // if (r.status === 200) {
        return Object.assign(new DoctorRegistrationResponse(), r.body as DoctorRegistrationResponse);
      }))
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === 400) {
          // data validation error
          if (err.error) {
            if (Array.isArray(err.error) && err.error.length > 0) {
              const errorMessage = err.error[0] as string;
              if (errorMessage.includes('R.104')) {
                return throwError(new DataValidationErrorResponse([
                  new FeldFehler('lanr', [errorMessage]),
                  new FeldFehler('bsnr', [errorMessage]),
                ]));
              } else if (errorMessage.includes('R.105')) {
                return throwError(new DataValidationErrorResponse([
                  new FeldFehler('gRecaptchaResponse', [errorMessage]),
                ]));
              } else if (errorMessage.includes('R.106')) {
                return throwError(new DataValidationErrorResponse([
                  new FeldFehler('password', [errorMessage]),
                ]));
              }
            } else if (typeof err.error === 'object' && err.error) {
              const ff: FeldFehler[] = [];
              for (const feldName of Object.keys(err.error)) {
                ff.push(new FeldFehler(feldName, err.error[feldName]));
              }
              return throwError(new DataValidationErrorResponse(ff));
            }
            return throwError(new GeneralErrorResponse());
          }
          return throwError(new GeneralErrorResponse());
        }
        return throwError(new GeneralErrorResponse());
      }));
  }

  loadAuthDevices() {
    return this.httpClient.get(`${this.baseURL}/auth/device/?page_size=100&page=1`,
      {headers: {...this.authHeaders}}).pipe(map((r: any) => {
      return r.results as Device[];
    }));
  }

  updatePassword(value: { old_password?: string | null, new_password?: string | null }) {
    return this.httpClient.put<PwdChangeResponse>(`${this.baseURL}/password/change_known/`,
      value,
      {headers: {...this.authHeaders}, observe: 'response'})
      .pipe(map(value1 => {
        return PwdChangeResponse.success;
      }))
      .pipe(catchError(err => {
        if (err.status === 406) {
          return of(PwdChangeResponse.errWrongCredentials);
        } else if (err.status === 409) {
          return of(PwdChangeResponse.errPasswordTooWeak);
        }
        return of(PwdChangeResponse.errUnknown);
      }));
  }

  deleteDevice(device: Device) {
    return this.httpClient.delete(`${this.baseURL}/auth/device/${device.id}/`,
      {headers: {...this.authHeaders}});
  }

  activateProduct(productId: string, rData: any) {
    return this.httpClient.post(`${this.baseURL}/product/${productId}/access/`,
      rData,
      {headers: {...this.authHeaders}}).pipe(tap(x => {
      this.store.dispatch(new LoadProductsAction());
      return x;
    }));
  }

  downloadEDFile(id: number) {
    return this.httpClient.get(`${this.baseURL}/userdata/${id}/download_file`,
      {headers: {...this.authHeaders}})
      .pipe(map(r => {
        return r as B64FileResponse;
      }));
  }

  getKrankenkasse(ik: string) {
    return this.httpClient.get(`${this.baseURL}/krankenkasse/retrieve_by_ik/?ik=${ik}`,
      {headers: {...this.authHeaders}}).pipe(map((r) => {
        return Object.assign(new KrankenkassenData(), r as KrankenkassenData);
      })
    )
  }
}
