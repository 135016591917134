<!-- TOP -->
<nav class="navbar navbar-expand-lg navbar-light bg-light header overflow-x-hidden py-1 px-2 py-md-1 px-md-2 d-flex flex-row justify-content-between">
  <span *ngIf="!logoLeft && !showSidebar"></span>
  <button *ngIf="showSidebar" class="btn" (click)="sidebarService.toggle()">
    <fa-icon [icon]="iconToggleSidebar"></fa-icon>
  </button>
  <a *ngIf="!customMainLogo" class="navbar-brand logo" [routerLink]="linkTopLogo" tabindex="-1">
    <div style="background-image: url('assets/logos/sanakey-portal.png')" class="logo"></div>
  </a>
  <a *ngIf="customMainLogo" class="navbar-brand logo" [routerLink]="customMainLogoLink" tabindex="-1">
    <div [ngStyle]="customMainLogoStyle"
         class="logo"></div>
  </a>
  <san-info-box-button class="" *ngIf="showInfoBoxButton"></san-info-box-button>
  <san-account-button *ngIf="showAccountBoxButton"></san-account-button>
  <img *ngIf="customLogo" [src]="customLogo" [alt]="customLogoAlt" class="rightCustomLogo p-1">
</nav>

<!--<router-outlet class="flex-fill bg-light p-5 overflow-x-hidden"></router-outlet>-->
<div class="content-container">
  <san-side-bar></san-side-bar>
  <div class="content">
    <!-- Breadcrumbs -->
    <!-- CONTENT -->
    <ng-scrollbar [autoWidthDisabled]="true" track="vertical" class="router-container">
      <router-outlet></router-outlet>
    </ng-scrollbar>
    <!-- BOTTOM -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light footer small overflow-x-hidden f-flex flex-row justify-content-between">
      <span class=""><a href="https://www.sanakey-contract.de" target="_blank">Sanakey Contract GmbH</a> &copy; 2024</span>
      <span class="">
        <a href="javascript:void(0)" (click)="this.openDatenschutz()" tabindex="-1">
          Datenschutzbestimmungen
        </a> |
        <a href="javascript:void(0)" (click)="this.openNutzung()" tabindex="-1">
          Nutzungsbedingungen
        </a> |
        <a href="javascript:void(0)" (click)="this.openImpressum()" tabindex="-1">
          Impressum
        </a>
      </span>
    </nav>
  </div>
</div>

<!-- Modals -->
<!-- Header Definition -->
<ng-template #modalHeader let-modal="modal" let-title="title" let-allowClose="allowClose">
  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button *ngIf="allowClose" type="button" class="btn-close" aria-label="schließen" (click)="modal.dismiss()">
    </button>
  </div>
</ng-template>

<!-- Content Definition -->
<ng-template #datenschutz let-modal>
  <ng-container
    *ngTemplateOutlet="modalHeader; context: {modal:modal, allowClose:true, title:'Datenschutzbestimmungen'}"></ng-container>
  <div class="modal-body embedding">
    <iframe src="https://www.sanakey.de/sanakey-portal/datenschutz" class="fill"></iframe>
  </div>
</ng-template>
<ng-template #nutzungsbedingungen let-modal>
  <ng-container
    *ngTemplateOutlet="modalHeader; context: {modal:modal, allowClose:true, title:'Nutzungsbedingungen'}"></ng-container>
  <div class="modal-body embedding">
    <iframe src="https://www.sanakey.de/sanakey-portal/nutzungsbedingungen" class="fill"></iframe>
  </div>
</ng-template>
<ng-template #impressum let-modal>
  <ng-container
    *ngTemplateOutlet="modalHeader; context: {modal:modal, allowClose:true, title:'Impressum'}"></ng-container>
  <div class="modal-body embedding">
    <iframe src="https://www.sanakey.de/sanakey-portal/impressum" class="fill"></iframe>
  </div>
</ng-template>
<ng-template #processError let-modal>
  <ng-container
    *ngTemplateOutlet="modalHeader; context: {modal:modal, allowClose:false, title:'Prozessfehler'}"></ng-container>
  <div class="modal-body">
    <p>
      Ein Fehler ist aufgetreten. Sie können das Portal derzeit nicht nutzen. Bitte nehmen Sie Kontakt mit dem Service
      auf.
    </p>
    <div class="d-flex justify-content-center">
      <san-visitenkarte class="col-xl-4 col-lg-6"
                        abteilung="Allgemeine / technische Fragen zur Funktion des Sanakey-Portals®"
                        email="service@sanakey-portal.de"
                        servicezeiten="Montag bis Freitag 11 Uhr bis 13 Uhr"
                        telefonnummer="030 3100 78 999 (Servicehotline der Sanakey-Gruppe)"></san-visitenkarte>
    </div>
  </div>
</ng-template>

<san-app-toast aria-live="polite" aria-atomic="true"></san-app-toast>
