<div class="bg-white p-2 mt-1">
  <nav class="navbar">
    <img [src]="logo" class="ms-auto" style="max-height: 60px; max-width: 120px;" alt="Logo"/>
  </nav>
  <div *ngIf="abteilung" class="abteilung">{{abteilung}}</div>
  <div *ngIf="email" class="email">
    <fa-icon [icon]="emailIcon" class="align-middle"></fa-icon>&nbsp;
    <a class="small align-middle" href="mailto:{{email}}">{{email}}</a>
    <span *ngIf="secondEmail">
      /
      <a class="small align-middle" href="mailto:{{secondEmail}}">{{secondEmail}}</a>
    </span>
  </div>
  <div *ngIf="telefonnummer" class="telefonnummer">
    <fa-icon [icon]="telefonIcon" class="align-middle"></fa-icon>&nbsp;<span
    class="small align-middle">{{telefonnummer}}</span></div>
  <div *ngIf="servicezeiten" class="servicezeiten">
    <fa-icon [icon]="servicezeitenIcon" class="align-middle"></fa-icon>&nbsp;<span
    class="small align-middle">{{servicezeiten}}</span></div>
  <div>
    <a class="download small" href="javascript:void(0)" [vcdDownloadVCard]="vCard">Digitale Visitenkarte
      herunterladen</a>
  </div>
</div>

