import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {faPrint} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'san-info-box-button',
  templateUrl: './info-box-button.component.html',
  styleUrls: ['./info-box-button.component.css']
})
export class InfoBoxButtonComponent implements OnInit {
  printIcon = faPrint;

  constructor(public modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  goToAuthenticator() {
    window.open('https://www.sanakey.de/sanakey-portal/google-authenticator/', '_blank');
  }

  goToTeamviewerDownload() {
    window.open('https://get.teamviewer.com/sanakey-portal', '_blank');
  }

  goToFAQ() {
    window.open('https://www.sanakey.de/sanakey-portal/faq/', '_blank');
  }

}
