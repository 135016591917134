import {Component, EventEmitter, HostBinding, OnInit, Output} from '@angular/core';
import {faBars, faEuroSign, faFileAlt, faPlay, faPlus, faSpinner} from '@fortawesome/pro-solid-svg-icons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'san-willkommen',
  templateUrl: './willkommen.component.html',
  styleUrls: ['./willkommen.component.scss']
})
export class WillkommenComponent implements OnInit {
  @HostBinding('class.d-block') dblock = true;
  @HostBinding('class.h-100') h100 = true;

  @Output() anmeldenClicked = new EventEmitter();

  spinnerIcon = faSpinner;
  iconAdd = faPlus;
  iconStart = faPlay;
  iconBars = faBars;
  iconEuros = faEuroSign;
  iconDokumente = faFileAlt;

  environment = environment;
  datenschutzAkzeptiert = false;
  loading = false;

  constructor() { }

  ngOnInit(): void {
  }

}
