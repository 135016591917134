import {Component, OnInit} from '@angular/core';

interface Picture {
  isActive?: boolean;
  imageUrl: string;
  title: string;
  description: string;
}

@Component({
  selector: 'san-pictures',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss']
})
export class PicturesComponent implements OnInit {
  pictures: Picture[] = [
    {
      title: 'Ärztinnen & Ärzte',
      description: 'Sie sind ärztlich tätig und möchten die ärzt&shy;liche Ver&shy;sorgung durch inno&shy;vative Versorgungs&shy;verträge mit&shy;gestalten!',
      imageUrl: 'assets/pictures/landing/doctor.jpg'
    },
    {
      title: 'Medizinische Versorgungs&shy;zentren',
      description: 'Sie arbeiten bereits inter&shy;disziplinär in der ärztlichen Versorgung zusammen. Wir bieten Ihnen interessante ' +
        'Versorgungs&shy;verträge und weitere Angebote.',
      imageUrl: 'assets/pictures/landing/mvz.jpg'
    },
    {
      title: 'Kliniken',
      description: 'Sie wollen die Grenze zwischen ambulant und stationär einreißen? Wir bieten Ihnen unkomplizierte ' +
        'Ver&shy;sor&shy;gungs&shy;verträge und die Teilnahme an der ASV!',
      imageUrl: 'assets/pictures/landing/hospital.jpg'
    },
    {
      title: 'Krankenkassen',
      description: 'Sie wünschen sich ein eff&shy;ektives Manage&shy;ment von Versorgungs&shy;verträgen mit Ärztinnen, Ärzten und weit&shy;eren Dienst&shy;leistern sowie Leistungs&shy;erbringern? Wir bieten Ihnen ein maß&shy;geschneidert&shy;es Paket!',
      imageUrl: 'assets/pictures/landing/health_insurance.jpg'
    },
    {
      title: 'Patientinnen & Patienten',
      description: 'Ärzt&shy;liche Informat&shy;ionen, Trans&shy;parenz und die Mög&shy;lich&shy;keit zur Teil&shy;nahme an inno&shy;vativer ärzt&shy;licher Ver&shy;sorgung. Ver&shy;ständ&shy;lich, aktuell und sicher! Sprechen Sie uns an!',
      imageUrl: 'assets/pictures/landing/patients.jpg'
    },
    {
      title: 'Vertragspartner',
      description: 'Sie möchten Ihre Dienst&shy;leistung&shy;en und Pro&shy;dukte auf effi&shy;ziente und intelligente Art und Weise mit Ärzt&shy;innen, Ärzten und weit&shy;eren Leistungs&shy;erbringern ver&shy;knüpfen? Sprechen Sie uns an!',
      imageUrl: 'assets/pictures/landing/contract_partners.jpg'
    },
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}
