<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.name" [term]="t" class="text-wrap"></ngb-highlight>
</ng-template>

<div class="container h-100">
  <!-- mobile view -->
  <div class="row row-cols-1">
    <div class="col">
      <h5>Bitte erfassen Sie zuerst Ihre persönlichen Daten</h5>

      <form class="p-3" [formGroup]="baseDataForm" (ngSubmit)="checkReady()">
        <div class="input-group">
          <label class="small w-100" title="Ihr Vorname; erforderlich">
            Vorname*
          </label>
          <input type="text" class="form-control" placeholder="Vorname"
                 formControlName="vorname"
                 aria-label="Vorname"
                 tabindex="2"
                 autocomplete="vorname">
        </div>
        <san-form-input-error
          [visible]="!baseDataForm.controls.vorname.pristine && baseDataForm.controls.vorname.errors && baseDataForm.controls.vorname.errors.required"
          message="Bitte geben Sie Ihren Vornamen an"></san-form-input-error>

        <div class="input-group mt-3">
          <label class="small w-100" title="Ihr Nachname; erforderlich">
            Nachname*
          </label>
          <input type="text" class="form-control" placeholder="Nachname"
                 formControlName="nachname"
                 aria-label="Nachname"
                 tabindex="3"
                 autocomplete="nachname">
        </div>
        <san-form-input-error
          [visible]="!baseDataForm.controls.nachname.pristine && baseDataForm.controls.nachname.errors && baseDataForm.controls.nachname.errors.required"
          message="Bitte geben Sie Ihren Nachnamen an"></san-form-input-error>

        <div class="input-group  mt-3">
          <label class="small w-100" title="Ihr Geschlecht; erforderlich">
            Geschlecht*
          </label>
          <select class="form-select"
                  formControlName="geschlecht"
                  aria-label="Geschlecht"
                  tabindex="4"
                  autocomplete="geschlecht">
            <option selected [value]="null">Geschlecht wählen</option>
            <!-- 1 weiblich, 2 männlich, 3 unbekannt, 4 divers -->
            <option [value]="1">Weiblich</option>
            <option [value]="2">Männlich</option>
            <option [value]="4">Divers</option>
          </select>
        </div>
        <san-form-input-error
          [visible]="!baseDataForm.controls.geschlecht.pristine && baseDataForm.controls.geschlecht.errors && baseDataForm.controls.geschlecht.errors.required"
          message="Bitte geben Sie Ihr Geschlecht an"></san-form-input-error>

        <div class="input-group mt-3">
          <label class="small w-100" title="Ihr Geburtstag; erforderlich">
            Geburtstag*
          </label>
          <input type="date" class="form-control"
                 formControlName="geburtsdatum"
                 aria-label="Geburtstag"
                 tabindex="5"
                 autocomplete="geburtstag">
        </div>
        <san-form-input-error
          [visible]="!baseDataForm.controls.geburtsdatum.pristine && baseDataForm.controls.geburtsdatum.errors && baseDataForm.controls.geburtsdatum.errors.required"
          message="Bitte geben Sie Ihren Geburstag an"></san-form-input-error>
        <san-form-input-error
          [visible]="!baseDataForm.controls.geburtsdatum.pristine && baseDataForm.controls.geburtsdatum.errors && baseDataForm.controls.geburtsdatum.errors.noFutureDate"
          message="Das Datum darf nicht in der Zukunft liegen"></san-form-input-error>

      </form>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col text-center">
      <button class="btn btn-primary m-lg-5" (click)="zurueck.emit()">Zurück</button>
    </div>
    <div class="col text-center">
      <button class="btn btn-primary m-lg-5" (click)="checkReady()">Weiter</button>
    </div>
  </div>
</div>
