import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'san-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {
  @Input() disabled = false;
  @Input() icon = null;
  @Input() label = null;
  @Input() showNumberOrIcon = true;
  @Input() iconPulse = false;

  @Output() entering = new EventEmitter();
  @Output() leaving = new EventEmitter();

  @ViewChild(TemplateRef) template: TemplateRef<any>;
  error = false;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.changeDetector.detectChanges();
  }

}
