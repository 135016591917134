import {Component, OnInit} from '@angular/core';

const tpl = `<span>DIFA&nbsp;<span class="font-italic">Science</span></span>`;

@Component({
  selector: 'san-difa-science-text',
  template: tpl,
  styles: [],
})
export class DifaScienceTextComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
