import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {SharedModule} from '../shared/shared.module';
import {StammdatenComponent} from './stammdaten/stammdaten.component';
import {Store, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {
  extradataInstanceReducers,
  extradataInstancesStateName,
  LoadVisibleExtradataPageAction,
  RefreshExtradataInstancesAction
} from '../reducers/userdata.reducer';
import {ExtradataInstanceEffects} from '../reducers/userdata.effects';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbProgressbarModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {Add2FADeviceComponent} from './add2-fadevice/add2-fadevice.component';
import {MODULE_ROOT} from './root.config';
import {DeleteDevicePopupComponent} from './delete-device-popup/delete-device-popup.component';
import {ZertifizierungenComponent} from './zertifizierungen/zertifizierungen.component';


const routes: Routes = [
  {
    path: MODULE_ROOT,
    component: StammdatenComponent,
  },
  {
    path: MODULE_ROOT + '/passwort',
    component: ChangePasswordComponent
  },
  {
    path: MODULE_ROOT + '/2fa',
    component: Add2FADeviceComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  declarations: []
})
export class StammdatenRoutingModule {
  constructor(private store: Store) {
  }
}


@NgModule({
  declarations: [StammdatenComponent, ChangePasswordComponent, Add2FADeviceComponent, DeleteDevicePopupComponent, ZertifizierungenComponent],
  exports: [
    StammdatenComponent
  ],
    imports: [
        CommonModule,
        // StoreModule.forFeature(extradataInstancesStateName, extradataInstanceReducers),
        // EffectsModule.forFeature([ExtradataInstanceEffects]),
        StammdatenRoutingModule,
        FontAwesomeModule,
        SharedModule,
        ReactiveFormsModule,
        NgbTypeaheadModule,
        NgbProgressbarModule,
        FormsModule,
        NgOptimizedImage
    ]
})
export class StammdatenModule {
}
