import {Action, ActionReducerMap, createFeatureSelector} from '@ngrx/store';

export enum SidebarActionTypes {
  EnableSidebar = '[Sidebar] Enable Sidebar',
  DisableSidebar = '[Sidebar] Disable Sidebar',
  ToggleSidebarEnabled = '[Sidebar] Toggle Sidebar Enabled',
}

export class EnableSidebarAction implements Action {
  readonly type = SidebarActionTypes.EnableSidebar;
}

export class DisableSidebarAction implements Action {
  readonly type = SidebarActionTypes.DisableSidebar;
}

export class ToggleSidebarEnabledAction implements Action {
  readonly type = SidebarActionTypes.ToggleSidebarEnabled;
}

export type SidebarActions = EnableSidebarAction
  | DisableSidebarAction
  | ToggleSidebarEnabledAction;

export interface SidebarState {
  sidebarEnabled: boolean;
}

export const initialState: SidebarState = {
  sidebarEnabled: false
};

export function sidebarStateReducer(state: SidebarState = initialState,
                                    action: SidebarActions): SidebarState {
  switch (action.type) {
    case SidebarActionTypes.EnableSidebar:
      return {...state, sidebarEnabled: true};
    case SidebarActionTypes.DisableSidebar:
      return {...state, sidebarEnabled: false};
    case SidebarActionTypes.ToggleSidebarEnabled:
      return {...state, sidebarEnabled: !state.sidebarEnabled};
    default:
      return state;
  }
}

export const sidebarStateName = 'sidebarFeature';

export interface SidebarReducerState {
  sidebar: SidebarState;
}

export const sidebarReducers: ActionReducerMap<SidebarReducerState> = {
  sidebar: sidebarStateReducer,
};

export const getSidebarFeatureState = createFeatureSelector<SidebarReducerState>(
  sidebarStateName
);
