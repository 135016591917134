import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {BenutzermanagementAPIService} from "../../api/benutzermanagement/benutzermanagement-api.service";
import {Store} from "@ngrx/store";
import {getBenutzerInfoFeatureState} from "../../reducers/authinfo.reducer";
import {Subscription} from "rxjs";
import {AuthDeviceSetupResponse201, TfaMethod} from "../../api/benutzermanagement/models";
import {take} from "rxjs/operators";
import * as moment from "moment";
import {AppToastService} from "../../toast/app-toast.service";
import {Router} from "@angular/router";
import {MODULE_ROOT} from "../root.config";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'san-add2-fadevice',
  templateUrl: './add2-fadevice.component.html',
  styleUrls: ['./add2-fadevice.component.scss']
})
export class Add2FADeviceComponent implements OnInit, OnDestroy {
  @HostBinding('class.d-block') dblock = true;
  @HostBinding('class.p-3') pad = true;

  loadingIcon = faSpinner;

  transmitting = false;

  methodLocked = false;
  method: TfaMethod = 'TOTP';
  password: '';

  setupResponse: any | AuthDeviceSetupResponse201;
  errorMessage = null;
  verificationPin: string;
  deviceName = '';

  defaultDeviceName: string;
  verificationPinInvalid = false;

  constructor(private bmClient: BenutzermanagementAPIService, private store: Store,
              private toastService: AppToastService, private router: Router) {
    this.defaultDeviceName = `Neues Gerät (${moment().format('DD.MM.YYYY')})`;
  }

  ngOnInit(): void {
  }

  reset() {
    this.methodLocked = false;
    this.setupResponse = null;
    this.transmitting = false;
    this.verificationPinInvalid = false;
  }

  ngOnDestroy(): void {
  }

  requestNewDevice() {
    this.errorMessage = null;
    this.methodLocked = true;
    this.transmitting = true;
    this.store.select(getBenutzerInfoFeatureState).pipe(take(1)).subscribe(value => {
      this.bmClient.setupDevice({
        method: this.method,
        username: value.auth.username,
        password: this.password,
      }, true).subscribe((value: AuthDeviceSetupResponse201) => {
        this.setupResponse = value;
        this.transmitting = false;
      }, error => {
        this.transmitting = false;
        if (error.status === 403) {
          // wrong credentials
          this.errorMessage = 'Ihr Passwort ist falsch. Bitte versuchen Sie erneut. Zu viele fehlgeschlagene ' +
            'Versuche in einem kurzen Zeitraum führen dazu, dass eine Anmeldung und Änderung kurzzeitig geblockt wird';
        } else if (error.status === 406) {
          // account locked
          this.errorMessage = 'Ihr Passwort wurde zu oft falsch eingegebene. Bitte versuchen Sie es später erneut';
        } else {
          this.errorMessage = 'Eine Aktivierung ist derzeit nicht möglich. Bitte versuchen Sie es später erneut';
        }
      })
    });
  }

  verifyDevice() {
    this.verificationPinInvalid = false;
    this.errorMessage = null;
    this.transmitting = true;
    this.store.select(getBenutzerInfoFeatureState).pipe(take(1)).subscribe(value => {
      this.bmClient.activateDevice({
        method: this.method,
        otp_token: this.verificationPin,
        password: this.password,
        username: value.auth.username,
        device_name: (this.deviceName) ? this.deviceName : this.defaultDeviceName,
      }, true).subscribe(value1 => {
        this.toastService.show('Gerät aktiviert', '', 'success');
        this.router.navigate([MODULE_ROOT])
        this.transmitting = false;
      }, errorResponse => {
        const error = errorResponse.error;
        if (error.errors) {
          if (error.errors.token) {
            this.verificationPinInvalid = true;
          }
        }
        this.toastService.show('Gerät nicht aktiviert', 'Ein Fehler ist aufgetrteten', 'danger');
        this.transmitting = false;
      });
    });
  }
}
