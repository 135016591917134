<h4>Herzlich Willkommen im Sanakey-Portal!</h4>
<p>
  Zur Teilnahme an Verträgen zur besonderen Versorgung bieten wir für Sie als Patient folgende Möglichkeiten:
</p>
<p class="text-center">
  - Teilnahme direkt online bestätigen,<br>
  - Informationen zur Teilnahme, dem besonderen Versorgungsangebot und zu Therapieinhalten abrufen,<br>
  - Teilnahmeerklärungen herunterladen<br>
  - Teilnahme online widerrufen<br>
</p>
<p>
  Der Zugang ist besonders geschützt. Ihr behandelnder Arzt startet für Sie den Vorgang zur digitalen Teilnahme am
  Versorgungsvertrag. Anschließend erhalten Sie eine E-Mail mit einem persönlichen Link und eine SMS mit einem PIN-Code.
</p>
<p>
  Sollten Sie Fragen zur Teilnahme an Verträgen zur besonderen Versorgung haben, melden Sie sich bei Ihrem behandelnden
  Arzt, Ihrer Krankenkasse oder schreiben Sie uns eine E-Mail an
  <a href="mailto:service@sanakey-portal.de">service@sanakey-portal.de</a>.
</p>
