import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {PartnerPreviewResponse, PreviewResponse} from "../../api/client.service";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'san-zustimmung-partner',
  templateUrl: './zustimmung-partner.component.html',
  styleUrls: ['./zustimmung-partner.component.scss']
})
export class ZustimmungPartnerComponent implements OnInit {
  @Input() token: string;
  @Input() pin: string;
  @Input() state: PartnerPreviewResponse;
  @Input() sending = false;

  @Output() bestaetigenClicked = new EventEmitter();

  spinnerIcon = faSpinner;

  bed1 = false;
  bed2 = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  onBestaetigenClicked() {
    if (this.sending || !this.bed1 || !this.bed2) return;
    this.bestaetigenClicked.emit()
  }
}
