import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Resolve} from '@angular/router';
import {Observable} from 'rxjs';
import {BenutzermanagementAPIService} from '../api/benutzermanagement/benutzermanagement-api.service';
import {Jwt} from '../api/benutzermanagement/models';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private benutzermanagementApi: BenutzermanagementAPIService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable<boolean>(subscriber => {
      return this.benutzermanagementApi.isAuthenticated().pipe(take(1)).subscribe(l => {
        if (!l) {
          this.benutzermanagementApi.setNextAfterLogin(state.url);
          this.router.navigate(['']);
        } else {
          subscriber.next(l);
        }
      });
    });
  }
}
