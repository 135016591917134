<div class="p-5">
  <div class="jumbotron">
    <h1 class="display-4">Seite nicht gefunden.</h1>
    <p class="lead">Die von Ihnen aufgerufene Seite wurde bei uns nicht gefunden.</p>
    <hr class="my-4">
    <p>Wenn Sie über einen Link hier hin gekommen sind, dann prüfen Sie diesen bitte.</p>
    <p class="lead">
      <a class="btn btn-primary btn-lg" routerLink="/" role="button">Zur Startseite</a>
    </p>
  </div>
</div>
