import {Injectable} from '@angular/core';
import {Actions, createEffect, CreateEffectMetadata, ofType} from '@ngrx/effects';
import {
    AuthinfoActionTypes,
    getBenutzerInfoFeatureState,
    ProductsinfoActionTypes,
    ProductsLoadedAction,
    ProductsLoadingAction
} from './authinfo.reducer';
import {catchError, delay, filter, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {SidebarActionTypes} from './sidebar.reducer';
import {HeaderActionTypes} from './header.reducer';
import {BenutzermanagementAPIService} from '../api/benutzermanagement/benutzermanagement-api.service';
import {EMPTY, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {LoadingState} from '../shared/reducer.includes';
import {LoadVisibleExtradataPageAction, RefreshExtradataInstancesAction} from './userdata.reducer';

@Injectable()
export class AuthinfoEffects {

    // Effects
    // noinspection JSUnusedGlobalSymbols
    enableSidebar$: Observable<{ type: SidebarActionTypes }> & CreateEffectMetadata;

    // noinspection JSUnusedGlobalSymbols
    enableAccountIcon$: Observable<{ type: HeaderActionTypes }> & CreateEffectMetadata;

    // noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols
    loadProducts$: Observable<ProductsLoadedAction> & CreateEffectMetadata;

    // noinspection JSUnusedGlobalSymbols
    disableSidebar$: Observable<{ type: SidebarActionTypes }> & CreateEffectMetadata;

    // noinspection JSUnusedGlobalSymbols
    disableAccountIcon$: Observable<{ type: HeaderActionTypes }> & CreateEffectMetadata;

    constructor(private actions$: Actions,
                private benutzerApi: BenutzermanagementAPIService,
                private store: Store) {
        this.disableAccountIcon$ = createEffect(() => this.actions$.pipe(
            ofType(AuthinfoActionTypes.SetUnauthenticated),
            map(() => {
                return {type: HeaderActionTypes.HideAccountIcon};
            })
        ));
        this.disableSidebar$ = createEffect(() => this.actions$.pipe(
            ofType(AuthinfoActionTypes.SetUnauthenticated),
            map(() => {
                return {type: SidebarActionTypes.DisableSidebar};
            })
        ));
        this.loadProducts$ = createEffect(() => this.actions$.pipe(
            ofType(AuthinfoActionTypes.SetAuthenticated, ProductsinfoActionTypes.DoLoadProducts),
            withLatestFrom(this.store.select(getBenutzerInfoFeatureState)),
            filter(([action, benutzerInfoState]) => {
                return benutzerInfoState?.products.state !== LoadingState.Loading;
            }),
            map(() => {
                this.store.dispatch(new RefreshExtradataInstancesAction());
                this.store.dispatch(new LoadVisibleExtradataPageAction());
                this.store.dispatch(new ProductsLoadingAction());
            }),
            delay(100),
            mergeMap(() => this.benutzerApi.getProducts().pipe(
                map(r => new ProductsLoadedAction(r.results)),
                catchError(() => EMPTY)
            )),
        ));
        // noinspection JSUnusedGlobalSymbols
        this.enableAccountIcon$ = createEffect(() => this.actions$.pipe(
            ofType(AuthinfoActionTypes.SetAuthenticated),
            delay(500),
            map(() => {
                return {type: HeaderActionTypes.ShowAccountIcon};
            })
        ));
        this.enableSidebar$ = createEffect(() => this.actions$.pipe(
            ofType(AuthinfoActionTypes.SetAuthenticated),
            delay(500),
            map(() => {
                return {type: SidebarActionTypes.EnableSidebar};
            })
        ));
    }
}
