import {Action, ActionReducerMap, createFeatureSelector} from '@ngrx/store';
import {Product} from '../api/benutzermanagement/models';
import {LoadingState} from '../shared/reducer.includes';

export enum AuthinfoActionTypes {
  SetAuthenticated = '[Authinfo] Set Authenticated',
  SetUnauthenticated = '[Authinfo] Set Unauthenticated',
  AuthDataFromStorageLoaded = '[Authinfo] Loaded Auth Data from local storage',
}

export enum ProductsinfoActionTypes {
  DoLoadProducts = '[Products] Load Products',
  ProductsLoading = '[Products] Products Loading',
  ProductsLoaded = '[Products] Products Loaded',
}

export class SetAuthenticatedAction implements Action {
  readonly type = AuthinfoActionTypes.SetAuthenticated;

  constructor(public username: string, public device_id: number) {
  }
}

export class SetUnauthenticatedAction implements Action {
  readonly type = AuthinfoActionTypes.SetUnauthenticated;
}

export class AuthDataFromStorageLoadedAction implements Action {
  readonly type = AuthinfoActionTypes.AuthDataFromStorageLoaded;
}

export class ProductsLoadedAction implements Action {
  readonly type = ProductsinfoActionTypes.ProductsLoaded;

  constructor(public payload: Product[]) {
  }
}

export class ProductsLoadingAction implements Action {
  readonly type = ProductsinfoActionTypes.ProductsLoading;
}

export class LoadProductsAction implements Action {
  readonly type = ProductsinfoActionTypes.DoLoadProducts;
}

export type AuthinfoActions = SetAuthenticatedAction
  | SetUnauthenticatedAction
  | AuthDataFromStorageLoadedAction;

export type ProductinfoStateActions = ProductsLoadingAction
  | ProductsLoadedAction
  | LoadProductsAction;

export interface AuthinfoState {
  isAuthenticated: boolean;
  username: string;
  device_id: number;
}


export interface ProductsinfoState {
  state: LoadingState;
  allRawProducts: Product[];
  activeProducts: Product[];
}

export const initialAuthState: AuthinfoState = {
  isAuthenticated: false,
  username: '',
  device_id: null,
};


export const initialProductState: ProductsinfoState = {
  state: LoadingState.Init,
  allRawProducts: [],
  activeProducts: [],
};

export function AuthinfoStateReducer(state: AuthinfoState = initialAuthState,
                                     action: AuthinfoActions): AuthinfoState {
  switch (action.type) {
    case AuthinfoActionTypes.SetAuthenticated:
      return {...state, isAuthenticated: true, username: action.username, device_id: action.device_id};
    case AuthinfoActionTypes.SetUnauthenticated:
      return {...state, isAuthenticated: false};
    default:
      return state;
  }
}

export function ProductsinfoStateReducer(state: ProductsinfoState = initialProductState,
                                         action: ProductinfoStateActions): ProductsinfoState {
  switch (action.type) {
    case ProductsinfoActionTypes.ProductsLoading:
      return {...state, state: LoadingState.Loading};
    case ProductsinfoActionTypes.ProductsLoaded:
      return {
        ...state,
        state: LoadingState.Loaded,
        allRawProducts: action.payload,
        activeProducts: action.payload.filter(p => {
          return !p.is_module && !p.is_static_link && p.status === 'aktiv';
        })
      };
    default:
      return state;
  }
}

export const BenutzerInfoStateName = 'BenutzerInfoFeature';

export interface BenutzerInfoReducerState {
  auth: AuthinfoState;
  products: ProductsinfoState;
}

export const BenutzerInfoReducers: ActionReducerMap<BenutzerInfoReducerState> = {
  auth: AuthinfoStateReducer,
  products: ProductsinfoStateReducer,
};

export const getBenutzerInfoFeatureState = createFeatureSelector<BenutzerInfoReducerState>(
  BenutzerInfoStateName
);
