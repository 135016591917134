import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {AppToastService} from '../../toast/app-toast.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';

export enum DIFASciencPopupState {
  welcome,
  info,
  dismiss,
}

@Component({
  selector: 'san-difa-science-info',
  templateUrl: './difa-science-info.component.html',
  styleUrls: ['./difa-science-info.component.css']
})
export class DifaScienceInfoComponent implements OnInit {
  DIFASciencPopupState = DIFASciencPopupState;
  state: DIFASciencPopupState = DIFASciencPopupState.welcome;

  constructor(private activeModal: NgbActiveModal, private bmClient: BenutzermanagementAPIService,
              private toastService: AppToastService, private http$: HttpClient, private router: Router) {
  }

  ngOnInit(): void {
  }

  onEMailPaketBtnClick() {
    this.activeModal.dismiss();
    this.bmClient.getUserdata(1, 100, null, null, [
      'Sanakey/Standard E-Mail',
    ])
      .subscribe(ediPage => {
        for (const edi of ediPage.results) {
          const key = `${edi.extradata.namespace}/${edi.extradata.name}`;
          if (key === 'Sanakey/Standard E-Mail' && edi.value) {
            return this.requestEmailPaket(edi.value);
          }
        }
        this.toastService.show('Ein Fehler ist aufgetreten',
          'Ihre E-Mail Adresse konnte leider nicht abgerufen werden', 'danger');
      });
  }

  onWeiterBtnClick() {
    this.state = DIFASciencPopupState.info;
  }

  onInfoZurueckBtnClick() {
    this.state = DIFASciencPopupState.welcome;
  }

  onHierInformierenBtnClick() {
    // Open DIFA Website in new tab
    window.open('https://cloud.sanakey-portal.de/s/ZWAfn9F6F6ebeZo', '_blank');
  }

  onJetztTeilnehmenBtnClick() {
    this.activeModal.dismiss();
    this.bmClient.setPopupViewed('DIFA Science Info Popup', false, 'DIFA Science Registrierung gestartet').subscribe();
    this.router.navigate(['/', 'difa', 'aktivierung', 'difascience']);
  }

  onSpaeterTeilnehmenBtnClick() {
    this.state = DIFASciencPopupState.dismiss;
  }

  onSchliessenClicked() {
    // send "dismiss" to server
    this.activeModal.dismiss();
    this.bmClient.setPopupViewed('DIFA Science Info Popup', true).subscribe();
  }

  onZurueckClicked() {
    this.state = DIFASciencPopupState.info;
  }

  private requestEmailPaket(email: string) {
    this.http$.post(environment.difa_url + '/infopaket/', {
      email,
    }).subscribe(value => {
      this.bmClient.setPopupViewed(
        'DIFA Science Info Popup',
        false,
        'EMail Info Paket angefordert').subscribe();
      this.toastService.show(
        'Info Paket angefordert',
        'Ihr Info Paket ist bald auf dem Weg zu Ihnen', 'success');
    }, error => {
      this.toastService.show('Ein Fehler ist aufgetreten',
        'Ihr Info Paket konnte leider nicht angefragt werden', 'danger');
    });
  }
}
