import {Component, OnInit} from '@angular/core';
import {Device} from "../../api/benutzermanagement/models";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'san-delete-device-popup',
  templateUrl: './delete-device-popup.component.html',
  styleUrls: ['./delete-device-popup.component.scss']
})
export class DeleteDevicePopupComponent implements OnInit {
  device: Device;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  confirmDelete() {
    this.activeModal.close({deleteConfirmed: 'true', device: this.device})
  }

}
