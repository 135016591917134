<div class="container">
  <div class="row">
    <div class="form-group">
      <h3>Richten Sie ein neues Gerät ein</h3>
    </div>
  </div>
  <div class="row">
    <div class="form-group">
      <label>
        Bitte wählen Sie einen Geräte Typ
        <select class="form-select" [(ngModel)]="method" [disabled]="methodLocked">
          <option value="TOTP">TOTP (Authenticator App)</option>
        </select>
      </label>
    </div>
  </div>
  <div class="row">
    <div class="form-group">
      <label class="small">
        Derzeit unterstützen wir die Zwei-Faktor-Authentifizierung durch eine E-Mail TAN oder Authenticator App.
        In Zukunft werden weitere Methoden hinzugefügt.
      </label>
    </div>
  </div>
  <hr>
  <ng-container *ngIf="method === 'TOTP'">
    <div class="row">
      <div class="form-group">
        <p>
          Einrichtungshinweise für TOTP Geräte wie die Authenticator App finden Sie
          <a tabindex="-1" href="https://www.sanakey.de/sanakey-portal/google-authenticator/" target="_blank">hier</a>.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label>
          Zur Einrichtung ist Eingabe Ihres Passworts erforderlich
          <input class="form-control" type="password" [(ngModel)]="password" placeholder="Ihr aktuelles Passwort"
                 [disabled]="setupResponse" (keyup.enter)="requestNewDevice()">
        </label>
        <san-form-input-error [visible]="!password" message="Das Passwort ist erforderlich"></san-form-input-error>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <button class="btn btn-primary" [disabled]="!password || setupResponse || transmitting"
                (click)="requestNewDevice()">
          <span *ngIf="setupResponse">QR Code und Schlüssel wurden generiert</span>
          <span *ngIf="!setupResponse">QR Code und Schlüssel generieren</span>
          <span *ngIf="!setupResponse && transmitting">
              &nbsp;
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon></span>
        </button>
      </div>
    </div>
    <ng-container *ngIf="setupResponse">
      <div class="row">
        <div class="form-group">
          <label>
            Ihr QR Code für die Authenticator App:
          </label>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="form-group">
          <img class="img-thumbnail" [src]="setupResponse.qrcode" [alt]="setupResponse.alt_code" width="200"
               height="auto">
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <label>
            Ihr Schlüssel für die Einrichtung ohne QR Code:
          </label>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="form-group text-center">
          <span *ngIf="setupResponse._showKey">{{setupResponse.alt_code}}</span><br/>
          <a *ngIf="!setupResponse._showKey" href="javascript:void(0)"
             (click)="setupResponse._showKey = true">
            Schlüssel anzeigen
          </a>
          <a *ngIf="setupResponse._showKey" href="javascript:void(0)"
             (click)="setupResponse._showKey = false">
            Schlüssel verbergen
          </a>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <label>
            Bestätigen Sie das neue Gerät, indem Sie die angezeigte TAN (sechs Ziffern) eingeben:
            <input class="form-control" type="text" maxlength="6" minlength="6" [(ngModel)]="verificationPin">
          </label>
          <san-form-input-error message="Die TAN ist ungültig"
                                [visible]="verificationPinInvalid"></san-form-input-error>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <label>
            Sie können Ihr Gerät benennen, um es besser identifizieren zu können (ansonsten wird es
            "{{defaultDeviceName}}" benannt):
            <input class="form-control" type="text" [(ngModel)]="deviceName" [placeholder]="defaultDeviceName">
          </label>
        </div>
      </div>
      <div class="row">
        <div class="form-group">
          <button class="btn btn-primary" (click)="verifyDevice()" [disabled]="!verificationPin || transmitting">
            Gerät aktivieren und speichern
            <span *ngIf="setupResponse && transmitting">
              &nbsp;
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon></span>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="row">
    <div class="form-group">
      <san-form-input-error [visible]="errorMessage" [message]="errorMessage"></san-form-input-error>
    </div>
  </div>
</div>
