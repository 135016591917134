import {Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {SideBarService} from '../side-bar.service';
import {faCog, faFileAlt, faSignOut, faTachometerAlt} from '@fortawesome/pro-light-svg-icons';
import {Router} from '@angular/router';
import {AppToastService} from '../../toast/app-toast.service';
import {select, Store} from '@ngrx/store';
import {getSidebarFeatureState} from '../../reducers/sidebar.reducer';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {getBenutzerInfoFeatureState, ProductsinfoState} from '../../reducers/authinfo.reducer';
import {faCircle, faQuestion} from '@fortawesome/pro-solid-svg-icons';
import {mapping} from 'src/app/app-routing/products.uuids';
import {getNavigationFeatureState, ProductRoutesState} from '../../reducers/navigation.reducer';
import {LoadingState} from 'src/app/shared/reducer.includes';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'san-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit, OnDestroy, OnChanges {
  // @HostBinding('class.h-100') h100 = true;
  @HostBinding('class.invisible') invisible = true;
  @Input() @HostBinding('class.active') active = false;

  // @Input() active = false;

  hover = false;

  meinSanakeyPortalIcon = faTachometerAlt;
  meineStammdaten = faCog;
  dokumenteVertraegeIcon = faFileAlt;
  abmeldenIcon = faSignOut;
  abmeldungLaeuft: any;
  spinnerIcon = faSpinner;
  productIcon = faCircle;

  mapping = mapping;

  LoadingState = LoadingState;
  productsInfo: ProductsinfoState = {
    allRawProducts: [],
    activeProducts: [],
    state: LoadingState.Init
  };
  productsRoutes: ProductRoutesState = {};
  private sidebarState$: Subscription;
  private benutzerState$: Subscription;
  private navigationState$: Subscription;
  helpIcon = faQuestion;

  constructor(private sidebarService: SideBarService, public router: Router, private toastService: AppToastService,
              private store: Store, private benutzermanagementApi: BenutzermanagementAPIService) {
    this.sidebarService.setInstance(this);

    this.sidebarState$ = this.store.pipe(select(getSidebarFeatureState)).subscribe((sidebarState) => {
      this.invisible = !sidebarState.sidebar.sidebarEnabled;
    });

    this.benutzerState$ = this.store.pipe(select(getBenutzerInfoFeatureState)).subscribe((state) => {
      this.productsInfo = state.products;
    });

    this.navigationState$ = this.store.pipe(select(getNavigationFeatureState)).subscribe((state) => {
      this.productsRoutes = state.productRoutes;
    });
  }

  ngOnDestroy(): void {
    this.sidebarState$.unsubscribe();
    this.benutzerState$.unsubscribe();
    this.navigationState$.unsubscribe();
  }

  ngOnInit(): void {
  }

  abmeldenClicked() {
    this.abmeldungLaeuft = true;
    this.benutzermanagementApi.logOut().pipe(take(1)).subscribe(() => {
      this.router.navigate(['/']);
      this.toastService.show('Sie wurden abgemeldet', 'Bis zum nächsten Mal', 'success');
      this.abmeldungLaeuft = false;
    }, err => {
      this.toastService.show('Ein Fehler ist aufgetreten', 'Sie konnten nicht abgemeldet werden (S.100)', 'danger');
      this.abmeldungLaeuft = false;
    });
  }

  noop() {
  }

  onMouseEnter() {
    this.hover = true;
  }

  onMouseExit() {
    this.hover = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }


}
