import {Component, Input} from '@angular/core';
import {Location} from '@angular/common';
import {faChevronLeft} from '@fortawesome/pro-light-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'san-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
  @Input('label') label = 'Zurück';
  @Input('icon') icon = faChevronLeft;
  @Input('target-up') targetUp = true;

  constructor(private location: Location, private router: Router) {
  }

  goBack() {
    if (this.targetUp) {
      this.router.navigate(['..']);
    } else {
      this.location.back();
    }
  }
}
