<div class="modal-header" *ngIf="showTitle">
  <h3 class="modal-title">{{ showTitle }}</h3>
  <button *ngIf="activeModal && allowClose" type="button" class="btn-close" aria-label="schließen"
    (click)="activeModal?.dismiss()"></button>
</div>
<form class="p-3" [formGroup]="loginForm" (ngSubmit)="sendData()">

  <div class="input-group">
    <div class="input-group-text validity-indicator-bg" [ngClass]="{
              'has-error': response > Responses.noError || !(loginForm.controls.username.disabled || loginForm.controls.username.valid || loginForm.controls.username.pristine),
              'is-valid':step > Steps.step1
              }">
      <fa-icon [icon]="userIcon" class="align-middle"></fa-icon>
    </div>
    <input type="text" class="form-control" id="username" placeholder="E-Mail oder Kundennummer" #usernameInput
      autofocus formControlName="username" aria-describedby="userIcon" aria-label="E-Mail oder Kundennummer"
      tabindex="1" autocomplete="current-username">
  </div>
  <san-form-input-error
    [visible]="!loginForm.controls.username.pristine && loginForm.controls.username.errors && loginForm.controls.username.errors.required && !loginForm.controls.username.errors.pattern"
    message="Bitte geben Sie Ihre Kundennummer oder E-Mail Adresse an"></san-form-input-error>
  <san-form-input-error [visible]="loginForm.controls.username.errors && loginForm.controls.username.errors.pattern"
    message="Bitte geben Sie Ihre Kundennummer (8-stellig) an"></san-form-input-error>

  <div *ngIf="forgotPasswordMode === PasswordResetSteps.off">
    <div class="input-group my-3">
      <div class="input-group-text validity-indicator-bg" [ngClass]="{
              'has-error': response > Responses.noError || !(loginForm.controls.password.disabled || loginForm.controls.password.valid || loginForm.controls.password.pristine),
              'is-valid':step > Steps.step1
              }">
        <fa-icon [icon]="passwordIcon" class="align-middle"></fa-icon>
      </div>
      <input type="password" class="form-control" id="password" placeholder="Passwort" formControlName="password"
        aria-describedby="passwordIcon" aria-label="Passwort" tabindex="2" #passwordInput
        autocomplete="current-password">
    </div>
    <san-form-input-error
      [visible]="!loginForm.controls.password.pristine && loginForm.controls.password.errors && loginForm.controls.password.errors.required"
      message="Bitte geben Sie Ihr Passwort an"></san-form-input-error>
    <div class="w-100 text-center">
      <fa-icon *ngIf="loading && [Steps.step1b, Steps.step2].includes($any(step))" [icon]="loadingIcon" size="2x"
        fixedWidth animation="spin-pulse" class="align-middle d-block"></fa-icon>
    </div>
    <div #collapse="ngbCollapse" [ngbCollapse]="!(!chosenOtpMethod && [Steps.step1b, Steps.step2].includes($any(step)))"
      class="text-center w-100">
      <p class="">
        Wie möchten Sie Ihre TAN zur Anmeldung erhalten?
      </p>
      <div *ngFor="let otpMethod of otpMethods">
        <div class="btn btn-primary w-100 mb-1" (click)="setOtpMethod(otpMethod)">
          <span *ngIf="otpMethod === 'TOTP'">TOTP (Authenticator App)</span>
          <span *ngIf="otpMethod === 'EMail'">E-Mail</span>
        </div>
      </div>
    </div>
    <!-- QR Code / OTP Setup -->
    <div class=" input-group text-center w-100" #otpsetup="ngbCollapse"
      [ngbCollapse]="step !== Steps.step1c || chosenOtpMethod !== 'TOTP'">
      <p class="">
        Bitte scannen Sie die Grafik mit der
        <a tabindex="-1" href="https://support.google.com/accounts/answer/1066447" target="_blank">Google
          Authenticator</a>
        App auf Ihrem Smartphone oder Tablet-PC ein.
        Informationen zur Einrichtung des Google Authenticators finden Sie
        <a tabindex="-1" href="https://www.sanakey.de/sanakey-portal/google-authenticator/" target="_blank">hier</a>.
      </p>
      <img class="w-75 ms-auto me-auto" [src]="otpSetupQRCodeURI" [alt]="otpSetupCode">
      <div [hidden]="otpSetupShowCode" class="w-100 text-center mt-1 mb-1 small"><a class="" href="javascript:void(0)"
          tabindex="-1" (click)="otpSetupShowCode=true;">
          Ohne Kamera einrichten</a></div>
      <div [hidden]="!otpSetupShowCode" class="w-100 text-center mt-1 mb-1 small otpcode" [innerHTML]="otpSetupCode">
      </div>
    </div>

    <div>
      <div class="input-group pb-3 delayed" #otpeingabe="ngbCollapse"
        [ngbCollapse]="![Steps.step1c, Steps.step2b].includes($any(step))">
        <div class="input-group-text validity-indicator-bg" [ngClass]="{
              'has-error': !loginForm.controls.otp.disabled && (response > Responses.noError || !(loginForm.controls.otp.valid || loginForm.controls.otp.pristine)),
              'is-valid': step > Steps.step2b
              }">
          <fa-icon [icon]="phoneIcon" class="align-middle"></fa-icon>
        </div>
        <input type="text" class="form-control" id="otp" [placeholder]="tanPlaceholder" formControlName="otp" #otpInput
          [tabindex]="(step > Steps.step1b) ? 3 : -1" aria-describedby="authenticatorIcon" aria-label="Geräte Code"
          autocomplete="off">
        <button *ngIf="![Steps.step1b, Steps.step2].includes($any(step))" class="btn btn-primary w-100 mt-3">
          <fa-icon *ngIf="!loading" [icon]="passwordIcon" fixedWidth class="align-middle"></fa-icon>
          <fa-icon *ngIf="loading" [icon]="loadingIcon" fixedWidth animation="spin-pulse"
            class="align-middle"></fa-icon>
          &nbsp;
          <span *ngIf="step === Steps.step1c">Gerät aktivieren und anmelden</span>
          <span *ngIf="step === Steps.step1 || step > Steps.step1c">Anmelden</span>
        </button>
      </div>
      <san-form-input-error
        [visible]="!loginForm.controls.otp.pristine && loginForm.controls.otp.errors && loginForm.controls.otp.errors.required"
        message="Bitte geben Sie Ihren Geräte Code an"></san-form-input-error>
      <san-form-input-error
        [visible]="!loginForm.controls.otp.pristine && loginForm.controls.otp.errors && loginForm.controls.otp.errors.pattern"
        message="Ihr Geräte Code muss aus sechs Ziffern bestehen"></san-form-input-error>
      <san-form-input-error [visible]="response === Responses.emailAmbigious"
        message="Die übergebene E-Mail Adresse kommt mehrfach vor. Bitte benutzen Sie Ihre Kundennummer"></san-form-input-error>
      <san-form-input-error [visible]="response === Responses.unavailableTFMethod"
        message="Die Zwei-Faktor Methode ist nicht verfügbar"></san-form-input-error>
      <san-form-input-error [visible]="response === Responses.alreadyRegistered"
        message="Für Ihren Account wurde bereits ein Gerät registriert"></san-form-input-error>
      <san-form-input-error [visible]="response === Responses.wrongCredentials"
        message="Die übergebenen Daten sind fehlerhaft"></san-form-input-error>
      <san-form-input-error [visible]="response === Responses.accountNotVerified"
        message="Ihr Konto wurde noch nicht aktiviert"></san-form-input-error>
      <san-form-input-error [visible]="response === Responses.accountLocked"
        message="Ihr Konto wurde temporär gesperrt. Bitte versuchen Sie es in 15 Minuten noch ein Mal"></san-form-input-error>
    </div>

    <button *ngIf="step === Steps.step1" class="btn btn-primary w-100">
      <fa-icon *ngIf="!loading" [icon]="passwordIcon" fixedWidth class="align-middle"></fa-icon>
      <fa-icon *ngIf="loading" [icon]="loadingIcon" fixedWidth animation="spin-pulse" class="align-middle"></fa-icon>
      &nbsp;
      <span *ngIf="step === Steps.step1">Anmelden</span>
    </button>
    <div class="w-100 mt-1 mb-1 small d-flex flex-row justify-content-end">
      <a class="" href="javascript:void(0)" (click)="forgotPasswordClicked()" tabindex="-1">
        Passwort vergessen?
      </a>
    </div>
  </div>

  <div *ngIf="forgotPasswordMode > PasswordResetSteps.off">
    <div class="d-flex flex-column align-items-center text-center pb-3">
      <div #captchaanzeige="ngbCollapse"
        [ngbCollapse]="![PasswordResetSteps.verifyHuman, PasswordResetSteps.error].includes($any(forgotPasswordMode))">
        <p>Bitte bestätigen Sie, dass Sie ein Mensch sind:</p>
        <re-captcha (resolved)="forgotPasswordClicked($event)" #recaptchaComponent
          [siteKey]="recaptchaSiteKey"></re-captcha>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center text-center pb-3 text-success">
      <div #captchaanzeige="ngbCollapse" [ngbCollapse]="forgotPasswordMode !== PasswordResetSteps.success">
        <p>
          Bitte folgen Sie den Anweisungen, die wir Ihnen per E-Mail gesendet haben.
        </p>
        <fa-icon [icon]="checkIcon" size="4x"></fa-icon>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center shrink-top-animated text-center pb-3">
      <div #datenpruefung="ngbCollapse" [ngbCollapse]="forgotPasswordMode !== PasswordResetSteps.loading">
        <p>
          Ihre Daten werden geprüft ...
        </p>
        <fa-icon animation="spin-pulse" [icon]="loadingIcon" size="4x"></fa-icon>
      </div>
    </div>
  </div>
</form>