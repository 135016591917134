<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="shrink-top-animated" [class.out]="loading">
        <div class="text-center my-5">
          <fa-icon class="" [icon]="spinnerIcon" animation="spin-pulse" size="3x"></fa-icon>
          <div class="">Die Ansicht wird geladen ...</div>
        </div>
      </div>
      <div class="shrink-top-animated p-0 p-md-3" [class.out]="!loading">
        <h4>Herzlich Willkommen im Sanakey-Portal!</h4>
        <p class="font-weight-bold">
          Ihre Arztpraxis hat Ihnen die Zugangsdaten zu diesem Service zur Verfügung gestellt. Die Sanakey Contract GmbH
          ist beauftragt, Ihre Teilnahme- und Einverständniserklärung für die Teilnahme am Versorgungsvertrag sicher und
          digital einzuholen.
        </p>
        <p>
          Bitte bestätigen Sie nachfolgend die Datenschutzbestimmungen und Nutzungsbedingungen und klicken dann auf
          "Anmelden". <br>
          Anschließend erhalten Sie alle Informationen zum Versorgungsvertrag, der Therapie und die Teilnahmebedingungen.
        </p>
        <p class="text-center p-lg-5">
          <label>
            <input type="checkbox" [(ngModel)]="datenschutzAkzeptiert" [value]="true"/>
            Mit der Anmeldung bestätige ich, dass ich die <a [href]="environment.datenschutzbestimmungen"
                                                             target="_blank">Datenschutzbestimmungen</a>
            und <a [href]="environment.nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a> gelesen und
            akzeptiert habe.
          </label><br>
          <button class="btn btn-primary m-lg-5" [disabled]="!datenschutzAkzeptiert"
                  (click)="datenschutzAkzeptiert && anmeldenClicked.emit()">Anmelden</button>
        </p>
      </div>
    </div>
  </div>
</div>
