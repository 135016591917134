<!-- MIDDLE -->
<div class="d-none d-sm-block left mt-5 mt-lg-0">
  <div class="sanakey-container border m-0 p-0 px-4 pb-4">
    <san-pictures></san-pictures>
  </div>
  <div class="sanakey-container border mt-5">
<!--    <san-logos></san-logos>-->
    <ng-template #carouselImage let-element="element" let-index="index">
      <div class="carouselImageContainer">
        <a class="img" target="_blank" [href]="element.link" [style.background-image]="'url('+element.image+')'"></a>
      </div>
    </ng-template>
    <san-carousel [elements]="carouselTileItems" [template]="carouselImage" [visibleElements]="nVisibleCarouselItems">
    </san-carousel>
  </div>
</div>
<div class="right mt-5 mt-lg-0 pt-1 pt-lg-0" #top>
  <div class="sanakey-container border ms-0 ms-lg-5 p-4">
    <p>
      Mit Klick auf „Anmeldung starten“ startet der Anmeldeprozess. Mit Klick auf „Neues Benutzerkonto“ können Sie
      sich bei uns ein neues Benutzerkonto einrichten. Bei beiden Prozessen werden Ihre erforderliche personenbezogenen
      Daten verarbeitet.
    </p>
    <p>
      Mit Klick auf „Anmeldung starten“ oder „Neues Benutzerkonto“ bestätigen Sie, dass Sie unsere
      <a href="javascript:void(0)" (click)="openDatenschutz()">Datenschutzbestimmungen</a> gelesen und verstanden haben
      und willigen in die Verarbeitung Ihrer erforderlichen personenbezogenen Daten ein.
    </p>
    <a class="btn btn-primary w-100" href="javascript:void(0)" tabindex="-1" (click)="openLoginModal()">
      <fa-icon [icon]="passwordIcon" class="align-middle"></fa-icon>
      &nbsp;Anmeldung starten
    </a>
    <a class="btn btn-primary w-100 mt-3" href="javascript:void(0)" routerLink="/registrierung" tabindex="-1">
      <fa-icon [icon]="newUserIcon" class="align-middle"></fa-icon>&nbsp;Neues Benutzerkonto
    </a>
  </div>
</div>
