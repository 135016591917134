<h4>Ändern Sie Ihr Passwort</h4>
<p>
  Um Ihr Passwort mit sofortiger Wirkung zu ändern ist es notwendig, dass Sie Ihre altes Passwort eingeben. Dann können
  Sie Ihr neues Passwort wählen. Die geschätzte Stärke Ihres neuen Passworts wird Ihnen bei Eingabe angezeigt.
</p>
<p>
  Es gilt ein verpflichtendes Sicherheitsniveau. Dieses sieht vor, dass Ihr Passwort bestimmte Elemente nicht enthalten
  darf. Darunter fallen beispielsweise Ihr Vorname, Nachname und weitere Ihrer Daten. Die Auflistung sehen Sie unten
  unter der Eingabe Ihres neuen Passworts. Die roten unerfüllten Eigenschaften werden grün dargestellt sobald sie
  erfüllt werden.
</p>
<form class="container" [formGroup]="changeForm">
  <input type="hidden" [value]="userData.username" autocomplete="username">
  <div class="row">
    <div class="col-12">
      <label>
        Ihr altes Passwort
        <input type="password" class="form-control" placeholder="Ihr altes Passwort" formControlName="old_password"
               autocomplete="current-password">
      </label>
      <san-form-input-error message="Das alte Passwort muss eingegeben werden"
                            [visible]="changeForm.get('old_password').dirty && changeForm.get('old_password').errors?.required"></san-form-input-error>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <label>
        Ihr neues Passwort
        <input type="password" class="form-control" placeholder="Ihr neues Passwort" formControlName="new_password"
               autocomplete="new-password" (ngModelChange)="recalculatePasswordStrength()">
      </label>
      <san-form-input-error message="Das neue Passwort muss eingegeben werden"
                            [visible]="changeForm.get('new_password').dirty && changeForm.get('new_password').errors?.required"></san-form-input-error>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ul class="small list-unstyled d-block">
        <li [ngClass]="{
        'text-danger': !changeForm.controls.new_password.value || changeForm.controls.new_password.errors?.minlength,
        'text-success': changeForm.controls.new_password.value && !changeForm.controls.new_password.errors?.minlength}">
          Mindestens 8 Zeichen
        </li>
        <li [ngClass]="{
        'text-danger': !changeForm.controls.new_password.value || changeForm.controls.new_password.errors?.enthaeltZiffer,
        'text-success': changeForm.controls.new_password.value && !changeForm.controls.new_password.errors?.enthaeltZiffer}">
          Mindestens 1 Zahl
        </li>
        <li [ngClass]="{
        'text-danger': !changeForm.controls.new_password.value || changeForm.controls.new_password.errors?.enthaeltSonderzeichen,
        'text-success': changeForm.controls.new_password.value && !changeForm.controls.new_password.errors?.enthaeltSonderzeichen}">
          Mindestens 1 Sonderzeichen
        </li>
        <li [ngClass]="{
        'text-danger': !changeForm.controls.new_password.value || changeForm.controls.new_password.errors?.enthaeltKleinbuchstabe,
        'text-success': changeForm.controls.new_password.value && !changeForm.controls.new_password.errors?.enthaeltKleinbuchstabe}">
          Mindestens 1 Kleinbuchstabe
        </li>
        <li [ngClass]="{
        'text-danger': !changeForm.controls.new_password.value || changeForm.controls.new_password.errors?.enthaeltGrossbuchstabe,
        'text-success': changeForm.controls.new_password.value && !changeForm.controls.new_password.errors?.enthaeltGrossbuchstabe}">
          Mindestens 1 Großbuchstabe
        </li>
        <li [ngClass]="{
        'text-danger': !changeForm.controls.new_password.value || changeForm.controls.new_password.errors?.enthaeltNichtEmail,
        'text-success': changeForm.controls.new_password.value && !changeForm.controls.new_password.errors?.enthaeltNichtEmail}">
          Darf nicht die E-Mail Adresse enthalten
        </li>
        <li [ngClass]="{
        'text-danger': !changeForm.controls.new_password.value || changeForm.controls.new_password.errors?.enthaeltNichtVornameNachname,
        'text-success': changeForm.controls.new_password.value && !changeForm.controls.new_password.errors?.enthaeltNichtVornameNachname}">
          Darf nicht den Vor- oder Nachnamen enthalten
        </li>
        <li [ngClass]="{
        'text-danger': !changeForm.controls.new_password.value || changeForm.controls.new_password.errors?.enthaeltNichtLanrBsnr,
        'text-success': changeForm.controls.new_password.value && !changeForm.controls.new_password.errors?.enthaeltNichtLanrBsnr}">
          Darf nicht die LANR oder BSNR enthalten
        </li>
      </ul>
      <ngb-progressbar class="mt-1 d-block" max="4" [value]="passwordStrength?.score"></ngb-progressbar>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <label>
        Wiederholen Sie Ihr neues Passwort
        <input type="password" class="form-control" placeholder="Ihr neues Passwort (Wiederholung)"
               formControlName="new_password_2" autocomplete="new-password">
      </label>
      <san-form-input-error message="Das neue Passwort muss erneut eingegeben werden"
                            [visible]="changeForm.get('new_password_2').dirty && changeForm.get('new_password_2').errors?.required"></san-form-input-error>
      <san-form-input-error message="Die Passwörter stimmen nicht überein"
                            [visible]="changeForm.get('new_password_2').dirty && changeForm.get('new_password_2').errors?.match"></san-form-input-error>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <button class="btn btn-primary" (click)="savePassword()" [disabled]="transmitting">
        Passwort ändern<span *ngIf="transmitting">&nbsp;<fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon></span>
      </button>
    </div>
  </div>
</form>
