import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {AppToastService} from '../../toast/app-toast.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'san-lex-online-aktivierung-premium-info-popup',
  templateUrl: './lex-online-aktivierung-premium-info-popup.component.html',
  styleUrls: ['./lex-online-aktivierung-premium-info-popup.component.scss']
})
export class LexOnlineAktivierungPremiumInfoPopupComponent implements OnInit {

  static ID = 'Lex Online Aktivierung Premium Info';
  bestaetigen = true;

  constructor(private activeModal: NgbActiveModal, private bmClient: BenutzermanagementAPIService,
              private toastService: AppToastService, private http$: HttpClient, private router: Router) {
  }

  ngOnInit(): void {
  }

  alsGelesenMarkieren() {
    this.activeModal.dismiss();
    this.bmClient.setPopupViewed(LexOnlineAktivierungPremiumInfoPopupComponent.ID, false).subscribe();
  }

  alsWeggeklicktMarkieren() {
    this.activeModal.dismiss();
    this.bmClient.setPopupViewed(LexOnlineAktivierungPremiumInfoPopupComponent.ID, true).subscribe();
  }

  geheZuLexOnline() {
    this.router.navigate(['/', 'lex-online']);
  }

  schliessen(bestaetigen: boolean) {
    if (bestaetigen) {
      this.alsGelesenMarkieren();
    } else {
      this.alsWeggeklicktMarkieren();
    }
  }
}
