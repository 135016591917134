import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'san-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() public text: string;
  infoIcon = faInfoCircle;
  @ViewChild('tooltip') tooltip: NgbTooltip;

  constructor() {
  }

  ngOnInit(): void {
  }

  show() {
    this.tooltip.open();
  }

}
