import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Result} from 'ts-zxcvbn/lib/types';
import {Store} from '@ngrx/store';
import {getExtradataInstanceFeatureState} from '../../reducers/userdata.reducer';
import {LoadingState} from '../../shared/reducer.includes';
import {getBenutzerInfoFeatureState} from '../../reducers/authinfo.reducer';
import {Subscription} from 'rxjs';
import zxcvbn from 'ts-zxcvbn';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {PwdChangeResponse} from '../../api/benutzermanagement/models';
import {Router} from '@angular/router';
import {AppToastService} from '../../toast/app-toast.service';
import {MODULE_ROOT} from '../root.config';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';


function validatorRename(oldValidator: ValidatorFn, newValidatorName) {
  return (control: AbstractControl): ValidationErrors | null => {
    const r = oldValidator(control);
    if (r) {
      const k = Object.keys(r)[0];
      const ret = {};
      ret[newValidatorName] = r[k];
      return ret;
    }
    return null;
  };
}

@Component({
  selector: 'san-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @HostBinding('class.d-block') dblock = true;
  @HostBinding('class.p-3') pad = true;

  loadingIcon = faSpinner;
  transmitting = false;

  passwordStrength: Result;

  userData = {
    loaded: false,
    firstname: '',
    lastname: '',
    lanr: '',
    bsnr: '',
    email: '',
    username: '',
  };

  private passwordValidators = [
    Validators.required,
    validatorRename(Validators.pattern(/[a-z]+/), 'enthaeltKleinbuchstabe'),
    validatorRename(Validators.pattern(/[A-Z]+/), 'enthaeltGrossbuchstabe'),
    validatorRename(Validators.pattern(/[0-9]+/), 'enthaeltZiffer'),
    validatorRename(Validators.pattern(/[`"§´€ß\-_~!@#$%^&*()+=[{\]}|\\<,.>'/;:?]+/), 'enthaeltSonderzeichen'),
    Validators.minLength(8),
    this.enthaeltNichtVornameNachname.bind(this),
    this.enthaeltNichtLanrBsnr.bind(this),
    this.enthaeltNichtEmail.bind(this),
  ];

  changeForm = new FormGroup({
    old_password: new FormControl('', [Validators.required]),
    new_password: new FormControl('', this.passwordValidators),
    new_password_2: new FormControl('', [Validators.required, this.matchesOther.bind(this)]),
  });
  private sub2$: Subscription;
  private sub1$: Subscription;

  constructor(private store: Store, private bmClient: BenutzermanagementAPIService, private router: Router,
              private toastService: AppToastService) {
    this.sub1$ = this.store.select(getBenutzerInfoFeatureState).subscribe(value => {
      if (value.auth.isAuthenticated && value.auth.username) {
        this.userData = {
          ...this.userData,
          username: value.auth.username,
        };
      }
    });
    this.sub2$ = this.store.select(getExtradataInstanceFeatureState).subscribe(r => {
      if (r.ExtradataInstances.loadingState !== LoadingState.Loaded) {
        return;
      }

      const tempUserData = {
        ...this.userData,
        bsnr: '',
        email: '',
        firstname: '',
        lanr: '',
        lastname: '',
        loaded: true
      };

      for (const ud of r.ExtradataInstances.alleExtradataInstances) {
        const key = `${ud.extradata.namespace}/${ud.extradata.name}`;
        switch (key) {
          case 'Sanakey/Vorname':
            tempUserData.firstname = ud.value;
            break;
          case 'Sanakey/Nachname':
            tempUserData.lastname = ud.value;
            break;
          case 'Sanakey/LANR':
            tempUserData.lanr = ud.value;
            break;
          case 'Sanakey/BSNR':
            tempUserData.bsnr = ud.value;
            break;
          case 'Sanakey/Standard E-Mail':
            tempUserData.email = ud.value;
            break;
        }
      }

      this.userData = tempUserData;
    });
  }

  ngOnDestroy(): void {
    this.sub1$?.unsubscribe();
    this.sub2$?.unsubscribe();
  }

  ngOnInit(): void {
  }

  enthaeltNichtVornameNachname(control: AbstractControl): ValidationErrors | null {
    if (!this.userData.loaded) {
      return null;
    }

    if (this.userData.firstname
      && (control.value as string).toLowerCase().includes(this.userData.firstname.toLowerCase())) {
      return {
        enthaeltNichtVornameNachname: true,
      };
    }
    if (this.userData.lastname
      && (control.value as string).toLowerCase().includes(this.userData.lastname.toLowerCase())) {
      return {
        enthaeltNichtVornameNachname: true,
      };
    }
    return null;
  }

  enthaeltNichtLanrBsnr(control: AbstractControl): ValidationErrors | null {
    if (!this.userData.loaded) {
      return null;
    }

    if (this.userData.lanr && (control.value as string).toLowerCase().includes(this.userData.lanr.toLowerCase())) {
      return {
        enthaeltNichtLanrBsnr: true,
      };
    }
    if (this.userData.bsnr && (control.value as string).toLowerCase().includes(this.userData.lanr.toLowerCase())) {
      return {
        enthaeltNichtLanrBsnr: true,
      };
    }
    return null;
  }

  enthaeltNichtEmail(control: AbstractControl): ValidationErrors | null {
    if (!this.userData.loaded) {
      return null;
    }

    if (this.userData.email && (control.value as string).toLowerCase().includes(this.userData.email.toLowerCase())) {
      return {
        enthaeltNichtEmail: true,
      };
    }
    return null;
  }

  matchesOther(control: AbstractControl): ValidationErrors | null {
    if (this.changeForm && this.changeForm.get('new_password').value !== this.changeForm.get('new_password_2').value) {
      return {
        match: true,
      };
    }
    return null;
  }

  recalculatePasswordStrength() {
    this.passwordStrength = zxcvbn(this.changeForm.controls.new_password.value, [
      this.userData.firstname,
      this.userData.lastname,
      this.userData.email,
      this.userData.lanr,
      this.userData.bsnr,
    ]);
  }

  savePassword() {
    this.changeForm.get('old_password').markAsDirty();
    this.changeForm.get('new_password').markAsDirty();
    this.changeForm.get('new_password_2').markAsDirty();
    if (this.changeForm.valid && !this.transmitting) {
      this.transmitting = true;
      this.bmClient.updatePassword(this.changeForm.value).subscribe((value: PwdChangeResponse) => {
        switch (value) {
          case PwdChangeResponse.success:
            this.router.navigate([MODULE_ROOT]);
            this.toastService.show('Passwort geändert', '', 'success');
            break;
          case PwdChangeResponse.errWrongCredentials:
            this.toastService.show('Ein Fehler ist aufgetreten', 'Das übergebene alte Passwort ist falsch', 'danger');
            break;
          case PwdChangeResponse.errPasswordTooWeak:
            this.toastService.show('Ein Fehler ist aufgetreten', 'Das übergebene neue Passwort ist zu schwach', 'danger');
            break;
          case PwdChangeResponse.errUnknown:
            this.toastService.show('Ein Fehler ist aufgetreten', 'Ein unbekannte Fehler ist aufgetreten', 'danger');
            break;
        }
        this.transmitting = false;
      });
    }
  }
}
