import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'asFunction'
})
export class AsFunctionPipe implements PipeTransform {

  transform(value) {
    if (value && value.call) {
      return value;
    }
    return (a) => {
      return undefined;
    };
  }

}

@Pipe({
  name: 'cast',
  pure: true
})
export class CastPipe implements PipeTransform {

  transform<T>(value: any, _: (new (...args: any[]) => T) | T): T {
    return value as T;
  }

}

@Pipe({
  name: 'tneStatus',
})
export class TneStatusPipe implements PipeTransform {

  transform(value: { einschreibung: any, files: Array<any>, teilnahmeerklaerung_status?: string }) {
    if (value.einschreibung.controls.digiTneAktiv.value) {
      // DigiTNE aktiv
      return 'Verfügbar';
    } else if (value.einschreibung.controls.digiTNEAktivieren.value) {
      // DigiTNE wird beim speichern aktiviert
      return 'Wird beim Speichern erstellt';
    } else {
      // DigiTNE nicht aktiv
      if (value?.files?.length) {
        return 'Wird beim Speichern hochgeladen';
      }
      if (value?.teilnahmeerklaerung_status) {
        // x: nicht hochgeladen, p: in Prüfung, g: Gültig, u: Ungültig
        const mapping = {
          x: 'nicht hochgeladen',
          p: 'in Prüfung',
          g: 'Gültig',
          u: 'Ungültig',
        };
        return mapping[value.teilnahmeerklaerung_status];
      }
      return 'nicht hochgeladen';
    }
  }

}
