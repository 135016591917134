<div class="container h-100">
  <!-- mobile view -->
  <div class="row row-cols-1" *ngIf="!error && !loading">
    <div class="col">
      <div class="row">
        <p>
          Meine Teilnahme am {{state?.vertrag?.name}}
        </p>
      </div>
    </div>
  </div>
  <div class="row row-cols-1 d-md-none">
    <div class="col" *ngIf="!error && !loading">
      <div class="row">
        <label>
          Untenstehend sehen Sie die Daten Ihres behandelnden Arztes
        </label>
      </div>
      <div class="form-group">
        <label class="small">LANR / BSNR</label>
        <div>{{state?.arzt?.lanr}} / {{state?.arzt?.bsnr}}</div>
      </div>
      <div class="form-group">
        <label class="small">Behandelnder Arzt</label>
        <div class="font-weight-bold">{{state?.arzt?.anrede_titel_nachname}}</div>
      </div>
      <div class="form-group">
        <label class="small">Anschrift</label>
        <div class="font-weight-bold">{{state?.arzt?.adresse}}</div>
      </div>
      <div class="form-group" *ngIf="state?.arzt?.telefonnummer">
        <label class="small">Telefon</label>
        <div class="font-weight-bold">{{state?.arzt?.telefonnummer}}</div>
      </div>
    </div>
    <div class="col" *ngIf="error && !loading">
      Die Arztdaten können nicht geladen werden.
      Bitte versuchen Sie es später erneut oder nehmen Sie Kontakt mit dem Service auf.
    </div>
  </div>
  <!-- desktop view -->
  <div class="row row-cols-1 d-none d-md-block">
    <div class="col" *ngIf="!error && !loading">
      <table class="table table-borderless data-table table-sm">
        <tr>
          <td>Untenstehend sehen Sie die Daten Ihres behandelnden Arztes</td>
        </tr>
        <tr>
          <td colspan="2">
            <hr class="my-0">
          </td>
        </tr>
        <tr>
          <td>LANR / BSNR</td>
          <td>{{state?.arzt?.lanr}} / {{state?.arzt?.bsnr}}</td>
        </tr>
        <tr>
          <td>Behandelnder Arzt</td>
          <td class="font-weight-bold">{{state?.arzt?.anrede_titel_nachname}}</td>
        </tr>
        <tr>
          <td>Anschrift</td>
          <td class="font-weight-bold">{{state?.arzt?.adresse}}</td>
        </tr>
        <tr *ngIf="state?.arzt?.telefonnummer">
          <td>Telefon</td>
          <td class="font-weight-bold">{{state?.arzt?.telefonnummer}}</td>
        </tr>
        <tr>
          <td colspan="2">
            <hr class="my-0">
          </td>
        </tr>
      </table>
    </div>
    <div class="col" *ngIf="error && !loading">
      Die Arztdaten können nicht geladen werden.
      Bitte versuchen Sie es später erneut oder nehmen Sie Kontakt mit dem Service auf.
    </div>
  </div>
  <!-- both -->
  <div class="text-center" *ngIf="loading">
    <fa-icon [icon]="spinnerIcon" animation="spin-pulse"></fa-icon>
  </div>
  <div class="text-center" *ngIf="!error && !loading">
    <button #btnWeiter class="btn btn-primary" [disabled]="sending"
            (click)="onWeiterClicked()">
      Bestätigen<span *ngIf="sending">&nbsp;<fa-icon [icon]="spinnerIcon" animation="spin-pulse"></fa-icon></span>
    </button>
  </div>
</div>
