<router-outlet></router-outlet>
<san-cookie-warnung></san-cookie-warnung>
<div class="orientation-warning blur-container">
<!--  <fa-icon [icon]="rotateIcon" size="5x"></fa-icon>-->
  <svg-icon name="rotateSmartphone"></svg-icon>
  <p class="mt-3">
    Das Sanakey-Portal sieht aufrecht besser aus.
  </p>
  <p>
    Bitte halten Sie Ihr Gerät aufrecht.
  </p>
</div>
