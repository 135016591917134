<div class="container h-100">
  <div class="row h-100">
    <div class="col-12 h-100">
      <div class="card w-100 h-100">
        <div class="card-body p-0 h-100">
          <div class="shrink-top-animated h-100" [class.out]="loading">
            <div class="text-center my-5">
              <fa-icon class="" [icon]="spinnerIcon" animation="spin-pulse" size="3x"></fa-icon>
              <div class="">Die Ansicht wird geladen ...</div>
            </div>
          </div>
          <div class="shrink-top-animated p-0 p-md-3 h-100" [class.out]="!loading">
            <san-info *ngIf="!token"></san-info>
            <ng-container *ngIf="token">
              <san-stepper #stepperComponent [showSteps]="false"
                           [hideBottomButtons]="true" class="mh-100">
                <san-step #stepPin>
                  <san-pin [token]="token" (anmeldenClicked)="sendCredentials($event)"
                           [wrongCredentials]="wrongCredentials"
                           [sending]="sending"
                  ></san-pin>
                </san-step>
                <san-step #stepZustimmung>
                  <san-zustimmung [state]="state" [token]="token" [pin]="pin" [sending]="sending"
                                  (bestaetigenClicked)="einschreiben()"></san-zustimmung>
                </san-step>
                <san-step #stepZustimmungBestaetigung>
                  <san-zustimmung-bestaetigung [token]="token" [pin]="pin" [state]="state"
                                               [widerruf]="showLinkToWiderruf"
                                               [teilnahmeerklaerung]="state?.teilnahmeerklaerung_abrufbar"
                                               (abmeldenClicked)="abmelden()"
                                               (widerrufClicked)="widerruf()"
                                               (downloadTneClicked)="downloadTNE()"></san-zustimmung-bestaetigung>
                </san-step>
                <san-step #stepWiderruf>
                  <san-widerruf [credentials]="{link_token: token, pin}" [state]="state"
                                (abmeldenClicked)="abmelden()"
                                [teilnahmeerklaerung]="state?.teilnahmeerklaerung_abrufbar"
                                (downloadTneClicked)="downloadTNE()"
                                (bestaetigenClicked)="widerrufBestaetigen()"></san-widerruf>
                </san-step>
                <san-step #stepWiderrufBestaetigung>
                  <san-widerruf-bestaetigung [teilnahmeerklaerung]="state?.teilnahmeerklaerung_abrufbar"
                                             [state]="state"
                                             (abmeldenClicked)="abmelden()"
                                             (downloadTneClicked)="downloadTNE()"></san-widerruf-bestaetigung>
                </san-step>
                <san-step #stepUngueltigeEinschreibung>
                  <div class="text-center my-5">
                    <fa-icon class="text-danger" [icon]="iconExclamation" size="3x"></fa-icon>
                    <div class="">Ihre Zugangsdaten sind korrekt. Jedoch wurde die Einschreibung deaktiviert.</div>
                  </div>
                </san-step>
              </san-stepper>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
