import {Component, OnInit} from '@angular/core';
import {faAngleDown, faAngleUp, faUser} from '@fortawesome/pro-regular-svg-icons';
import {BenutzermanagementAPIService} from "../../api/benutzermanagement/benutzermanagement-api.service";
import {AppToastService} from "../../toast/app-toast.service";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {Router} from "@angular/router";

@Component({
  selector: 'san-account-button',
  templateUrl: './account-button.component.html',
  styleUrls: ['./account-button.component.scss']
})
export class AccountButtonComponent implements OnInit {
  userIcon = faUser;
  pfeilIcon = faAngleDown;
  pfeilHochIcon = faAngleUp;
  out = false;
  userdata = {
    vorname: 'wird geladen ...',
    nachname: 'wird geladen ...',
    kundennummer: 'wird geladen ...',
    email: 'wird geladen ...',
  };
  loggingOut = false;
  loadingIcon = faSpinner;

  constructor(private bmClient: BenutzermanagementAPIService, private toastService: AppToastService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.bmClient.getUserdata(1, 100, null, null, [
      'Sanakey/Kundennummer',
      'Sanakey/Vorname',
      'Sanakey/Nachname',
      'Sanakey/Standard E-Mail',
    ])
      .subscribe(ediPage => {
        for (let edi of ediPage.results) {
          const key = `${edi.extradata.namespace}/${edi.extradata.name}`;
          switch (key) {
            case 'Sanakey/Kundennummer':
              this.userdata.kundennummer = edi.value;
              break;
            case 'Sanakey/Vorname':
              this.userdata.vorname = edi.value;
              break;
            case 'Sanakey/Nachname':
              this.userdata.nachname = edi.value;
              break;
            case 'Sanakey/Standard E-Mail':
              this.userdata.email = edi.value;
              break;
          }
        }
      })
  }

  logout() {
    this.loggingOut = true;
    this.bmClient.logOut().subscribe(value => {
      this.loggingOut = false;
      this.router.navigate(['/']);
      this.toastService.show('Erfolgreich abgemeldet', 'Sie wurden abgemeldet', "success");
    }, error => {
      this.loggingOut = false;
      this.toastService.show('Nicht abgemeldet',
        'Sie konnten nicht abgemeldet werden. Bitte versuchen Sie es später erneut',
        "danger");
    });
  }
}
