import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {faLock, faMobileAlt, faSpinner, faUser, faUserPlus, faCheckCircle, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {
  DeviceRegistrationRequiredResponse,
  AuthResponse,
  ResetPasswordResponse,
  CredentialsCorrectResponse, TfaMethod, AuthDeviceSetupResponse201
} from '../../api/benutzermanagement/models';
import {HttpErrorResponse} from '@angular/common/http';
import {AppToastService} from '../../toast/app-toast.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ShowCustomLogoAction } from 'src/app/reducers/header.reducer';

/*
           Show Methods
             Step 1b    Send OTP
  Step 1 ---->---->+----> Step 2 --> Logged In
  Send User-       |        ^
  name &           |        |
  Password         +--> Step 1c
                        Show OTP
                        Setup
 */

enum Steps {
  step1, step1b, step1c, step2, Done,
  step2b,
}

enum PasswordResetSteps {
  off, verifyHuman, loading, error, success
}

enum Responses {
  noError,
  emailAmbigious, wrongCredentials, accountLocked, accountNotVerified,
  alreadyRegistered,
  unavailableTFMethod,
}

@Component({
  selector: 'san-login-box',
  templateUrl: './login-box.component.html',
  styleUrls: ['./login-box.component.css']
})
export class LoginBoxComponent implements OnInit {
  @Input() allowClose = false;

  userIcon = faUser;
  passwordIcon = faLock;
  newUserIcon = faUserPlus;
  phoneIcon = faMobileAlt;
  loadingIcon = faSpinner;
  checkIcon = faCheckCircle;

  otpSetupShowCode = false;
  otpValidators = [Validators.required, Validators.pattern(/^[0-9]{6}$/)];
  otpSetupQRCodeURI: string = null;
  otpSetupCode: string = null;
  tanPlaceholder = 'Geräte Code (aus Authenticator)';

  otpMethods: TfaMethod[];
  chosenOtpMethod: TfaMethod;

  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    otp: new FormControl('', []),
  });

  // AppState Management
  Steps = Steps;

  step = Steps.step1;
  Responses = Responses;
  response = Responses.noError;

  loading = false;
  error = '';
  showTitle: string = null;
  activeModal = null;

  PasswordResetSteps = PasswordResetSteps;
  forgotPasswordMode: PasswordResetSteps = PasswordResetSteps.off;
  recaptchaSiteKey = environment.recaptchaSiteKey;

  @ViewChild('recaptchaComponent') recaptchaComponent;
  @ViewChild('usernameInput') usernameInput;
  @ViewChild('passwordInput') passwordInput;
  @ViewChild('otpInput') otpInput;

  constructor(private benutzermanagementApi: BenutzermanagementAPIService, public toastService: AppToastService,
              private router: Router, private injector: Injector
              // private recaptchaV3Service: ReCaptchaV3Service,
  ) {
  }

  ngOnInit(): void {
    try {
      this.activeModal = this.injector.get(NgbActiveModal);
    } catch (e) {
      this.activeModal = null;
    }
  }

  sendData() {
    if (this.loading) {
      return;
    }

    switch (this.step) {
      case Steps.step1:
        // send username & password to device check
        this.loginForm.controls.username.markAsDirty();
        this.loginForm.controls.password.markAsDirty();
        if (!this.loginForm.valid) {
          return;
        }
        this.response = Responses.noError;
        this.loginForm.controls.username.disable();
        this.loginForm.controls.password.disable();
        this.loading = true;

        const step1Observer = {
          next: (x: DeviceRegistrationRequiredResponse | CredentialsCorrectResponse | any) => {
            if (x instanceof DeviceRegistrationRequiredResponse) {
              this.step = this.Steps.step1b;
              this.otpMethods = x.options;
            } else if (x instanceof CredentialsCorrectResponse) {
              this.step = this.Steps.step2;
              this.otpMethods = x.options;
            }
          },
          error: (err: HttpErrorResponse) => {
            this.loginForm.controls.username.enable();
            this.loginForm.controls.password.enable();

            switch (err.status) {
              case 402:
                // 402 Email address ambiguous
                this.loginForm.controls.username.setValidators([Validators.required, Validators.pattern(/^[0-9]{8}$/)]);
                this.loginForm.controls.username.updateValueAndValidity();
                break;
              case 403:
                // 403 Wrong credentials
                this.response = Responses.wrongCredentials;
                break;
              case 406:
                // 406 Too many false logins - account temporary locked
                this.response = Responses.accountLocked;
                break;
              case 423:
                // 423 Account not yet verified
                this.response = Responses.accountNotVerified;
                break;
            }
            this.loading = false;
          },
          complete: () => this.loading = false,
        };

        this.benutzermanagementApi.authDevice({
          username: this.loginForm.controls.username.value,
          password: this.loginForm.controls.password.value
        }).subscribe(step1Observer);
        break;
      case Steps.step1b:
        const step1bObserver = {
          next: (x: AuthDeviceSetupResponse201) => {
            this.otpSetupQRCodeURI = x.qrcode;
            this.otpSetupCode = x.alt_code;
            this.step = this.Steps.step1c;
            this.loginForm.controls.otp.setValidators(this.otpValidators);
            this.otpInput.nativeElement.focus();
          },
          error: (err: HttpErrorResponse) => {
            this.loginForm.controls.username.enable();
            this.loginForm.controls.password.enable();

            switch (err.status) {
              case 402:
                // 402 Email address ambiguous
                this.loginForm.controls.username.setValidators([Validators.required, Validators.pattern(/^[0-9]{8}$/)]);
                this.loginForm.controls.username.updateValueAndValidity();
                this.response = Responses.emailAmbigious;
                break;
              case 403:
                // 403 Wrong credentials
                this.response = Responses.wrongCredentials;
                break;
              case 406:
                // 406 Too many false logins - account temporary locked
                this.response = Responses.accountLocked;
                break;
              case 407:
                // 406 Too many false logins - account temporary locked
                this.response = Responses.alreadyRegistered;
                break;
              case 408:
                // 406 Too many false logins - account temporary locked
                this.response = Responses.unavailableTFMethod;
                break;
              case 423:
                // 423 Account not yet verified
                this.response = Responses.accountNotVerified;
                break;
            }
            this.loading = false;
          },
          complete: () => this.loading = false,
        };

        this.loading = true;
        this.benutzermanagementApi.setupDevice({
          username: this.loginForm.controls.username.value,
          password: this.loginForm.controls.password.value,
          method: this.chosenOtpMethod,
        }).subscribe(step1bObserver);
        break;
      case Steps.step1c:
        // send username & password & otp to device activation
        this.loginForm.controls.username.markAsDirty();
        this.loginForm.controls.password.markAsDirty();
        this.loginForm.controls.otp.markAsDirty();
        if (!this.loginForm.valid) {
          return;
        }
        this.response = Responses.noError;
        this.loginForm.controls.username.disable();
        this.loginForm.controls.password.disable();
        this.loginForm.controls.otp.disable();
        this.loading = true;

        const step1cObserver = {
          next: (x: AuthResponse | any) => {
            this.step = Steps.Done;
            this.toastService.show('Gerät aktiviert', 'Ihr Gerät wurde erfolgreich aktiviert', 'success');
            this.navigateAfterSuccessfulLogin();
          },
          error: (err: HttpErrorResponse) => {
            this.loginForm.controls.otp.enable();

            switch (err.status) {
              case 400:
              case 403:
                // 403 Wrong credentials
                this.response = Responses.wrongCredentials;
                break;
              case 406:
                // 406 Too many false logins - account temporary locked
                this.response = Responses.accountLocked;
                break;
            }
            this.loading = false;
          },
          complete: () => this.loading = false,
        };

        this.benutzermanagementApi.activateDevice({
          username: this.loginForm.controls.username.value,
          password: this.loginForm.controls.password.value,
          otp_token: this.loginForm.controls.otp.value,
          method: this.chosenOtpMethod,
        }).subscribe(step1cObserver);
        break;
      case Steps.step2:
        const step2Observer = {
          next: (x: AuthDeviceSetupResponse201) => {
            this.step = Steps.step2b;
          },
          error: (err: HttpErrorResponse) => {
            this.loginForm.controls.username.enable();
            this.loginForm.controls.password.enable();

            switch (err.status) {
              case 402:
                // 402 Email address ambiguous
                this.loginForm.controls.username.setValidators([Validators.required, Validators.pattern(/^[0-9]{8}$/)]);
                this.loginForm.controls.username.updateValueAndValidity();
                this.response = Responses.emailAmbigious;
                break;
              case 403:
                // 403 Wrong credentials
                this.response = Responses.wrongCredentials;
                break;
              case 406:
                // 406 Too many false logins - account temporary locked
                this.response = Responses.accountLocked;
                break;
              case 407:
                // 406 Too many false logins - account temporary locked
                this.response = Responses.alreadyRegistered;
                break;
              case 408:
                // 406 Too many false logins - account temporary locked
                this.response = Responses.unavailableTFMethod;
                break;
              case 423:
                // 423 Account not yet verified
                this.response = Responses.accountNotVerified;
                break;
            }
            this.loading = false;
          },
          complete: () => this.loading = false,
        };
        switch (this.chosenOtpMethod) {
          case 'EMail':
            this.loading = true;
            this.loginForm.controls.otp.setValidators(this.otpValidators);
            this.benutzermanagementApi.setupDevice({
              username: this.loginForm.controls.username.value,
              password: this.loginForm.controls.password.value,
              method: this.chosenOtpMethod,
            }).subscribe(step2Observer);
            break;
          case 'TOTP':
            this.step = Steps.step2b;
            this.loginForm.controls.otp.setValidators(this.otpValidators);
            break;
          default:
            alert('Ungültige 2FA Methode');
        }
        break;
      case Steps.step2b:
        // send username & password & otp to auth
        this.loginForm.controls.username.markAsDirty();
        this.loginForm.controls.password.markAsDirty();
        this.loginForm.controls.otp.markAsDirty();
        if (!this.loginForm.valid) {
          return;
        }
        this.response = Responses.noError;
        this.loginForm.controls.username.disable();
        this.loginForm.controls.password.disable();
        this.loginForm.controls.otp.disable();
        this.loading = true;

        const step2bObserver = {
          next: (x: AuthResponse | any) => {
            this.step = Steps.Done;
            this.navigateAfterSuccessfulLogin();
          },
          error: (err: HttpErrorResponse) => {
            this.loginForm.controls.otp.enable();

            switch (err.status) {
              case 403:
                // 403 Wrong credentials
                this.response = Responses.wrongCredentials;
                break;
              case 406:
                // 406 Too many false logins - account temporary locked
                this.response = Responses.accountLocked;
                break;
            }
            this.loading = false;
          },
          complete: () => this.loading = false,
        };

        this.benutzermanagementApi.auth({
          username: this.loginForm.controls.username.value,
          password: this.loginForm.controls.password.value,
          otp_token: this.loginForm.controls.otp.value,
          method: this.chosenOtpMethod,
        }).subscribe(step2bObserver);
        break;
    }
  }

  forgotPasswordClicked(gRecaptchaResponse = null) {
    this.loginForm.controls.username.markAsDirty();
    if (!this.loginForm.controls.username.disabled) {
      const validUsernameObserver = {
        next: () => {
          this.loginForm.controls.username.disable();
          this.PWForgotNextStep(gRecaptchaResponse);
          return;
        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 402) {
              // 402 Email address ambiguous
              this.loginForm.controls.username.setValidators([Validators.required, Validators.pattern(/^[0-9]{8}$/)]);
              this.loginForm.controls.username.updateValueAndValidity();
          }
          return;
        },
        complete: () => { 
        },
      };
      this.benutzermanagementApi.validateUsername({
        username: this.loginForm.controls.username.value
      }).subscribe(validUsernameObserver);
    }

    if (this.loginForm.controls.username.disabled && !this.loginForm.controls.username.invalid) {
      this.PWForgotNextStep(gRecaptchaResponse);
    }
  }

  PWForgotNextStep(gRecaptchaResponse) {
    this.showTitle = 'Passwort zurücksetzen'

    if (this.forgotPasswordMode === PasswordResetSteps.off) {
      this.forgotPasswordMode = PasswordResetSteps.verifyHuman;
      return;
    }

    if (!gRecaptchaResponse) {
      return;
    }
    
    const forgotPasswordObserver = {
      next: (x: ResetPasswordResponse) => {
        this.toastService.show('Bitte prüfen Sie Ihre E-Mails.',
          'Wenn Ihre Daten gültig sind, dann erhalten Sie von uns in Kürze eine E-Mail.',
          'success');
        this.forgotPasswordMode = PasswordResetSteps.success;
      },
      error: (x: ResetPasswordResponse) => {
        this.loginForm.controls.username.enable();
        this.recaptchaComponent.reset();
        this.forgotPasswordMode = PasswordResetSteps.error;
        switch (x) {
          case ResetPasswordResponse.OK:
            break;
          case ResetPasswordResponse.DataInvalid:
            this.toastService.show('Daten ungültig.',
              'Bitte überprüfen Sie Ihre Eingaben.',
              'warning');
            break;
          case ResetPasswordResponse.EmailAmbigious:
            this.toastService.show('Kundennummer benutzen.',
              'Bitte geben Sie Ihre Kundennummer anstelle Ihrer E-Mail Adresse an.',
              'warning');
            break;
          case ResetPasswordResponse.ServiceUnavailable:
            this.toastService.show('Ein Fehler ist aufgetreten.',
              'Bitte versuchen Sie es später noch ein Mal. (E-Mail konnte nicht verschickt werden)',
              'danger');
            break;
          default:
            this.toastService.show('Ein unbekannter Fehler ist aufgetreten.',
              'Bitte versuchen Sie es später noch ein Mal. (Unbekannter Fehler)',
              'danger');
            break;
        }
      },
    };

    this.forgotPasswordMode = PasswordResetSteps.loading;
    this.benutzermanagementApi.resetPassword({
      username: this.loginForm.controls.username.value,
      gRecaptchaResponse,
    }).subscribe(forgotPasswordObserver);

    // RecaptchaV3
    // this.recaptchaV3Service.execute('importantAction')
    //   .subscribe((token) => {
    //     this.benutzermanagementApi.resetPassword({
    //       username: this.loginForm.controls.username.value,
    //       gRecaptchaResponse: token,
    //     }).subscribe(forgotPasswordObserver);
    //   });

  }

  private navigateAfterSuccessfulLogin() {
    const nextAfterLogin = this.benutzermanagementApi.getNextAfterLogin();
    if (nextAfterLogin) {
      this.benutzermanagementApi.clearNextAfterLogin();
      this.router.navigate([nextAfterLogin]);
    } else {
      this.router.navigate(['dashboard']);
    }
    this.closeModal();
  }

  public fillUsername(username: string) {
    if (username) {
      this.loginForm.controls.username.setValue(username);
      this.loginForm.controls.username.updateValueAndValidity();
      setTimeout(() => {
        this.passwordInput.nativeElement.focus();
      }, 500);
    }
  }

  private closeModal() {
    try {
      this.activeModal.close();
    } finally {
      // noop
    }
  }

  setUsernameFocus() {
    setTimeout(() => {
      this.usernameInput.nativeElement.focus();
    }, 200);
  }

  setOtpMethod(otpMethod: TfaMethod) {
    this.chosenOtpMethod = otpMethod;
    switch (this.chosenOtpMethod) {
      case 'EMail':
        this.tanPlaceholder = 'TAN (aus E-Mail)';
        this.phoneIcon = faEnvelope;
        break;
      case 'TOTP':
        this.tanPlaceholder = 'TAN (aus Authenticator)';
        this.phoneIcon = faMobileAlt;
        break;
      default:
        alert('Ungültige OTP Methode');
    }
    this.sendData();
  }
}
