import {Component, OnInit, Sanitizer, SecurityContext} from '@angular/core';
import {AppToastService} from '../toast/app-toast.service';
// import {MatomoInjector} from "ngx-matomo";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieWarnungContentComponent} from './cookie-warnung-content/cookie-warnung-content.component';
import {Angulartics2Matomo} from 'angulartics2';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../environments/environment';

@Component({
  selector: 'san-cookie-warnung',
  templateUrl: './cookie-warnung.component.html',
  styleUrls: ['./cookie-warnung.component.scss']
})
export class CookieWarnungComponent implements OnInit {
  cookies = {
    required: false,
    tracking: false,
    auswahl_merken: false,
  };

  constructor(private toastService: AppToastService,
              // private matomoInjector: MatomoInjector,
              private angulartics2Matomo: Angulartics2Matomo,
              private modalService: NgbModal,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    if (window.localStorage) {
      const settings = window.localStorage.getItem('_SAN_COOKIE_SETTINGS');
      if (settings) {
        this.cookies = JSON.parse(settings);
        this.handleCookies();
        return;
      }
    }
    this.modalService.open(CookieWarnungContentComponent, {
      centered: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    })
      .result
      .then((value: { tracking: boolean, required: boolean, auswahl_merken: boolean }) => {
        this.cookies = value;
        if (value.auswahl_merken) {
          if (!window.localStorage) {
            this.toastService.show('Auswahl nicht gemerkt', 'Ihre Cookie Auswahl kann nicht gemerkt werden',
              'info');
          } else {
            window.localStorage.setItem('_SAN_COOKIE_SETTINGS', JSON.stringify(value));
          }
        }
        this.handleCookies();
      });
  }

  private handleCookies() {
    if (environment.MATOMO_SITE_ID > 0 && this.cookies.tracking) {
      // this.matomoInjector.init('https://matomo.sanakey-portal.de/', environment.MATOMO_SITE_ID);
      this.initMatomo();
      this.angulartics2Matomo.startTracking();
    }
  }

  initMatomo() {
    // @ts-ignore
    // tslint:disable-next-line:variable-name
    const _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    // _paq.push(['trackPageView']);
    // _paq.push(['enableLinkTracking']);
    // tslint:disable-next-line:only-arrow-functions
    (function() {
      const u = 'https://matomo.sanakey-portal.de/';
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', `${environment.MATOMO_SITE_ID}`]);
      // tslint:disable-next-line:one-variable-per-declaration
      const d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.src = u + 'matomo.js';
      s.parentNode.insertBefore(g, s);
    })();
    console.log('Matomo wird aktiviert');
  }
}
