import {Component, Injectable, OnDestroy, OnInit} from '@angular/core';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbInputDatepickerConfig
} from '@ng-bootstrap/ng-bootstrap';
import {AppToastService} from './toast/app-toast.service';
import {Actions, ofType} from '@ngrx/effects';
import {AuthinfoActionTypes} from './reducers/authinfo.reducer';
import {Subscription} from 'rxjs';
import {UserPopupsService} from './user-popups/user-popups.service';
import {faSyncAlt} from '@fortawesome/pro-solid-svg-icons';

function padZero(n: number): string {
  if (n < 10) {
    return '0' + n;
  }
  return '' + n;
}

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[0], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year + this.DELIMITER + padZero(date.month) + this.DELIMITER + padZero(date.day) : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '.';
  readonly regexpDashY4MD = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;
  readonly regexpDashYMD2 = /^[0-9]{2}-[0-9]{1,2}-[0-9]{1,2}$/;
  readonly regexpDashDMY4 = /^[0-9]{2}-[0-9]{1,2}-[0-9]{4}$/;
  readonly regexpDotDMY2 = /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{2}$/;
  readonly regexpDotDMY4 = /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/;
  readonly regexpSlashDMY2 = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{2}$/;
  readonly regexpSlashDMY4 = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
  readonly regexpBackslashDMY2 = /^[0-9]{1,2}\\[0-9]{1,2}\\[0-9]{2}$/;
  readonly regexpBackslashDMY4 = /^[0-9]{1,2}\\[0-9]{1,2}\\[0-9]{4}$/;
  readonly regexpDates = [
    this.regexpDashY4MD,
    this.regexpDashYMD2,
    this.regexpDashDMY4,
    this.regexpDotDMY2,
    this.regexpDotDMY4,
    this.regexpSlashDMY2,
    this.regexpSlashDMY4,
    this.regexpBackslashDMY2,
    this.regexpBackslashDMY4,
  ];

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const indexMatchingRegex = this.regexpDates.map(r => r.test(value)).indexOf(true);
      let date = null;
      switch (indexMatchingRegex) {
        case 0:
          date = value.split('-');
          return {
            day: parseInt(date[2], 10),
            month: parseInt(date[1], 10),
            year: parseInt(date[0], 10)
          };
        case 1:
          date = value.split('-');
          return {
            day: parseInt('20' + date[2], 10),
            month: parseInt(date[1], 10),
            year: parseInt(date[0], 10)
          };
        case 2:
          date = value.split('-');
          return {
            day: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            year: parseInt(date[2], 10)
          };
        case 3:
          date = value.split('.');
          return {
            day: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            year: parseInt('20' + date[2], 10)
          };
        case 4:
          date = value.split('.');
          return {
            day: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            year: parseInt(date[2], 10)
          };
        case 5:
          date = value.split('/');
          return {
            day: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            year: parseInt('20' + date[2], 10)
          };
        case 6:
          date = value.split('/');
          return {
            day: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            year: parseInt(date[2], 10)
          };
        case 7:
          date = value.split('\\');
          return {
            day: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            year: parseInt('20' + date[2], 10)
          };
        case 8:
          date = value.split('\\');
          return {
            day: parseInt(date[0], 10),
            month: parseInt(date[1], 10),
            year: parseInt(date[2], 10)
          };
        default:
          return null;
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? padZero(date.day) + this.DELIMITER + padZero(date.month) + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'san-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'frontend-v3';
  private actionsSup$: Subscription;
  rotateIcon = faSyncAlt;

  constructor(private toastService: AppToastService, private actions: Actions,
              config: NgbInputDatepickerConfig, calendar: NgbCalendar,
              ups: UserPopupsService) {
    this.actionsSup$ = this.actions
      .pipe(ofType(AuthinfoActionTypes.AuthDataFromStorageLoaded))
      .subscribe(n => {
        this.toastService.show(
          'Sie wurden angemeldet',
          'In Ihrem Browser waren noch gültige Anmeldeinformationen hinterlegt. Sie wurden automatisch wieder angemeldet.',
          'success');
      });

    /* DATEPICKER GLOBAL CONFIG */

    // customize default values of datepickers used by this component tree
    config.minDate = {year: 1900, month: 1, day: 1};
    config.maxDate = {year: 2099, month: 12, day: 31};

    // days that don't belong to current month are not visible
    config.outsideDays = 'hidden';

    // setting datepicker popup to close only on click outside
    config.autoClose = 'outside';

    /* DATEPICKER GLOBAL CONFIG END */

  }

  ngOnDestroy(): void {
    this.actionsSup$.unsubscribe();
  }

  ngOnInit() {
  }
}
