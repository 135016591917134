<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Sie deaktivieren und löschen Ihr 2FA-Gerät</h4>
</div>
<div class="modal-body">
  <p>
    Zur Anmeldung auf der Sanakey-Plattform ist die Anmeldung mit Benutzername, Passwort und einem zweiten Faktor
    notwendig. Der zweite Faktor kann u.A. eine E-Mail TAN sein oder eine Authenticator TAN. Alle Möglichkeiten eine
    TAN zu erstellen außer per E-Mail werden als Geräte genannt. Sie entscheiden sich nun eine der Möglichkeiten zu
    löschen.
  </p>
  <p>
    Sie löschen das Gerät mit der Bezeichnung "{{device.name}}".
  </p>
  <small>
    (Die Bezeichnung "default" steht für das nach der Registrierung erste eingegebene Gerät ohne extra Bezeichnung)
  </small>
</div>
<div class="modal-footer flex-row justify-content-between">
  <button type="button" class="btn btn-outline" (click)="activeModal.close({})">Abbrechen</button>
  <!--  <button type="button" class="btn btn-primary" (click)="alleAkzeptieren(false)">Alle akzeptieren</button>-->
  <button type="button" class="btn btn-danger" (click)="confirmDelete()">Gerät löschen</button>
</div>
