<ng-template let-control="control" #requiredStar>
  <span *ngIf="(control?.validator | asFunction)({})?.required">*</span>
</ng-template>

<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
</ng-template>

<form [formGroup]="stammdatenForm" #theForm>
  <div class="w-100 text-center my-5" *ngIf="categoriesState !== LoadingState.Loaded">
    <fa-icon [icon]="loadingIcon" size="5x" animation="spin-pulse"></fa-icon>
    <br/>
    Die Ansicht wird geladen ...
  </div>
  <div class="card w-100" *ngIf="visibleCategories['Basisdaten']">
    <div class="card-body">
      <h6 class="card-title text-uppercase">Basisdaten</h6>
      <div class="row">
        <div [@vis] class="col-12" *ngIf="!($any(extradata)|keyvalue).length"><p>wird geladen ...</p></div>
        <div [@vis] class="col-12" *ngIf="($any(extradata)|keyvalue).length">
          <ng-container *ngIf="'Sanakey/Kundennummer'; let key;">
            <div class="row" *ngIf="visibleKeys[key]">
              <div class="form-group col-6 col-lg-4 col-xl-2 ">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>
                <input class="form-control text-right" [formControlName]="key">
              </div>
            </div>
          </ng-container>
          <div class="row">
            <ng-container *ngIf="'Sanakey/Anrede'; let key">
              <div class="col-6 col-lg-2 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>
                <div class="position-relative">
                  <select class="form-select" [formControlName]="key">
                    <option value=""></option>
                    <option value="Frau">Frau</option>
                    <option value="Herr">Herr</option>
                    <option value="Divers">Divers</option>
                  </select>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
                    <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
                  </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Titel'; let key">
              <div class="col-6 col-lg-2 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control"
                    [formControlName]="key"
                    [ngbTypeahead]="titleAuswahlConfig.titleAuswahl"
                    container="form"
                  />
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Vorname'; let key">
              <div class="col-6 col-lg-4 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>
                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
                    <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
                  </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Nachname'; let key">
              <div class="col-6 col-lg-4 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/LANR'; let key">
              <div class="col-6 col-lg-4 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/BSNR'; let key">
              <div class="col-6 col-lg-4 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Institutionskennzeichen'; let key">
              <div class="col-6 col-lg-4 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Fachgruppe'; let key">
              <div class="col-12 col-lg-6 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Region'; let key">
              <div class="col-12 col-lg-6 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Verbandsmitgliedschaften'; let key">
              <div class="col-12" *ngIf="visibleKeys[key]">
                <div class="row">
                  <label class="col-12 form-group">
                    <span>{{ extradata[key]?.display_field_name }}</span>
                    <ng-container
                      *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                    <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                             [title]="extradata[key]?.display_help"></fa-icon>
                  </label>
                </div>
                <div class="position-relative row">
                  <ng-container
                    *ngFor="let vm of $any(stammdatenForm.controls[key])?.controls; index as i">
                    <div class="form-group col-5 col-sm-6 d-flex align-items-end">
                      <div class="form-group w-100">
                        <label class="">Verbandsname:</label>
                        <div class="position-relative">
                          <input
                            #vn
                            type="text"
                            class="form-control"
                            [formControl]="vm.controls[0]"
                            autocomplete="off"
                            [ngbTypeahead]="$any(vm.controls[0]).auswahl"
                            [resultTemplate]="rt"
                            [inputFormatter]="formatter"
                            (focus)="$any(vm.controls[0]).controlFocused$.next($any($event).target.name)"
                            (selectItem)="addEmptyVerbandsRow(!vn.value)"
                            (change)="addEmptyVerbandsRow(!vn.value)"
                            (keyup)="addEmptyVerbandsRow(!vn.value)"
                            container="form"
                          />
                          <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
                      <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
                    </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-5">
                      <div class="form-group">
                        <label><small>(Optional)</small> Mitgliedsnummer:</label>
                        <div class="position-relative">
                          <input class="form-control" type="text"
                                 [formControl]="vm.controls[1]"
                                 autocomplete="number">
                          <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
                      <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
                    </span>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-2 col-sm-1 d-flex align-items-end justify-content-end">
                      <div class="form-group float-right">
                        <a class="btn btn-outline-warning"
                           [class.disabled]="!vn.value"
                           (click)="deleteVerbandEintrag($event, i)">
                          <fa-icon [icon]="deleteVerbandIcon"></fa-icon>
                        </a>
                      </div>
                    </div>
                    <div class="form-group col-12">
                      <hr>
                    </div>
                  </ng-container>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].dirty && stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <san-zertifizierungen #zertifizierungenComponent
                        *ngIf="visibleCategories['Zertifizierungen und Nachweise']"></san-zertifizierungen>
  <!--suppress JSNonASCIINames -->
  <div class="card w-100 mt-3" *ngIf="visibleCategories['Selbstauskünfte']">
    <div class="card-body">
      <h6 class="card-title text-uppercase">Selbstauskünfte</h6>
      <ng-container *ngIf="'Sanakey/Selbstauskunft/400xInjektionstechniken-24M'; let key">
        <div class="form-group" *ngIf="visibleKeys[key]">
          <div class="row">
            <div class="col-12 col-lg-5">
              <label>
                <span>{{ extradata[key]?.display_field_name }}</span>
                <ng-container
                  *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                         [title]="extradata[key]?.display_help"></fa-icon>
              </label>
              <div class="position-relative">
                <textarea disabled class="form-control">
Innerhalb der letzten 24 Monate habe ich 400 Eingriffe bzgl. der Injektionstechniken (Periradikuläre Therapie (PRT)/Facettensyndrom) durchgeführt.
                </textarea>
              </div>
            </div>
            <div class="col-12 col-lg-5" *ngIf="visibleKeys[key + '/Kommentar']">
              <label *ngIf="visibleKeys[key + '/Kommentar']">
                <span>{{ extradata[key + '/Kommentar']?.display_field_name }}</span>
                <ng-container
                  *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key + '/Kommentar']}"></ng-container>
                <fa-icon *ngIf="extradata[key + '/Kommentar']?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                         [title]="extradata[key + '/Kommentar']?.display_help"></fa-icon>
              </label>
              <div class="position-relative" *ngIf="visibleKeys[key + '/Kommentar']">
                <textarea class="form-control"
                          [placeholder]="(extradata[key + '/Kommentar']?.required) ? '' : 'Optional'"
                          [formControlName]="key + '/Kommentar'"></textarea>
                <span class="loading-icon" [ngClass]="{visible:processingData[key + '/Kommentar']}">
                  <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
                </span>
              </div>
            </div>
            <div class="col-12 col-lg-2">
              <label>
                <span>Bestätigung</span>
                <ng-container
                  *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                         [title]="extradata[key]?.display_help"></fa-icon>
              </label>
              <div class="position-relative">
                <input type="checkbox" class="form-check" [formControlName]="key"/>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="card w-100 mt-3" *ngIf="visibleCategories['Kontaktdaten']">
    <div class="card-body">
      <h6 class="card-title text-uppercase">Kontaktdaten</h6>
      <div class="row">
        <div [@vis] class="col-12" *ngIf="!($any(extradata)|keyvalue).length"><p>wird geladen ...</p></div>
        <div [@vis] class="col-12" *ngIf="($any(extradata)|keyvalue).length">
          <div class="row">
            <ng-container *ngIf="'Sanakey/Standard E-Mail'; let key">
              <div class="col-12 col-lg-6 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Telefonnummer'; let key">
              <div class="col-6 col-lg-6 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Mobilnummer'; let key">
              <div class="col-6 col-lg-6 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Faxnummer'; let key">
              <div class="col-6 col-lg-6 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card w-100 mt-3" *ngIf="visibleCategories['Praxisdaten']">
    <div class="card-body">
      <h6 class="card-title text-uppercase">Praxisdaten</h6>
      <div class="row">
        <div [@vis] class="col-12" *ngIf="!($any(extradata)|keyvalue).length"><p>wird geladen ...</p></div>
        <div [@vis] class="col-12" *ngIf="($any(extradata)|keyvalue).length">
          <div class="row">
            <ng-container *ngIf="'Sanakey/Name der Institution'; let key">
              <div class="col-12 col-lg-8 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Strasse'; let key">
              <div class="col-12 col-md-8 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Hausnummer'; let key">
              <div class="col-12 col-md-4 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="row">
            <ng-container *ngIf="'Sanakey/Postleitzahl'; let key">
              <div class="col-12 col-md-4 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Ort'; let key">
              <div class="col-12 col-md-8 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card w-100 mt-3" *ngIf="visibleCategories['Bankdaten']">
    <div class="card-body">
      <h6 class="card-title text-uppercase">Bankdaten</h6>
      <div class="row">
        <div [@vis] class="col-12" *ngIf="!($any(extradata)|keyvalue).length"><p>wird geladen ...</p></div>
        <div [@vis] class="col-12" *ngIf="($any(extradata)|keyvalue).length">
          <div class="row">
            <ng-container *ngIf="'Sanakey/Bankverbindung/Kontoinhaber'; let key">
              <div class="col-12 col-lg-4 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
                    <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
                  </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Bankverbindung/IBAN'; let key">
              <div class="col-12 col-lg-4 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="'Sanakey/Bankverbindung/BIC'; let key">
              <div class="col-12 col-lg-4 form-group" *ngIf="visibleKeys[key]">
                <label>
                  <span>{{ extradata[key]?.display_field_name }}</span>
                  <ng-container
                    *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                  <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                           [title]="extradata[key]?.display_help"></fa-icon>
                </label>

                <div class="position-relative">
                  <input type="text" class="form-control" [formControlName]="key"/>
                  <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
                </div>
                <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                  <san-form-input-error [message]="k.value"
                                        [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card w-100 mt-3" *ngIf="visibleCategories['Krankenkasse']">
    <div class="card-body">
      <h6 class="card-title text-uppercase">Krankenkasse</h6>
      <div [@vis] class="col-12" *ngIf="!($any(krankenkassendata)|keyvalue).length"><p>wird geladen ...</p></div>
      <div [@vis] class="col-12" *ngIf="($any(krankenkassendata)|keyvalue).length">
        <div class="row">
          <ng-container *ngIf="'Sanakey/Krankenkasse Ik'; let key">
            <div class="col-12 col-lg-4 form-group" *ngIf="visibleKeys[key]">
              <label>
                <span>{{ extradata[key]?.display_field_name }}</span>
                <ng-container
                  *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                         [title]="extradata[key]?.display_help"></fa-icon>
              </label>

              <div class="position-relative">
                <input type="text" class="form-control" [formControlName]="key"/>
                <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
              </div>
              <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                <san-form-input-error [message]="k.value"
                                      [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="'Sanakey/Krankenkasse Name'; let key">
            <div class="col-12 col-lg-4 form-group" *ngIf="visibleKeys[key]">
              <label>
                <span>{{ extradata[key]?.display_field_name }}</span>
                <ng-container
                  *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                         [title]="extradata[key]?.display_help"></fa-icon>
              </label>

              <div class="position-relative">
                <input type="text" class="form-control" [formControlName]="key"/>
                <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
              </div>
              <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                <san-form-input-error [message]="k.value"
                                      [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="'Sanakey/Krankenkasse Logo'; let key">
            <div class="col-12 col-lg-4 form-group" *ngIf="visibleKeys[key] && krankenkassendata[key] !== null">
              <div class="position-relative">
                <img [ngSrc]="krankenkassendata[key]" alt="Kein Logo" width="100" height="100"/>
                <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
                </span>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <ng-container *ngIf="'Sanakey/Krankenkasse Strasse'; let key">
            <div class="col-12 col-md-8 form-group" *ngIf="visibleKeys[key]">
              <label>
                <span>{{ extradata[key]?.display_field_name }}</span>
                <ng-container
                  *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                         [title]="extradata[key]?.display_help"></fa-icon>
              </label>

              <div class="position-relative">
                <input type="text" class="form-control" [formControlName]="key"/>
                <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
              </div>
              <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                <san-form-input-error [message]="k.value"
                                      [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="'Sanakey/Krankenkasse Hausnummer'; let key">
            <div class="col-12 col-md-4 form-group" *ngIf="visibleKeys[key]">
              <label>
                <span>{{ extradata[key]?.display_field_name }}</span>
                <ng-container
                  *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                         [title]="extradata[key]?.display_help"></fa-icon>
              </label>

              <div class="position-relative">
                <input type="text" class="form-control" [formControlName]="key"/>
                <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
              </div>
              <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                <san-form-input-error [message]="k.value"
                                      [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row">
          <ng-container *ngIf="'Sanakey/Krankenkasse Postleitzahl'; let key">
            <div class="col-12 col-md-4 form-group" *ngIf="visibleKeys[key]">
              <label>
                <span>{{ extradata[key]?.display_field_name }}</span>
                <ng-container
                  *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                         [title]="extradata[key]?.display_help"></fa-icon>
              </label>

              <div class="position-relative">
                <input type="text" class="form-control" [formControlName]="key"/>
                <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
              </div>
              <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                <san-form-input-error [message]="k.value"
                                      [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="'Sanakey/Krankenkasse Ort'; let key">
            <div class="col-12 col-md-8 form-group" *ngIf="visibleKeys[key]">
              <label>
                <span>{{ extradata[key]?.display_field_name }}</span>
                <ng-container
                  *ngTemplateOutlet="requiredStar; context:{control: stammdatenForm.controls[key]}"></ng-container>
                <fa-icon *ngIf="extradata[key]?.display_help" [icon]="infoIcon" class="info-icon ms-1"
                         [title]="extradata[key]?.display_help"></fa-icon>
              </label>

              <div class="position-relative">
                <input type="text" class="form-control" [formControlName]="key"/>
                <span class="loading-icon" [ngClass]="{visible:processingData[key]}">
              <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
            </span>
              </div>
              <div *ngFor="let k of $any(validatorMessages[key]) | keyvalue">
                <san-form-input-error [message]="k.value"
                                      [visible]="stammdatenForm.controls[key].hasError($any(k.key))"></san-form-input-error>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="card w-100 mt-3" *ngIf="speichernBtnVisible && categoriesState === LoadingState.Loaded">
    <div class="card-body">
      <h6 class="card-title text-uppercase">Sicherheit</h6>
      <div class="row">
        <div [@vis] class="col-12">
          <div class="row">
            <div class="col-12 form-group">
              <label>
                <span>Passwort</span>
                <fa-icon [icon]="infoIcon" class="info-icon ms-1"
                         title="Ihr Passwort zur Anmeldung auf der Plattform"></fa-icon>
              </label>
              <div class="position-relative">
                <div class="btn btn-primary" [routerLink]="['passwort']">
                  Passwort ändern
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 form-group">
              <label>
                <span>Zwei-Faktor-Authentifizierung</span>
                <fa-icon [icon]="infoIcon" class="info-icon ms-1"
                         title="Ihr zweiter Faktor zur Anmeldung auf der Plattform (E-Mail TAN, Authenticator TAN)"></fa-icon>
              </label>
              <div class="position-relative">
                Aktive Geräte:
                <ul>
                  <li *ngIf="authDevices.length === 0">
                    Es ist kein Gerät aktiv
                  </li>
                  <li *ngFor="let device of authDevices">
                    {{ device.name }}&nbsp;<span
                    *ngIf="device.id === authDeviceActive">(hier in Verwendung)&nbsp;</span>
                    <a href="javascript:void(0)" (click)="deleteDevice(device)">
                      Gerät deaktivieren und löschen
                    </a>
                  </li>
                </ul>
                <div class="btn btn-primary" [routerLink]="['2fa']">
                  <span *ngIf="authDevices.length">Weiteres</span> Gerät aktivieren
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr *ngIf="speichernBtnVisible && categoriesState === LoadingState.Loaded">
  <div *ngIf="speichernBtnVisible && categoriesState === LoadingState.Loaded">
    <button [disabled]="processingAnyData"
            class="btn btn-primary"
            (click)="speichern()">Speichern<span *ngIf="processingAnyData">
      &nbsp;<fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon></span></button>
  </div>
</form>
