<div class="elements-container"
     (mouseenter)="PauseInterval()"
     (mouseleave)="ResumeInterval()"
     [class.zeroTransitionDuration]="!runAnimation"
     [style.width]="containerWidth + '%'"
     [style.transition-duration]="transmissionDuration + 'ms'"
     [style.margin-left]="marginLeft + '%'">
  <div *ngFor="let element of elements; index as i" class="carousel-element" [style.width]="elementWidth + '%'">
    <ng-container *ngTemplateOutlet="template; context: {'element': element, 'index': i}"></ng-container>
  </div>
</div>
