import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {PreviewResponse} from "../../api/client.service";

@Component({
  selector: 'san-zustimmung-bestaetigung',
  templateUrl: './zustimmung-bestaetigung.component.html',
  styleUrls: ['./zustimmung-bestaetigung.component.scss']
})
export class ZustimmungBestaetigungComponent implements OnInit {
  @Input() token: string;
  @Input() pin: string;
  @Input() teilnahmeerklaerung = false;
  @Input() widerruf = false;
  @Input() state: PreviewResponse;

  @Output() abmeldenClicked = new EventEmitter();
  @Output() widerrufClicked = new EventEmitter();
  @Output() downloadTneClicked = new EventEmitter();

  loadingIcon = faSpinner;

  constructor() {
  }

  ngOnInit(): void {
  }
}
