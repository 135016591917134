import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

export enum HeaderActionTypes {
  ShowInfoIcon = '[Header] Show Info Icon',
  HideInfoIcon = '[Header] Hide Info Icon',
  ToggleInfoIcon = '[Header] Toggle Info Icon',
  ShowAccountIcon = '[Header] Show Account Icon',
  HideAccountIcon = '[Header] Hide Account Icon',
  ToggleAccountIcon = '[Header] Toggle Account Icon',
  PlaceLogoLeft = '[Header] Place Spifa Logo Left',
  PlaceLogoMiddle = '[Header] Place Spifa Logo Middle', // default
  ShowCustomLogo = '[Header] Show Custom Logo Right',
  HideCustomLogoAction = '[Header] Hide Custom Logo Right',
  ShowDefaultMainLogo = '[Header] Show Default Main Logo',
  ShowCustomMainLogo = '[Header] Show Custom Main Logo',
}

export class PlaceLogoLeftAction implements Action {
  readonly type = HeaderActionTypes.PlaceLogoLeft;
}

export class PlaceLogoMiddleAction implements Action {
  readonly type = HeaderActionTypes.PlaceLogoMiddle;
}

export class HideCustomLogoAction implements Action {
  readonly type = HeaderActionTypes.HideCustomLogoAction;
}

export class ShowCustomLogoAction implements Action {
  readonly type = HeaderActionTypes.ShowCustomLogo;

  constructor(public src: string, public alt: string) {
  }
}

export class ShowInfoIconAction implements Action {
  readonly type = HeaderActionTypes.ShowInfoIcon;
}

export class HideInfoIconAction implements Action {
  readonly type = HeaderActionTypes.HideInfoIcon;
}

export class ToggleInfoIconAction implements Action {
  readonly type = HeaderActionTypes.ToggleInfoIcon;
}

export class ShowAccountIconAction implements Action {
  readonly type = HeaderActionTypes.ShowAccountIcon;
}

export class HideAccountIconAction implements Action {
  readonly type = HeaderActionTypes.HideAccountIcon;
}

export class ToggleAccountIconAction implements Action {
  readonly type = HeaderActionTypes.ToggleAccountIcon;
}

export class ShowDefaultMainLogoAction implements Action {
  readonly type = HeaderActionTypes.ShowDefaultMainLogo;
}

export class ShowCustomMainLogoAction implements Action {
  readonly type = HeaderActionTypes.ShowCustomMainLogo;

  constructor(public src: string, public alt: string, public link: string) {
  }
}

export type HeaderActions = ShowInfoIconAction
  | HideInfoIconAction
  | ToggleInfoIconAction
  | ShowAccountIconAction
  | HideAccountIconAction
  | ShowCustomLogoAction
  | ShowDefaultMainLogoAction
  | ShowCustomMainLogoAction
  | HideCustomLogoAction
  | PlaceLogoLeftAction
  | PlaceLogoMiddleAction
  | ToggleAccountIconAction;

export interface HeaderState {
  infoIconVisible: boolean;
  accountButtonVisible: boolean;
  customLogo: string;
  customLogoAlt: string;
  logoPosition: 'left' | 'middle';
  customMainLogo: string | null;
  customMainLogoAlt: string | null;
  customMainLogoLink: string | null;
}

export const initialState: HeaderState = {
  infoIconVisible: false,
  accountButtonVisible: false,
  customLogo: null,
  customLogoAlt: null,
  logoPosition: 'middle',
  customMainLogo: null,
  customMainLogoAlt: null,
  customMainLogoLink: null,
};

export function headerStateReducer(state: HeaderState = initialState,
                                   action: HeaderActions): HeaderState {
  switch (action.type) {
    case HeaderActionTypes.ShowInfoIcon:
      return {...state, infoIconVisible: true};
    case HeaderActionTypes.HideInfoIcon:
      return {...state, infoIconVisible: false};
    case HeaderActionTypes.ToggleInfoIcon:
      return {...state, infoIconVisible: !state.infoIconVisible};
    case HeaderActionTypes.ShowAccountIcon:
      return {...state, accountButtonVisible: true};
    case HeaderActionTypes.HideAccountIcon:
      return {...state, accountButtonVisible: false};
    case HeaderActionTypes.ToggleAccountIcon:
      return {...state, accountButtonVisible: !state.accountButtonVisible};
    case HeaderActionTypes.ShowCustomLogo:
      return {...state, customLogo: action.src, customLogoAlt: action.alt};
    case HeaderActionTypes.HideCustomLogoAction:
      return {...state, customLogo: null, customLogoAlt: null};
    case HeaderActionTypes.PlaceLogoLeft:
      return {...state, logoPosition: 'left'};
    case HeaderActionTypes.PlaceLogoMiddle:
      return {...state, logoPosition: 'middle'};
    case HeaderActionTypes.ShowCustomMainLogo:
      return {...state, customMainLogo: action.src, customMainLogoAlt: action.alt, customMainLogoLink: action.link};
    case HeaderActionTypes.ShowDefaultMainLogo:
      return {...state, customMainLogo: null, customMainLogoAlt: null};
    default:
      return state;
  }
}

export const headerStateName = 'headerFeature';

export interface HeaderReducerState {
  header: HeaderState;
}

export const headerReducers: ActionReducerMap<HeaderReducerState> = {
  header: headerStateReducer,
};

export const getHeaderFeatureState = createFeatureSelector<HeaderReducerState>(
  headerStateName
);
