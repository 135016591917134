import { Injectable } from '@angular/core';
import {BaseLayoutComponent} from '../base-layout/base-layout.component';

@Injectable({
  providedIn: 'root'
})
export class FooterModalsService {
  private footer: BaseLayoutComponent;

  constructor() { }

  setInstance(param: BaseLayoutComponent) {
    this.footer = param;
  }


  openImpressum() {
    this.footer.openImpressum();
  }

  openDatenschutz() {
    this.footer.openDatenschutz();
  }

  openNutzung() {
    this.footer.openNutzung();
  }

  openAuth() {
    this.footer.openAuth();
  }

  closeAuth() {
    this.footer.closeAuth();
  }

  openProcessError() {
    this.footer.openProcessError();
  }

  closeProcessError() {
    this.footer.closeProcessError();
  }
}
