import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PreviewResponse} from "../../api/client.service";
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'san-zustimmung-bestaetigung-partner',
  templateUrl: './zustimmung-bestaetigung-partner.component.html',
  styleUrls: ['./zustimmung-bestaetigung-partner.component.scss']
})
export class ZustimmungBestaetigungPartnerComponent implements OnInit {
  @Input() token: string;
  @Input() pin: string;
  @Input() teilnahmeerklaerung = false;
  @Input() widerruf = false;
  @Input() state: PreviewResponse;

  @Output() abmeldenClicked = new EventEmitter();
  @Output() widerrufClicked = new EventEmitter();
  @Output() downloadTneClicked = new EventEmitter();

  loadingIcon = faSpinner;


  constructor() { }

  ngOnInit(): void {
  }

}
