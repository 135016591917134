import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'datumPlusDelta'
})
export class DatumPlusDeltaPipe implements PipeTransform {

  transform(value: Date, days: number): Date {
    const ret = new Date(value);
    ret.setDate(ret.getDate() + days);
    return ret;
  }

}
