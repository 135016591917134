import {AfterContentInit, AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from 'src/environments/environment';
import {getExtradataInstanceFeatureState} from '../../reducers/userdata.reducer';
import {LoadingState} from '../../shared/reducer.includes';
import {Store} from '@ngrx/store';
import {Subscription} from 'rxjs';
import {ClickEvent, StepperComponent} from '../../shared/stepper/stepper.component';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {AppToastService} from '../../toast/app-toast.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {StammdatenComponent} from '../../stammdaten/stammdaten/stammdaten.component';

@Component({
    selector: 'san-agb-datenschutz-aktualisierung',
    templateUrl: './telefonnummer-wird-pflichtfeld-popup.component.html',
    styleUrls: ['./telefonnummer-wird-pflichtfeld-popup.component.scss']
})
export class TelefonnummerWirdPflichtfeldPopupComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {

    constructor(private store: Store, private bmClient: BenutzermanagementAPIService, private router: Router,
                private toastService: AppToastService, private activeModal: NgbActiveModal) {
    }
    @HostBinding('class.p-3') pad = true;

    abzufragendeKeys = [
        'Sanakey/Telefonnummer',
    ];
    forceRequiredKeys = [ // TODO <-- from server required_data
        'Sanakey/Telefonnummer',
    ];

    environment = environment;

    @ViewChild('stepperComponent') stepperComponent: StepperComponent;
    @ViewChild('stammdatenComponent') stammdatenComponent: StammdatenComponent;

    loading = true;
    sending = false;

    anrede = '';

    nutzungAkzeptiert = false;
    datenschutzAkzeptiert = false;

    private benutzerEDISub$: Subscription;
    leftButtonDisabled = false;
    leftButtonText = '';
    rightButtonDisabled = true;
    rightButtonText = '';

    readonly faSpinner = faSpinner;

    ngOnInit(): void {
    }

    ngAfterContentInit(): void {
    }

    init() {
        this.benutzerEDISub$ = this.store.select(getExtradataInstanceFeatureState).subscribe(value => {
            if (value?.ExtradataInstances.loadingState !== LoadingState.Loaded) {
                return;
            }

            const userData = {
                vorname: '',
                nachname: '',
                anrede: '',
                titel: '',
            };

            for (const d of value.ExtradataInstances.alleExtradataInstances) {
                const key = `${d.extradata.namespace}/${d.extradata.name}`;
                switch (key) {
                    case 'Sanakey/Vorname':
                        userData.vorname = d.value;
                        break;
                    case 'Sanakey/Nachname':
                        userData.nachname = d.value;
                        break;
                    case 'Sanakey/Anrede':
                        userData.anrede = d.value;
                        break;
                    case 'Sanakey/Titel':
                        userData.titel = d.value;
                        break;
                }
            }

            this.anrede = `Sehr geehrte`;

            if (userData.anrede === 'Herr') {
                this.anrede += 'r Herr';
            } else if (userData.anrede !== 'Frau') {
                this.anrede += '(r) Frau/Herr';
            }

            if (userData.titel) {
                this.anrede += ' ' + userData.titel;
            }

            if (userData.nachname) {
                this.anrede += ' ' + userData.nachname;
            }

            this.loading = false;

        });
    }

    ngOnDestroy(): void {
        this.benutzerEDISub$?.unsubscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(this.init.bind(this));
    }

    onDataReady($event: any) {
        // this.showUserDataForm();
    }

    onDataSaveError() {
        // this.dataUpdateDone = false;
        // this.saveError.emit();
    }

    onDataSaved() {
        this.closePopup();
    }

    private closePopup() {
        this.activeModal.close(false);
    }

    speichern() {
        this.stammdatenComponent.speichern();
    }
}
