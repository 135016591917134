import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {gueltigeKVNR} from '../../../shared/form-helpers';
import {ClientService} from '../../api/client.service';

@Component({
  selector: 'san-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @HostBinding('class.d-flex') dflex = true;
  @HostBinding('class.flex-column') flexColumn = true;

  @Input() uuid: string;
  @Input() versorgungsVertragID: string;
  @Input() wrongCredentials = false;
  @Input() sending = false;

  @Output() anmeldenClicked = new EventEmitter<{ versicherten_nummer: string, geburtsdatum: string, tnz_beginn: string } >();

  hrefDatenschutzbestimmungen = environment.datenschutzbestimmungen;
  hrefNutzungsbedingungen = environment.nutzungsbedingungen;

  spinnerIcon = faSpinner;

  akzeptiertBedingungen = false;
  form = new FormGroup({
    versicherten_nummer: new FormControl(null, [Validators.required, gueltigeKVNR]),
    geburtsdatum: new FormControl(null, [Validators.required]),
  });

  constructor(private apiClient: ClientService) {
  }

  ngOnInit(): void {
  }

  onAnmeldenClicked() {
    if (this.form.valid) {
      // @ts-ignore
      this.anmeldenClicked.emit(this.form.value);
    }
  }
}
