import {Action, ActionReducerMap, createFeatureSelector} from '@ngrx/store';
import {LoadingState, Ordering, resetOrAddPagesWRTOrdering, resetOrAddPageWRTPageSize} from '../shared/reducer.includes';
import {PaginatedResult} from '../shared/models.includes';
import {
  ExtraData,
  ExtradataInstance,
  ExtradataInstanceOrdering,
  FullExtraData,
  KrankenkassenData
} from '../api/benutzermanagement/models';
import {HeaderReducerState, headerStateReducer} from "./header.reducer";

export enum ExtradataInstanceActionTypes {
  RefreshExtradataInstances = '[ExtradataInstance] Lade ExtradataInstances komplett neu',
  LoadExtradataInstances = '[ExtradataInstance] Lade ExtradataInstances',
  LoadingExtradataInstances = '[ExtradataInstance] ExtradataInstances werden abgerufen',
  ExtradataInstancesLoaded = '[ExtradataInstance] ExtradataInstances erfolgreich abgerufen',
  LastExtradataInstancesPageLoaded = '[ExtradataInstance] Letzte ExtradataInstances Seite erfolgreich abgerufen',
}

export enum VisibleExtradataActionTypes {
  LoadVisibleExtradatas = '[VisibleExtradata] Lade VisibleExtradatas',
  LoadingVisibleExtradatas = '[VisibleExtradata] VisibleExtradatas werden abgerufen',
  LoadedVisibleExtradatas = '[VisibleExtradata] VisibleExtradatas erfolgreich abgerufen',
}

export class RefreshExtradataInstancesAction implements Action {
  readonly type = ExtradataInstanceActionTypes.RefreshExtradataInstances;

  constructor(public pageSize: number = 50, public ordering: Ordering<ExtradataInstanceOrdering> = null) {
  }
}

export class LoadExtradataInstancesPageAction implements Action {
  readonly type = ExtradataInstanceActionTypes.LoadExtradataInstances;

  constructor(public page: number, public pageSize: number = 10, public ordering: Ordering<ExtradataInstanceOrdering>) {
  }
}

export class LastExtradataInstancesPageLoadedAction implements Action {
  readonly type = ExtradataInstanceActionTypes.LastExtradataInstancesPageLoaded;

  constructor(public page: number, public pageSize: number = 10, public ordering: Ordering<ExtradataInstanceOrdering>) {
  }
}

export class LoadingExtradataInstancesPageAction implements Action {
  readonly type = ExtradataInstanceActionTypes.LoadingExtradataInstances;

  constructor(public page: number, public pageSize: number = 10, public ordering: Ordering<ExtradataInstanceOrdering>) {
  }
}

export class ExtradataInstancesPageLoadedAction implements Action {
  readonly type = ExtradataInstanceActionTypes.ExtradataInstancesLoaded;

  constructor(public page: PaginatedResult<ExtradataInstance, ExtradataInstanceOrdering>) {
  }
}

export class LoadingVisibleExtradataPageAction implements Action {
  readonly type = VisibleExtradataActionTypes.LoadingVisibleExtradatas;
}

export class LoadVisibleExtradataPageAction implements Action {
  readonly type = VisibleExtradataActionTypes.LoadVisibleExtradatas;
}

export class LoadedVisibleExtradataPageAction implements Action {
  constructor(public extradata: FullExtraData[]) {
  }

  readonly type = VisibleExtradataActionTypes.LoadedVisibleExtradatas;
}

export type ExtradataInstanceActions = LoadExtradataInstancesPageAction
  | LoadingExtradataInstancesPageAction
  | RefreshExtradataInstancesAction
  | ExtradataInstancesPageLoadedAction;

export type VisibleExtradataActions = LoadVisibleExtradataPageAction
  | LoadingVisibleExtradataPageAction
  | LoadedVisibleExtradataPageAction;

export type ExtradataInstancePage = PaginatedResult<ExtradataInstance, ExtradataInstanceOrdering>;

export interface ExtradataInstancesState {
  ordering: Ordering<ExtradataInstanceOrdering>;
  pageSize: number;
  loadingState: LoadingState;
  total: number;
  pages: { [key: number]: ExtradataInstancePage };
  alleExtradataInstances: ExtradataInstance[];
}

export interface VisibleExtradataState {
  loadingState: LoadingState;
  visibleExtradata: FullExtraData[];
}

export const initialState: ExtradataInstancesState = {
  loadingState: LoadingState.Init,
  total: 0,
  pages: {},
  pageSize: 10,
  ordering: null,
  alleExtradataInstances: [],
};

export const visibleInitialState: VisibleExtradataState = {
  loadingState: LoadingState.Init,
  visibleExtradata: [],
};

export function ExtradataInstancesStateReducer(state: ExtradataInstancesState = initialState,
                                               action: ExtradataInstanceActions): ExtradataInstancesState {
  switch (action.type) {
    case ExtradataInstanceActionTypes.RefreshExtradataInstances:
      return {...state, loadingState: LoadingState.Loading, pages: {}, alleExtradataInstances: []};
    case ExtradataInstanceActionTypes.LoadingExtradataInstances:
      return {...state, loadingState: LoadingState.Loading};
    case ExtradataInstanceActionTypes.ExtradataInstancesLoaded:
      const s = {
        ...state,
        loadingState: LoadingState.Loaded,
        total: action.page.count,
        pages: {...state.pages}
      };
      let reset = resetOrAddPageWRTPageSize(s, action);
      reset = reset && resetOrAddPagesWRTOrdering(s, action);

      s.alleExtradataInstances = [];
      for (const existingPageNumber of Object.keys(s.pages)) {
        const pageData = s.pages[existingPageNumber] as ExtradataInstancePage;
        s.alleExtradataInstances = s.alleExtradataInstances.concat(pageData.results);
      }

      return s;
    default:
      return state;
  }
}

export function VisibleExtradataStateReducer(state: VisibleExtradataState = visibleInitialState,
                                             action: VisibleExtradataActions): VisibleExtradataState {
  switch (action.type) {
    case VisibleExtradataActionTypes.LoadingVisibleExtradatas:
      return {...state, loadingState: LoadingState.Loading};
    case VisibleExtradataActionTypes.LoadedVisibleExtradatas:
      return {...state, loadingState: LoadingState.Loaded, visibleExtradata: action.extradata};
    case VisibleExtradataActionTypes.LoadVisibleExtradatas:
    default:
      return state;
  }
}

export const extradataInstancesStateName = 'ExtradataInstanceFeature';

export interface ExtradataInstanceReducerState {
  ExtradataInstances: ExtradataInstancesState;
  visibleExtradata: VisibleExtradataState;
}

export const extradataInstanceReducers: ActionReducerMap<ExtradataInstanceReducerState> = {
  ExtradataInstances: ExtradataInstancesStateReducer,
  visibleExtradata: VisibleExtradataStateReducer,
};

export const getExtradataInstanceFeatureState = createFeatureSelector<ExtradataInstanceReducerState>(
  extradataInstancesStateName
);

export enum KrankenkassenDataActionTypes {
  LoadKrankenkassenData = '[KrankenkassenData] Lade KrankenkassenData',
  LoadingKrankenkassenData = '[KrankenkassenData] KrankenkassenData werden abgerufen',
  LoadedKrankenkassenData = '[KrankenkassenData] KrankenkassenData erfolgreich abgerufen',
}

export class LoadKrankenkassenDataAction implements Action {
  constructor(public ik: string) {
  }

  readonly type = KrankenkassenDataActionTypes.LoadKrankenkassenData;
}

export class LoadingKrankenkassenDataAction implements Action {
  readonly type = KrankenkassenDataActionTypes.LoadingKrankenkassenData;
}

export class LoadedKrankenkassenDataAction implements Action {
  constructor(public krankenkassenData: KrankenkassenData) {
  }

  readonly type = KrankenkassenDataActionTypes.LoadedKrankenkassenData;
}

export type KrankenkassenDataActions = LoadKrankenkassenDataAction
  | LoadingKrankenkassenDataAction
  | LoadedKrankenkassenDataAction;

export interface KrankenkassenDataState {
  loadingState: LoadingState;
  krankenkassenData: KrankenkassenData;
}

export const krankenkassenDataState: KrankenkassenDataState = {
  loadingState: LoadingState.Init,
  krankenkassenData: {strasse: '', hausnummer: '', plz: '', ort: '', name: '', logo: ''},
};

export function KrankenkassenDataStateReducer(state: KrankenkassenDataState = krankenkassenDataState,
                                             action: KrankenkassenDataActions): KrankenkassenDataState {
  switch (action.type) {
    case KrankenkassenDataActionTypes.LoadingKrankenkassenData:
      return {...state, loadingState: LoadingState.Loading};
    case KrankenkassenDataActionTypes.LoadedKrankenkassenData:
      return {...state, loadingState: LoadingState.Loaded, krankenkassenData: action.krankenkassenData};
    case KrankenkassenDataActionTypes.LoadKrankenkassenData:
    default:
      return state;
  }
}

export const krankenkassenDataStateName = 'KrankenkassenDataFeature';

export interface KrankenkassenDataReducerState {
  KrankenkassenData: KrankenkassenDataState;
}

export const krankenkassenDataReducers: ActionReducerMap<KrankenkassenDataReducerState> = {
  KrankenkassenData: KrankenkassenDataStateReducer,
};

export const getKrankenkassenDataFeatureState = createFeatureSelector<KrankenkassenDataReducerState>(
  krankenkassenDataStateName
);
