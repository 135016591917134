import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'onkogisterZuUrogisterVertragNamen'
})
export class OnkogisterZuUrogisterVertragNamenPipe implements PipeTransform {

  transform(vertragsname: string): string {
    let r = vertragsname;
    r = r.replace('DIFA-Science', 'Uroscience')
    r = r.replace('Onkogister', 'Urogister')
    r = r.replace('onkogister', 'urogister')
    return r;
  }

}
