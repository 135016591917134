import {Component, OnInit} from '@angular/core';
import {AppToastService} from '../app-toast.service';

@Component({
  selector: 'san-app-toast',
  templateUrl: './app-toast.component.html',
  styleUrls: ['./app-toast.component.scss']
})
export class AppToastComponent implements OnInit {
  toastService: AppToastService;

  constructor(toastService: AppToastService) {
    this.toastService = toastService;
  }

  ngOnInit(): void {
  }

}
