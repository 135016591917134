<div class="pop-height">
  <san-blocking-loader [active]="loading" nachricht="Die Informationen werden geladen ..."></san-blocking-loader>

  <object *ngIf="windowWidth > 0 && windowWidth >= 996" type="image/svg+xml" [class.hidden]="loading"
          data="/assets/popups/hybrid-drgabrechnung-flyer/desktop.svg"
          onload="window.dispatchEvent(new Event('__SAN_POPUP_HDRG_LOADED'))"
          width="100%" height="auto">
  </object>

  <object *ngIf="windowWidth > 0 && windowWidth < 996" type="image/svg+xml" [class.hidden]="loading"
          data="/assets/popups/hybrid-drgabrechnung-flyer/mobile.svg"
          onload="window.dispatchEvent(new Event('__SAN_POPUP_HDRG_LOADED'))"
          width="100%" height="auto">
  </object>

  <a href="https://l.sanakey-portal.de/H-DRG-Flyer"
     target="_blank" #downloadLink class="d-none">Flyer</a>

  <!--<img ngSrc="/assets/popups/hybrid-drgabrechnung-flyer-popup/Popup_Hybrid-DRG.png"-->
  <!--     width="1714"-->
  <!--     height="807"-->
  <!--     priority=""-->
  <!--     style="max-width: 100%; height: auto;" alt="Hybrid DRG Ankündigung"/>-->
</div>
