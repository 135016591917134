import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Subscriber, throwError} from 'rxjs';
import {catchError, take} from 'rxjs/operators';
import {AppToastService} from '../toast/app-toast.service';
import {Store} from '@ngrx/store';
import {AuthinfoActionTypes, getBenutzerInfoFeatureState} from '../reducers/authinfo.reducer';
import {Injectable, Injector} from '@angular/core';
import {FooterModalsService} from '../shared/footer-modals.service';
import {BenutzermanagementAPIService} from './benutzermanagement/benutzermanagement-api.service';
import {Actions, ofType} from '@ngrx/effects';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private toastService: AppToastService, private store: Store, private footerService: FooterModalsService,
              private actions: Actions, private injector: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
            console.log(errorMessage);
            window.alert('Bitte überprüfen Sie Ihre Internetverbindung');
          } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            if (error.status === 401) {
              const benutzerApi = this.injector.get(BenutzermanagementAPIService);
              benutzerApi.logOut().pipe(take(1)).subscribe();
              let resolution: Subscriber<any> = null;
              this.footerService.openAuth();
              this.actions.pipe(ofType(AuthinfoActionTypes.SetAuthenticated)).pipe(take(1)).subscribe(r => {
                request = request.clone({
                  setHeaders: benutzerApi.authHeadersObj()
                });
                this.footerService.closeAuth();
                next.handle(request).subscribe(resolution);
                return null;
              });
              return new Observable<any>(sub => {
                resolution = sub;
              });
            }
            if (error.status === 499) {
              console.log(errorMessage);
              this.footerService.openProcessError();
            }
          }
          return throwError(error);
        })
      );
  }
}
