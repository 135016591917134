<button class="btn d-flex flex-row align-items-center" (click)="out = !out">
  <span class="avatar justify-content-center align-items-center d-flex border">
    <fa-icon class="" size="1x" [icon]="userIcon"></fa-icon>
  </span>
  <span class="icon x1-5 icons" [ngClass]="{'show-second': out}">
    <fa-icon class="icon-default" size="1x" [icon]="pfeilIcon"></fa-icon>
    <fa-icon class="icon-second" size="1x" [icon]="pfeilHochIcon"></fa-icon>
  </span>
</button>

<ul #dd class="dd-menu text-center" [ngClass]="{out: out}">
  <li class="p-2 border-bottom font-weight-bold">
    Angemeldet als:<br/>
    {{userdata.vorname}} {{userdata.nachname}}
  </li>
  <li class="p-2 font-italic">
    Kundennummer: {{userdata.kundennummer}}
  </li>
  <li class="p-2 font-italic">
    ({{userdata.email}})
  </li>
  <li class="p-2">
    <button class="btn w-100" routerLink="/stammdaten" (click)="out=false">
      Meine Stammdaten
    </button>
  </li>
  <li class="p-2">
    <button class="btn w-100 shrink-top-animated" [class.out]="!loggingOut" (click)="logout()">
      Abmelden
    </button>
    <span class="w-100 text-center shrink-top-animated" [class.out]="loggingOut">
      <fa-icon [icon]="loadingIcon" animation="spin-pulse"></fa-icon>
    </span>
  </li>
</ul>
