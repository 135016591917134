import {Injectable, NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules, PreloadingStrategy, Route} from '@angular/router';
import {LandingComponent} from '../landing/landing.component';
import {PageNotFoundComponent} from '../page-not-found/page-not-found.component';
import {BaseLayoutComponent} from '../base-layout/base-layout.component';
import {AuthGuard} from './auth.guard';
import {Observable, of} from 'rxjs';
import {catchError} from "rxjs/operators";

const routes: Routes = [
  {
    path: '', component: BaseLayoutComponent,
    children: [
      {
        path: '', pathMatch: 'full', component: LandingComponent,
        data: {
          breadcrumb: 'Öffentliche Startseite',
        },
      },
      {
        path: 'registrierung',
        loadChildren: () => import('../registration/registration.module').then(m => m.RegistrationModule),
        data: {
          breadcrumb: 'Registrierung',
        },
      },
      {
        path: 'reset_password/verify',
        loadChildren: () => import('../reset-password/reset-password.module').then(m => m.ResetPasswordModule),
        data: {
          breadcrumb: 'Passwort Zurücksetzung',
        },
      },
      {
        path: 'dashboard',
        loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
        data: {
          preload: true,
          breadcrumb: 'Startseite',
        },
      },
      {
        path: '',
        loadChildren: () => import('../lex-online/lex-online.module').then(m => m.LexOnlineModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Lex Online',
        },
      },
      {
        path: '',
        loadChildren: () => import('../kontakt-und-hilfe/kontakt-und-hilfe.module').then(m => m.KontaktUndHilfeModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Kontakt & Hilfe',
        },
      },
      {
        path: '',
        loadChildren: () => import('../stammdaten/stammdaten.module').then(m => m.StammdatenModule),
        canActivate: [AuthGuard],
        data: {
          preload: true,
          breadcrumb: 'Stammdaten',
        },
      },
      {
        path: '',
        loadChildren: () => import('../dokumente-und-vertraege/dokumente-und-vertraege.module').then(m => m.DokumenteUndVertraegeModule),
        canActivate: [AuthGuard],
        data: {
          preload: true,
          breadcrumb: 'Dokumente und Verträge',
        },
      },
      {
        path: '',
        loadChildren: () => import('../versorgungsvertraege/versorgungsvertraege.module').then(m => m.VersorgungsvertraegeModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Versorgungsverträge',
        },
      },
      // {
      //   path: '',
      //   loadChildren: () => import('../difa/difa.module').then(m => m.DifaModule),
      //   canActivate: [AuthGuard],
      //   data: {
      //     breadcrumb: 'DIFA',
      //   },
      // },
      {
        path: '',
        loadChildren: () => import('../hdrg/hdrg.module').then(m => m.HDRGModule),
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'HDRG',
        },
      },
      {
        path: '',
        loadChildren: () => import('../arzt-liste/arzt-liste.module').then(m => m.ArztListeModule),
        data: {
          breadcrumb: 'Arzt-Liste',
        },
      },
      {
        path: '',
        loadChildren: () => import('../patienten-selbst-einschreibung/patienten-selbst-einschreibung.module')
          .then(m => m.PatientenSelbstEinschreibungModule),
        data: {
          breadcrumb: 'Selbst-Einschreibung',
        },
      },
      {path: 'register', redirectTo: 'registrierung'},
      {path: '**', component: PageNotFoundComponent},
    ],
  },
]; // sets up routes constant where you define your routes

@Injectable({providedIn: 'root'})
class MyPreloadingStrategy extends PreloadingStrategy {
  preload(route: Route, fn: () => Observable<any>): Observable<any> {
    if ((route.data.hasOwnProperty('preload') && route.data.preload)) {
      return fn().pipe(catchError(() => of(null)));
    }
    return of(null);
  }
}

// configures NgModule imports and exports
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: MyPreloadingStrategy})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
