import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'san-cookie-warnung-content',
  templateUrl: './cookie-warnung-content.component.html',
  styleUrls: ['./cookie-warnung-content.component.scss']
})
export class CookieWarnungContentComponent implements OnInit {
  required = true;
  tracking = false;
  auswahl_merken = false;

  constructor(private activeModalService: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  auswahlBestaetigen() {
    this.activeModalService.close({
      required: this.required,
      tracking: this.tracking,
      auswahl_merken: this.auswahl_merken
    });
  }

  alleAkzeptieren(auswahl_merken: boolean) {
    this.activeModalService.close({required: true, tracking: true, auswahl_merken: auswahl_merken});
  }

}
