import {Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {Store} from '@ngrx/store';
import {
  ExtradataInstanceReducerState,
  getExtradataInstanceFeatureState,
  getKrankenkassenDataFeatureState,
  LoadExtradataInstancesPageAction,
  LoadKrankenkassenDataAction,
  LoadVisibleExtradataPageAction
} from '../../reducers/userdata.reducer';
import {
  AbstractControl,
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {LoadingState} from '../../shared/reducer.includes';
import {merge, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {AppToastService} from '../../toast/app-toast.service';
import {Device, ExtradataInstance, FullExtraData} from '../../api/benutzermanagement/models';
import {faInfoCircle, faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {animate, style, transition, trigger} from '@angular/animations';
import {ibanValidator, notEquals} from '../../shared/validators';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {VVFixFehlerAction} from '../../shared/actions/actions';
import {getBenutzerInfoFeatureState} from '../../reducers/authinfo.reducer';
import {MODULE_ROOT} from '../root.config';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DeleteDevicePopupComponent} from '../delete-device-popup/delete-device-popup.component';
import {ZertifizierungenComponent} from '../zertifizierungen/zertifizierungen.component';
import {ClientService} from "../../versorgungsvertraege/api/client.service";
import {HttpClient} from "@angular/common/http";

export class FormArrayWithEdi extends FormArray {
  public edi: ExtradataInstance;
}

export class FormControlWithEdi extends FormControl {
  public edi: ExtradataInstance;
}

class FormControlWithHints extends FormControl {
  controlFocused$ = new Subject<string>();
  controlClicked$ = new Subject<string>();
  controlSelected$ = new Subject<string>();
  // noinspection JSUnusedGlobalSymbols
  auswahl = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    // const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.controlFocused$;
    return merge(debouncedText$, inputFocus$, this.controlClicked$, this.controlSelected$).pipe(
      map(term => this.arr.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)));
    // tslint:disable-next-line:semicolon
  };

  constructor(private arr: { name: string }[],
              formState?: any,
              validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
              asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    super(formState, validatorOrOpts, asyncValidator);
  }
}

class FormControlWithHintsAndEdi extends FormControlWithHints {
  public edi: ExtradataInstance;
}

const FALLBACK_CATEGORIES = { // TODO: enternen und mit Server abgleichen
  'Sanakey/Kundennummer': 'Basisdaten',
  'Sanakey/Anrede': 'Basisdaten',
  'Sanakey/Titel': 'Basisdaten',
  'Sanakey/Vorname': 'Basisdaten',
  'Sanakey/Nachname': 'Basisdaten',
  'Sanakey/LANR': 'Basisdaten',
  'Sanakey/BSNR': 'Basisdaten',
  'Sanakey/Institutionskennzeichen': 'Basisdaten',
  'Sanakey/Region': 'Basisdaten',
  'Sanakey/Verbandsmitgliedschaften': 'Basisdaten',
  'Sanakey/Standard E-Mail': 'Kontaktdaten',
  'Sanakey/Telefonnummer': 'Kontaktdaten',
  'Sanakey/Mobilnummer': 'Kontaktdaten',
  'Sanakey/Faxnummer': 'Kontaktdaten',
  'Sanakey/Name der Institution': 'Praxisdaten',
  'Sanakey/Strasse': 'Praxisdaten',
  'Sanakey/Hausnummer': 'Praxisdaten',
  'Sanakey/Postleitzahl': 'Praxisdaten',
  'Sanakey/Ort': 'Praxisdaten',
  'Sanakey/Bankverbindung/Kontoinhaber': 'Bankdaten',
  'Sanakey/Bankverbindung/IBAN': 'Bankdaten',
  'Sanakey/Bankverbindung/BIC': 'Bankdaten',
  'Sanakey/Zertifikate': 'Zertifizierungen und Nachweise',
  'Sanakey/Selbstauskunft/400xInjektionstechniken-24M': 'Selbstauskünfte',
};

const KRANKENKASSEN_USER_VISIBLE_KEYS = [
  'Sanakey/Kundennummer',
  'Sanakey/Anrede',
  'Sanakey/Titel',
  'Sanakey/Vorname',
  'Sanakey/Nachname',
  'Sanakey/Standard E-Mail',
  'Sanakey/Krankenkasse Ik',
  'Sanakey/Krankenkasse Name',
  'Sanakey/Krankenkasse Strasse',
  'Sanakey/Krankenkasse Hausnummer',
  'Sanakey/Krankenkasse Postleitzahl',
  'Sanakey/Krankenkasse Ort',
  'Sanakey/Krankenkasse Logo',
]

interface UpdateResult {
  controlName: string;
  control: AbstractControl;
  errors?: string[];
}

@Component({
  selector: 'san-stammdaten',
  templateUrl: './stammdaten.component.html',
  styleUrls: ['./stammdaten.component.scss'],
  animations: [
    trigger('vis', [
      transition(':enter', [
        style({'max-height': 0, overflow: 'hidden'}),
        animate('0.5s ease', style({'max-height': '100%'})),
        style({'max-height': 'unset'}),
      ]),
      transition(':leave', [
        style({'max-height': '100px', overflow: 'hidden'}),
        animate('0.3s ease', style({'max-height': 0})),
      ]),
    ]),
  ],
})
export class StammdatenComponent implements OnInit, OnDestroy {
  @HostBinding('class.d-block') dblock = true;
  @HostBinding('class.p-3') pad = true;

  @Input() onlyKeys: string[] = null;
  @Input() forceRequired: string[] = null;
  @Input() speichernBtnVisible = true;

  @Output() saving = new EventEmitter<any>();
  @Output() saved = new EventEmitter<any>();
  @Output() allSaved = new EventEmitter<any>();
  @Output() saveError = new EventEmitter<any>();
  @Output('data-ready') dataReady = new EventEmitter<any>();
  // @Output('data-valid') dataValid = new EventEmitter<any>(); // tba
  // @Output('data-invalid') dataInvalid = new EventEmitter<any>(); // tba

  @ViewChild('zertifizierungenComponent') zertifizierungenComponent: ZertifizierungenComponent;

  MODULE_ROOT = MODULE_ROOT;

  visibleCategories: { [key: string]: boolean } = {};
  visibleKeys: { [key: string]: boolean } = {};
  categoriesState = LoadingState.Init;

  infoIcon = faInfoCircle;
  loadingIcon = faSpinner;
  deleteVerbandIcon = faTrashAlt;

  stammdatenForm = new FormGroup({});
  extradata: { [key: string]: FullExtraData } = {};
  krankenkassendata: { [key: string]: string } = {};
  extradataInstances: { [key: string]: ExtradataInstance } = {};
  validatorMessages: { [key: string]: { [key: string]: string } } = {};
  initialValues: { [key: string]: string } = {};
  processingData: { [key: string]: boolean } = {};
  private ignoreOnUpdate: string[] = [];
  processingAnyData = true;
  private ediState$: Subscription;
  private sub2$: Subscription;
  private kkData$: Subscription;

  extraDataState: ExtradataInstanceReducerState;
  LoadingState = LoadingState;

  title: { id: number; name: string }[] = [];
  verbaende: { id: number; name: string }[] = [];
  zertifikatstypen: { id: number; name: string }[] = [];
  files: { [key: string]: string[] };

  authDevices: Device[] = [];
  authDeviceActive: number;

  titleAuswahlConfig = {
    titleFocused$: new Subject<string>(),
    titleClicked$: new Subject<string>(),
    titleSelected$: new Subject<string>(),
    // noinspection JSUnusedGlobalSymbols
    titleAuswahl: (text$: Observable<string>) => {
      const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
      // const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
      const inputFocus$ = this.titleAuswahlConfig.titleFocused$;
      return merge(debouncedText$, inputFocus$, this.titleAuswahlConfig.titleClicked$, this.titleAuswahlConfig.titleSelected$)
        .pipe(
          map(term => this.title.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).map(v => v.name)));
      // tslint:disable-next-line:semicolon
    }
  };
  formatter = (x: { name: string }) => x.name;

  constructor(private benutzerApi: BenutzermanagementAPIService, private store: Store,
              private toastService: AppToastService, private popupService: NgbModal) {
  }

  ngOnDestroy(): void {
    this.ediState$?.unsubscribe();
    this.sub2$?.unsubscribe();
    this.kkData$?.unsubscribe();
  }

  ngOnInit(): void {
    this.ediState$ = this.store.select(getExtradataInstanceFeatureState).subscribe(r => {
      if (r.ExtradataInstances.loadingState === LoadingState.Init) {
        this.extraDataState = r;
        return;
      }

      if (r.ExtradataInstances.loadingState !== LoadingState.Loaded || r.visibleExtradata.loadingState !== LoadingState.Loaded) {
        return;
      }

      const isKrankenkassenUser = r.ExtradataInstances.alleExtradataInstances.some((ed) => {
        return ed.extradata.name === 'Benutzergruppe' && ed.value === 'Krankenkassen Benutzer'
      });

      if (isKrankenkassenUser) {
        this.onlyKeys = KRANKENKASSEN_USER_VISIBLE_KEYS
        this.store.dispatch(new LoadKrankenkassenDataAction(r.ExtradataInstances.alleExtradataInstances.find(edi => {
          return edi.extradata.name === 'Krankenkasse Ik'
        }).value
        ));
      }

      this.files = {};

      for (const ved of r.visibleExtradata.visibleExtradata) {

        const key = `${ved.namespace}/${ved.name}`;
        if (ved.type === 'tuple') {
          continue;
        }

        if (this.ignoreOnUpdate.indexOf(key) > -1) {
          continue;
        }

        if (this.onlyKeys && this.onlyKeys.length && this.onlyKeys.indexOf(key) < 0) {
          continue;
        }

        if (!ved.category) {
          this.visibleCategories[FALLBACK_CATEGORIES[key]] = true;
        } else {
          this.visibleCategories[ved.category] = true;
        }
        this.visibleKeys[key] = true;

        let control: AbstractControl;
        const validators = [];

        if (this.stammdatenForm.controls[key]) {
          control = this.stammdatenForm.controls[key] as AbstractControl;
        } else {
          if (ved.type === 'list') {
            control = new FormArrayWithEdi([]);
          } else if (ved.type === 'tuple') {
            console.warn('Control Type für "Tuple" nicht implementiert, aber Daten erhalten');
            continue;
          } else {
            // Simple Types
            control = new FormControlWithEdi({value: '', disabled: true});
          }
        }

        const validatorMessages: { [key: string]: string } = {};

        if (ved.required ||
          (this.forceRequired && this.forceRequired.indexOf && this.forceRequired.indexOf(key) >= 0)) {
          validators.push(Validators.required);
          validatorMessages.required = 'Dieses Feld muss ausgefüllt werden';
        }

        if (ved.type === 'email') {
          // validators.push(Validators.email);
          validatorMessages.email = 'Bitte eine gültige E-Mail Adresse angeben';
        }

        for (const serversideValidator of ved.serverside_validators) {
          const defTokens = serversideValidator.split(',');
          const clazz = defTokens[0];
          const def = defTokens.slice(1).join('');
          switch (clazz) {
            case 'Function':
              switch (def) {
                case 'IBAN Validator':
                  validators.push(ibanValidator);
                  validatorMessages.iban = `Die IBAN ist nicht korrekt`;
                  break;
                default:
                  console.warn('Function validator "%s" required', def);
              }
              break;
            case 'Regex':
              validators.push(Validators.pattern(def));
              validatorMessages.pattern = 'Das Format ist nicht gültig';
              break;
            case 'Minimum':
              validators.push(Validators.min(parseInt(def, 10)));
              validatorMessages.min = `Der Wert muss mindestens ${def} betragen`;
              break;
            case 'Maximum':
              validators.push(Validators.max(parseInt(def, 10)));
              validatorMessages.max = `Der Wert darf höchstens ${def} betragen`;
              break;
            default:
              console.error('Unbekannte ServerSide Validator Klasse: "%s" mit Definition "%s"', clazz, def);
              break;
          }
        }

        if (ved.user_creatable || ved.user_changeable) {
          control.setValidators(validators);
          this.validatorMessages[key] = validatorMessages;
        }

        this.stammdatenForm.addControl(key, control);
        this.extradata[`${ved.namespace}/${ved.name}`] = ved;
      }

      for (const vedi of r.ExtradataInstances.alleExtradataInstances) {
        const key = `${vedi.extradata.namespace}/${vedi.extradata.name}`;

        if (this.onlyKeys && this.onlyKeys.length && this.onlyKeys.indexOf(key) < 0) {
          continue;
        }

        const control = this.stammdatenForm.controls[key];
        if (!control) {
          // console.info(`${key} übersprungen, da kein Control vorhanden ist`);
          continue;
        }

        if (this.ignoreOnUpdate.indexOf(key) > -1) {
          continue;
        }

        this.extradataInstances[key] = vedi;

        let v = vedi.value;
        if (vedi.value_second) {
          v = [v, vedi.value_second];
        }
        if (vedi.alternative_value) {
          v = vedi.alternative_value;
        }

        this.initialValues[key] = v;

        if (Array.isArray(v)) {
          const fa = control as FormArrayWithEdi;
          if (!fa) {
            console.error(`Value mit Typ list erhalten, aber Control ist kein FormArray: ${key}`);
            continue;
          }
          fa.clear();
          const controls = (this.toFormControl(vedi) as FormArray).controls;
          for (const el of controls) {
            fa.push(el);
          }
          fa.edi = vedi;
        } else if (typeof v === 'object') {
          // tuple
          const c = control as FormArrayWithEdi;
          c.edi = vedi;
          console.warn('Tuple (object) noch nicht bei Form unterstützt aber empfangen');
          continue;
        } else {
          const c = control as FormArrayWithEdi;
          c.edi = vedi;
          control.setValue(v);
        }

        control.updateValueAndValidity();
        control.markAsPristine();
        control.markAsUntouched();
      }

      /* enable all not changeable and creatable ED */
      for (const ved of r.visibleExtradata.visibleExtradata) {
        const key = `${ved.namespace}/${ved.name}`;

        if (this.onlyKeys && this.onlyKeys.length && this.onlyKeys.indexOf(key) < 0) {
          continue;
        }

        const control = this.stammdatenForm.controls[key];

        if (!control) {
          continue;
        }

        this.processingData[key] = false;
        if (this.extradataInstances[key] && ved.user_changeable) {
          control.enable();
        } else if (!this.extradataInstances[key] && ved.user_creatable) {
          control.enable();
        }

        // if (ved.required) {
        //   control.markAsDirty();
        // }
      }

      this.processingAnyData = false;

      /* form set up */
      this.extraDataState = r;

      /* add empty Verband row*/
      this.addEmptyVerbandsRow();
      this.addEmptyZertifikatRow();
      this.categoriesState = LoadingState.Loaded;
      this.dataReady.emit(this);
    });

    this.benutzerApi.fetchTitel().subscribe(
      (next) => {
        if (next.results.length < 1) {
          this.toastService.show('Verbindung fehlerhaft',
            'Die Liste der bekannten Titel konnte nicht geladen werden (R.102).',
            'danger');
        }
        for (const choice of next.results[0].enum.choices) {
          this.title.push(choice);
        }
      },
      () => {
        this.toastService.show('Verbindung fehlgeschlagen',
          'Die Liste der bekannten Berufsverbände konnte nicht geladen werden (R.103).',
          'danger');
      });

    this.benutzerApi.fetchBerufsverbaende().subscribe(
      (next) => {
        if (next.results.length < 1) {
          this.toastService.show('Verbindung fehlerhaft',
            'Die Liste der bekannten Titel konnte nicht geladen werden (R.100).',
            'danger');
        }
        for (const choice of next.results[0].enum.choices) {
          this.verbaende.push(choice);
        }
      },
      () => {
        this.toastService.show('Verbindung fehlgeschlagen',
          'Die Liste der bekannten Berufsverbände konnte nicht geladen werden (R.101).',
          'danger');
      });

    this.benutzerApi.fetchEnum('Sanakey', 'Zertifikate/Zertifikatstyp').subscribe(
      (next) => {
        if (next.results.length < 1) {
          this.toastService.show('Verbindung fehlerhaft',
            `Die Liste der ${'Sanakey/Zertifikate/Zertifikatstyp'} konnte nicht geladen werden.`,
            'danger');
        }
        for (const choice of next.results[0].enum.choices) {
          this.zertifikatstypen.push(choice);
        }
      },
      () => {
        this.toastService.show('Verbindung fehlgeschlagen',
          `Die Liste der ${'Sanakey/Zertifikate/Zertifikatstyp'} konnte nicht geladen werden (Verbindungsfehler).`,
          'danger');
      });

    this.sub2$ = this.store.select(getBenutzerInfoFeatureState).subscribe(value => {
      if (value.auth.isAuthenticated) {
        this.authDeviceActive = value.auth.device_id;
        this.refreshDevices();
      }
    });
    this.kkData$ = this.store.select(getKrankenkassenDataFeatureState).subscribe(r => {
      if (r.KrankenkassenData.loadingState !== LoadingState.Loaded) {
        return;
      }

      this.krankenkassendata['Sanakey/Krankenkasse Strasse'] = r.KrankenkassenData.krankenkassenData.strasse;
      this.krankenkassendata['Sanakey/Krankenkasse Hausnummer'] = r.KrankenkassenData.krankenkassenData.hausnummer;
      this.krankenkassendata['Sanakey/Krankenkasse Postleitzahl'] = r.KrankenkassenData.krankenkassenData.plz;
      this.krankenkassendata['Sanakey/Krankenkasse Ort'] = r.KrankenkassenData.krankenkassenData.ort;
      this.krankenkassendata['Sanakey/Krankenkasse Name'] = r.KrankenkassenData.krankenkassenData.name;
      this.krankenkassendata['Sanakey/Krankenkasse Logo'] = r.KrankenkassenData.krankenkassenData.logo;

      for (let key in this.krankenkassendata) {
        if (this.stammdatenForm.controls[key]) {
          const control = this.stammdatenForm.controls[key] as AbstractControl;
          control.setValue(this.krankenkassendata[key])
          control.updateValueAndValidity();
          control.markAsPristine();
          control.markAsUntouched()
        }
      }
    })
  }

  toFormControl(v: ExtradataInstance | string | number | boolean) {
    if (!v) {
      return new FormControl(null);
    }

    if (typeof v === 'string' || typeof v === 'number' || typeof v === 'boolean') {
      return new FormControl(v);
    }

    const k = `${v.extradata.namespace}/${v.extradata.name}`;
    if (v.extradata.type === 'list') {
      const fa = new FormArrayWithEdi([]);
      fa.edi = v;
      for (const el of v.value) {
        fa.push(this.toFormControl(el));
      }
      return fa;
    } else if (v.extradata.type === 'tuple') {
      const r = new FormArrayWithEdi([this.toFormControl(v.value), this.toFormControl(v.value_second)]);
      r.edi = v;
      return r;
    } else if (v.extradata.type === 'file') {
      const r = new FormControlWithEdi();
      r.edi = v;
      if (!this.files[k]) {
        this.files[k] = [];
      }
      this.files[k].push(v.value);
      return r;
    } else if (v.extradata.type === 'enum') {
      // TODO: strikte Enums unterstützen; momentan ist Freitext + Vorschlag gültig
      let c;

      if (v.extradata.namespace === 'Sanakey' && v.extradata.name === 'Zertifikate/Zertifikatstyp') {
        c = new FormControlWithHintsAndEdi(this.zertifikatstypen);  // TODO: hier sind statisch die verbände drin; das ist nicht gut
      } else if (v.extradata.namespace === 'Sanakey' && v.extradata.name === 'Verbandsmitgliedschaft/Verbandsname') {
        c = new FormControlWithHintsAndEdi(this.verbaende);  // TODO: hier sind statisch die verbände drin; das ist nicht gut
      } else {
        c = new FormControlWithHintsAndEdi(this.verbaende);  // TODO: hier sind statisch die verbände drin; das ist nicht gut
      }
      c.edi = v;
      if (v.alternative_value) {
        c.setValue({name: v.alternative_value});
      } else {
        c.setValue({name: v.value});
      }
      return c;
    } else {
      const control = new FormControlWithEdi(null);
      control.edi = v;
      control.setValue(v.value);
      return control;
    }
  }

  speichern(options?: {
    unchanged_fail_silently: boolean
  }) {
    // tslint:disable-next-line:variable-name
    let _options: any = options;
    if (!_options) {
      _options = {};
    }
    if (_options.unchanged_fail_silently === undefined) {
      _options.unchanged_fail_silently = false;
    }
    this.saving.emit(this);
    /* get diff*/
    const listKeys = ['Sanakey/Verbandsmitgliedschaften', 'Sanakey/Zertifikate'];
    let anyListDirty = false;
    for (const vbKey of listKeys) {
      const vbControl = this.stammdatenForm.controls[vbKey] as FormArray;
      let vbDirty = false;
      if (vbControl) {
        vbControl.controls.splice(vbControl.controls.length - 1, 1);
        const arr = vbControl.controls;
        const vbCountChanged = arr.length !== (this.initialValues[vbKey]?.length || 0);

        vbDirty = vbCountChanged
          || vbControl.dirty
          || arr.reduce(((previousValue, currentValue) => previousValue || currentValue.dirty), false);
      }
      anyListDirty ||= vbDirty;
    }

    if (!this.stammdatenForm.dirty && !anyListDirty && !this.zertifizierungenComponent?.hasChanged()) {
      if (!_options.unchanged_fail_silently) {
        this.toastService.show('Keine Änderung', 'Ihre Stammdaten haben sich nicht geändert', 'info');
      }
      this.addEmptyVerbandsRow();
      this.saved.emit(this);
      this.allSaved.emit();
      return;
    }


    const afterHttpAction = () => {
      // once for all Speicherungen
      this.allSaved.emit();
      this.store.dispatch(new LoadVisibleExtradataPageAction());
      this.store.dispatch(new LoadExtradataInstancesPageAction(1, 50, null));
    };

    const operations: Promise<UpdateResult>[] = [];

    if (this.zertifizierungenComponent) {
      const zertProm = this.zertifizierungenComponent.speichern();
      if (zertProm) {
        operations.push(zertProm);
      }
    }

    for (const controlName of Object.keys(this.stammdatenForm.controls)) {
      const control = this.stammdatenForm.controls[controlName];
      let dirtyList = false;
      let validList = false;
      const isList = Array.isArray((control as any).controls);
      if (Array.isArray((control as any).controls)) {
        dirtyList = (control as FormArray).controls
          .reduce((previousValue, currentValue) => previousValue || currentValue.dirty, false);
        dirtyList ||= (control as FormArray).controls.length !== (this.initialValues[controlName]?.length || 0);
        validList = (control as FormArray).controls
          .reduce((previousValue, currentValue) => previousValue && currentValue.valid, true);
      }

      if ((!isList && control.dirty) || (isList && dirtyList)) {
        if ((!isList && !control.valid) || (isList && !validList)) {
          continue;
        }

        if (this.ignoreOnUpdate.indexOf(controlName) > -1) {
          this.ignoreOnUpdate.splice(this.ignoreOnUpdate.indexOf(controlName), 1);
        }

        this.processingData[controlName] = true;
        this.processingAnyData = true;
        control.disable();

        // lists are special elements
        operations.push(new Observable<UpdateResult>((subscriber) => {
          if (this.extradataInstances[controlName]) {
            /* send update (or delete) */
            if ([null, ''].includes(control.value) && !this.extradata[controlName].allow_blank) {
              this.benutzerApi.deleteUserdata(this.extradataInstances[controlName]).subscribe(r => {
                control.markAsPristine();
                control.markAsUntouched();
                delete this.extradataInstances[controlName];
                subscriber.next({controlName, control});
                subscriber.complete();
              }, (error: { error: string | string[] }) => {
                if (Array.isArray(error.error)) {
                  subscriber.next({controlName, control, errors: error.error});
                } else {
                  subscriber.next({controlName, control, errors: [error.error]});
                }
                subscriber.complete();
              });
            } else {
              let value: any = null;
              let byValue = false;
              if (controlName === 'Sanakey/Verbandsmitgliedschaften') {
                byValue = true;
                value = [];
                for (const el of control.value) {
                  const name = el[0];
                  let vb: any;
                  if (typeof name === 'string') {
                    if (this.istBekannterVerband(name)) {
                      vb = {
                        value: {value: name},
                      };
                    } else {
                      vb = {
                        value: {alternative_value: name},
                      };
                    }
                  } else {
                    if (this.istBekannterVerband(name.name)) {
                      vb = {
                        value: {value: name.name},
                      };
                    } else {
                      vb = {
                        value: {alternative_value: name.name},
                      };
                    }
                  }
                  if (el[1]) {
                    vb.value_second = {value: el[1]};
                  }
                  value.push(vb);
                }
              } else if (controlName === 'Sanakey/Zertifikate') {
                byValue = true;
                value = [];
                for (const el of control.value) {
                  const name = el[0];
                  let vb: any;
                  if (typeof name === 'string') {
                    if (this.istBekanntesZertifikat(name)) {
                      vb = {
                        value: {value: name},
                      };
                    } else {
                      vb = {
                        value: {alternative_value: name},
                      };
                    }
                  } else {
                    if (this.istBekanntesZertifikat(name.name)) {
                      vb = {
                        value: {value: name.name},
                      };
                    } else {
                      vb = {
                        value: {alternative_value: name.name},
                      };
                    }
                  }
                  if (el[1]) {
                    vb.value_second = {value: el[1]};
                  }
                  value.push(vb);
                }
              } else {
                value = control.value;
              }
              this.benutzerApi.updateUserdata(this.extradataInstances[controlName], value, byValue).subscribe(r => {
                control.markAsPristine();
                control.markAsUntouched();
                subscriber.next({controlName, control});
                subscriber.complete();
              }, (error: { error: string | string[] }) => {
                if (Array.isArray(error.error)) {
                  subscriber.next({controlName, control, errors: error.error});
                } else {
                  subscriber.next({controlName, control, errors: [error.error]});
                }
                subscriber.complete();
              });
            }
          } else {
            /* send create */
            let value: any = null;
            let byValue = false;
            if (controlName === 'Sanakey/Verbandsmitgliedschaften') {
              byValue = true;
              value = [];
              for (const el of control.value) {
                const name = el[0];
                let vb: any;
                if (typeof name === 'string') {
                  if (this.istBekannterVerband(name)) {
                    vb = {
                      value: {value: name},
                    };
                  } else {
                    vb = {
                      value: {alternative_value: name},
                    };
                  }
                } else {
                  if (this.istBekannterVerband(name.name)) {
                    vb = {
                      value: {value: name.name},
                    };
                  } else {
                    vb = {
                      value: {alternative_value: name.name},
                    };
                  }
                }
                if (el[1]) {
                  vb.value_second = {value: el[1]};
                }
                value.push(vb);
              }
            } else if (controlName === 'Sanakey/Zertifikate') {
              byValue = true;
              value = [];
              for (const el of control.value) {
                const name = el[0];
                let vb: any;
                if (typeof name === 'string') {
                  if (this.istBekanntesZertifikat(name)) {
                    vb = {
                      value: {value: name},
                    };
                  } else {
                    vb = {
                      value: {alternative_value: name},
                    };
                  }
                } else {
                  if (this.istBekanntesZertifikat(name.name)) {
                    vb = {
                      value: {value: name.name},
                    };
                  } else {
                    vb = {
                      value: {alternative_value: name.name},
                    };
                  }
                }
                if (el[1]) {
                  vb.value_second = {
                    value: {
                      value: el[1],
                    },
                    value_second: {
                      value: 'Test Hinweis'
                    },
                  };
                }
                value.push(vb);
              }
            } else {
              value = control.value;
            }
            this.benutzerApi.createUserdata(this.extradata[controlName], value, byValue).subscribe(r => {
              control.markAsPristine();
              control.markAsUntouched();
              this.extradataInstances[controlName] = r;
              subscriber.next({controlName, control});
              subscriber.complete();
            }, (error: { error: string | string[] }) => {
              if (Array.isArray(error.error)) {
                subscriber.next({controlName, control, errors: error.error});
              } else {
                subscriber.next({controlName, control, errors: [error.error]});
              }
              subscriber.complete();
            });
          }
        }).toPromise());
      }
    }

    if (!operations.length) {
      this.toastService.show('Keine Änderung', 'Ihre Änderungen an den Stammdaten sind nicht gültig', 'info');
      this.addEmptyVerbandsRow();
      this.addEmptyZertifikatRow();
      return;
    }

    this.processingAnyData = true;

    Promise.all(operations).then((r: UpdateResult[]) => {
      for (const ur of r) {
        if (ur.errors?.length) {
          this.saveError.emit(this);
          this.ignoreOnUpdate.push(ur.controlName);
          try {
            ur.control.setValidators([ur.control.validator, notEquals(ur.control.value)]);
          } catch (e) {
            continue;
          }
          if (!this.validatorMessages[ur.controlName]) {
            this.validatorMessages[ur.controlName] = {};
          }
          this.validatorMessages[ur.controlName].notEquals = ur.errors[0];
          // TODO: bessere Fehler Darstellung; Verbandsmitgliedschaften können z.B. teilweise nicht gelöscht werden
        } else {
          this.store.dispatch(new VVFixFehlerAction());
          this.saved.emit(this);
        }
        ur.control.updateValueAndValidity();
      }

      afterHttpAction();
    }, reason => {
      console.error(reason);
      this.toastService.show('Bei der Speicherung ist ein Fehler aufgetreten',
        'Bitte laden die Sie Seite neu', 'warning');
    });
  }

  addEmptyVerbandsRow(addSecondEmpty = false) {
    this.stammdatenForm.updateValueAndValidity();
    if (!this.stammdatenForm.controls['Sanakey/Verbandsmitgliedschaften']) {
      return;
    }
    const arr = (this.stammdatenForm.controls['Sanakey/Verbandsmitgliedschaften'] as FormArray).controls;

    let add = false;
    if (addSecondEmpty) {
      if (arr.length === 1) {
        add = true;
      } else {
        const v1 = (arr[arr.length - 1] as FormArray).controls[0].value;
        const v2 = (arr[arr.length - 2] as FormArray).controls[0].value;

        if (!v1 && !v2) {
          return;
        }
        add = true;
      }
    }
    if (!arr.length || add || (arr[arr.length - 1] as FormArray).controls[0].value) {
      arr.push(this.generateNewVerbandRow());
    }
  }

  deleteVerbandEintrag($event: MouseEvent, i: number) {
    const vbControl = this.stammdatenForm.controls['Sanakey/Verbandsmitgliedschaften'] as FormArray;
    const deletedControl = vbControl.controls.splice(i, 1)[0];
    vbControl.markAsDirty();
    this.stammdatenForm.updateValueAndValidity();
  }

  private generateNewVerbandRow() {
    return new FormArrayWithEdi([
      new FormControlWithHintsAndEdi(this.verbaende, ''),
      new FormControlWithHintsAndEdi(this.verbaende, '')
    ]);
  }

  /*
   * Zertifikate
   * */
  addEmptyZertifikatRow(addSecondEmpty = false) {
    this.stammdatenForm.updateValueAndValidity();
    if (!this.stammdatenForm.controls['Sanakey/Zertifikate']) {
      return;
    }
    const arr = (this.stammdatenForm.controls['Sanakey/Zertifikate'] as FormArray).controls;

    let add = false;
    if (addSecondEmpty) {
      if (arr.length === 1) {
        add = true;
      } else {
        const v1 = (arr[arr.length - 1] as FormArray).controls[0].value;
        const v2 = (arr[arr.length - 2] as FormArray).controls[0].value;

        if (!v1 && !v2) {
          return;
        }
        add = true;
      }
    }
    if (!arr.length || add || (arr[arr.length - 1] as FormArray).controls[0].value) {
      arr.push(this.generateNewZertifikatRow());
    }
  }

  deleteZertifikatEintrag($event: MouseEvent, i: number) {
    const vbControl = this.stammdatenForm.controls['Sanakey/Zertifikate'] as FormArray;
    const deletedControl = vbControl.controls.splice(i, 1)[0];
    vbControl.markAsDirty();
    this.stammdatenForm.updateValueAndValidity();
  }

  private generateNewZertifikatRow() {
    return new FormArrayWithEdi([
      new FormControlWithHintsAndEdi(this.zertifikatstypen, ''),  // zert typ
      new FormArray([
        new FormControl(), // zert file
        new FormControl(), // zert hinweis
      ]),
    ]);
  }

  private istBekannterVerband(name: string) {
    return this.verbaende.find((value => value.name === name));
  }

  private istBekanntesZertifikat(name: string) {
    return this.zertifikatstypen.find((value => value.name === name));
  }

  deleteDevice(device: Device) {
    const modalRef = this.popupService.open(DeleteDevicePopupComponent,
      {
        size: 'lg',
        backdrop: 'static',
        keyboard: false
      });
    const modalRefInstance = modalRef.componentInstance as DeleteDevicePopupComponent;
    modalRefInstance.device = device;

    modalRef.result.then(value => {
      if (value && value.deleteConfirmed) {
        this.benutzerApi.deleteDevice(value.device as Device).subscribe(value1 => {
          this.toastService.show('Gerät gelöscht',
            'Das Gerät wurde gelöscht und kann ab sofort nicht mehr benutzt werden',
            'success');
          this.refreshDevices();
        }, error => {
          this.toastService.show('Gerät nicht gelöscht',
            'Das Gerät konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut',
            'warning');
          this.refreshDevices();
        });
      }
    });
  }

  private refreshDevices() {
    this.benutzerApi.loadAuthDevices().subscribe(value => {
      this.authDevices = value;
    });
  }

  setFileValue(control: any, $event: Event) {
    console.log(control);
    const files = [];
    for (const oFile of ($event.target as any).files) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (typeof event.target.result === 'string') {
          files.push(event.target.result);
        }
      };
      reader.readAsDataURL(oFile);
    }

    control.setValue(files);
  }
}
