<div *ngIf="loading">
  Lade...
</div>
<san-stepper #stepperComponent [showSteps]="false"
             [hideBottomButtons]="false"
             (pageChanged)="calculateValidity()"
             (leftButtonClicked)="leftButtonClicked($event)" (rightButtonClicked)="rightButtonClicked($event)"
             [leftButtonDisabled]="leftButtonDisabled" [rightButtonDisabled]="rightButtonDisabled"
             [leftButtonText]="leftButtonText" [rightButtonText]="rightButtonText">
  <san-step>
    <p>
      Sehr geehrte{{anrede}},
    </p>
    <p>
      wir haben unsere
      <a [href]="environment.nutzungsbedingungen" target="_blank">
        Nutzungsbedingungen
      </a> und
      <a [href]="environment.nutzungsbedingungen" target="_blank">
        Datenschutzbestimmungen
      </a> aktualisiert.
    </p>
    <p>
      Bitte stimmen Sie diesen zu, bevor Sie das Sanakey-Portal weiter nutzen.
    </p>
    <p>
      Vielen Dank!
    </p>
    <div>
      <label>
        <input type="checkbox" [(ngModel)]="nutzungAkzeptiert" (ngModelChange)="calculateValidity()"/>
        Ich stimme den <a [href]="environment.nutzungsbedingungen" target="_blank">Nutzungsbedingungen</a>
        zu.
      </label>
    </div>
    <div>
      <label>
        <input type="checkbox" [(ngModel)]="datenschutzAkzeptiert" (ngModelChange)="calculateValidity()"/>
        Ich stimme den <a [href]="environment.datenschutzbestimmungen" target="_blank">Datenschutzbestimmungen</a>
        zu.
      </label>
    </div>
    <div>
      Für Rückfragen stehen wir gern zur Verfügung:
      <a href="mailto:service@sanakey-portal.de">service@sanakey-portal.de</a>
    </div>
  </san-step>
  <san-step>
    <h4>
      Achtung:
    </h4>
    <p>
      Wenn Sie die Datenschutz- und Nutzungsbedingungen nicht akzeptieren, können Sie das Sanakey-Portal leider nicht
      weiter
      nutzen. Bei Fragen wenden Sie sich bitte an service@sanakey-portal.de.
    </p>
  </san-step>
</san-stepper>
