<div class="row">
  <div class="col-12 col-lg-6 col-xl-4 picture mt-4" *ngFor="let picture of pictures"
       (click)="picture.isActive = !picture.isActive" [ngClass]="{'active': picture.isActive}">
    <div class="pic" [ngStyle]="{'background-image': 'url(' + picture.imageUrl + ')'}" ></div>
    <div class="text w-100 h-100 position-relative">
      <div class="title anim m-0 p-2" [innerHTML]="picture.title"></div>
      <div class="desc anim m-0 p-2 text-center" [innerHTML]="picture.description"></div>
    </div>
  </div>
</div>
