<ng-template #navBtn
             let-icon="icon"
             let-svgIcon="svgIcon"
             let-name="name"
             let-action="action"
             let-link="link"
             let-submenuUUID="submenuUUID"
             let-borderTop="borderTop">
  <div class="nav-btn p-2 noselect" (click)="(action) ? action() : noop()"
       [routerLink]="link" routerLinkActive="active" [ngClass]="{'border-top':borderTop}">
    <fa-icon *ngIf="icon" [icon]="icon" [fixedWidth]="true" class="nav-icon"></fa-icon>
    <svg-icon *ngIf="svgIcon" name="140a" svgClass="icon x1-5 sanakey-color fill"></svg-icon>
    <span class="ms-3">{{name}}</span>
  </div>
  <ul *ngIf="submenuUUID && productsRoutes[submenuUUID]?.length" class="list-unstyled produktUnterMenu"
      [class.active]="router.isActive(link, false)">
    <li *ngFor="let untermenu of productsRoutes[submenuUUID]"
        class="border-left nav-btn p-2 noselect"
        [routerLink]="[link].concat(untermenu.url)"
        routerLinkActive="active">
      {{untermenu.label}}
    </li>
  </ul>
</ng-template>

<div class="backdrop" [ngClass]="{active:hover || active}" (click)="active = false;"></div>
<div class="sidebar h-100" [ngClass]="{active: active}" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseExit()">
  <div class="widthPreserver h-100 d-flex flex-column bg-light">
    <ng-container
      *ngTemplateOutlet="navBtn; context:{icon: meinSanakeyPortalIcon, name:'Mein Sanakey-Portal', link: '/dashboard'}"></ng-container>
    <ng-container
      *ngTemplateOutlet="navBtn; context:{icon: meineStammdaten, name:'Meine Stammdaten', link: '/stammdaten'}"></ng-container>
    <ng-container
      *ngTemplateOutlet="navBtn; context:{icon: dokumenteVertraegeIcon, name:'Dokumente & Verträge', link: '/dokumente-vertraege'}"></ng-container>

    <div class="produkt-liste flex-fill">
      <!-- Produkt Liste -->
      <div class="separator text-center small p-2">
        <div class="dummy-icon"></div>
        <span class="ms-3">Produkte<span>&nbsp; <fa-icon *ngIf="productsInfo.state === LoadingState.Loading"
                                                         [icon]="spinnerIcon" animation="spin-pulse"></fa-icon></span></span>
      </div>
      <div *ngIf="!productsInfo.allRawProducts.length" class="text-center small">- keine aktiven Produkte -</div>

      <ng-container *ngFor="let product of productsInfo.activeProducts">
        <ng-container
          *ngTemplateOutlet="navBtn; context:{
        icon: productIcon,
        name: product.name,
        link: '/' + $any((mapping[product.uuid]) ? mapping[product.uuid] : product.uuid).url,
        submenuUUID: product.uuid}"></ng-container>
      </ng-container>
      <!-- Produkt Liste Ende -->
    </div>

    <div class="nav-btn p-2 noselect" routerLink="/kontakt-und-hilfe" routerLinkActive="active">
      <fa-icon [icon]="helpIcon" [fixedWidth]="true" class="nav-icon"></fa-icon>
      <span class="ms-3">Kontakt & Hilfe</span>
    </div>
    <ul class="list-unstyled produktUnterMenu" [class.active]="router.isActive('/kontakt-und-hilfe', false)">
      <li class="border-left nav-btn p-2 noselect"
          routerLink="/kontakt-und-hilfe/kontakt"
          routerLinkActive="active">
        Kontakt
      </li>
    </ul>

    <div class="nav-btn p-2 noselect" (click)="abmeldenClicked()"
         routerLink="/abmelden" routerLinkActive="active">
      <div class="icons" [ngClass]="{'show-second': abmeldungLaeuft}">
        <fa-icon [icon]="abmeldenIcon" [fixedWidth]="true" class="icon-default nav-icon"></fa-icon>
        <fa-icon [icon]="spinnerIcon" animation="spin-pulse" [fixedWidth]="true" class="icon-second nav-icon"></fa-icon>
      </div>
      <span class="ms-3">Abmelden</span>
    </div>
  </div>
</div>
