import {AfterContentInit, AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {BenutzermanagementAPIService} from '../../api/benutzermanagement/benutzermanagement-api.service';
import {Router} from '@angular/router';
import {AppToastService} from '../../toast/app-toast.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from 'src/environments/environment';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'san-hybrid-drgabrechnung-flyer-popup',
  templateUrl: './hybrid-drgabrechnung-flyer-popup.component.html',
  styleUrls: ['./hybrid-drgabrechnung-flyer-popup.component.scss']
})
export class HybridDRGAbrechnungFlyerPopupComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {

  constructor(private store: Store, private bmClient: BenutzermanagementAPIService, private router: Router,
              private toastService: AppToastService, private activeModal: NgbActiveModal) {
  }

  static ID = 'Hybrid DRG Abrechnung Flyer';

  // @HostBinding('class.p-3') pad = true;
  environment = environment;

  @ViewChild('downloadLink') downloadLink;

  loading = true;

  readonly faSpinner = faSpinner;

  windowWidth = -1;

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
  }

  ngAfterContentInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(this.init.bind(this));
  }

  init() {
    window.addEventListener('__SAN_POPUP_HDRG_AKTIVIEREN_CLICKED', evt => {
      this.activeModal.dismiss();
      this.bmClient.setPopupViewed(HybridDRGAbrechnungFlyerPopupComponent.ID, false).subscribe();
      this.router.navigate(['hdrg']);
    }, false);
    window.addEventListener('__SAN_POPUP_HDRG_DOWNLOAD_FLYER_CLICKED', evt => {
      this.activeModal.dismiss();
      this.bmClient.setPopupViewed(HybridDRGAbrechnungFlyerPopupComponent.ID, false).subscribe();
      this.downloadLink.nativeElement.click();
    }, false);
    window.addEventListener('__SAN_POPUP_CLOSE_CLICKED', evt => {
      this.activeModal.dismiss();
      this.bmClient.setPopupViewed(HybridDRGAbrechnungFlyerPopupComponent.ID, true).subscribe();
    }, false);
    window.addEventListener('__SAN_POPUP_HDRG_LOADED', evt => {
      this.loading = false;
    }, false);
  }

}
