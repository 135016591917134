import {Action, ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';

export enum NavigationActionTypes {
  ProduktRoutesAvailable = '[Navigation] Neue Produkt Navigationen verfuegbar',
}

export interface AvailableRoute {
  url: string[];
  label: string;
}

export class ProduktRoutesAvailableAction implements Action {
  readonly type = NavigationActionTypes.ProduktRoutesAvailable;

  constructor(public uuid: string, public config: AvailableRoute[]) {
  }
}

export type NavigationActions = ProduktRoutesAvailableAction;

export interface ProductRoutesState {
  [key: string]: AvailableRoute[];
}

export const initialState: ProductRoutesState = {};

export function navigationStateReducer(state: ProductRoutesState = initialState,
                                       action: NavigationActions): ProductRoutesState {
  switch (action.type) {
    case NavigationActionTypes.ProduktRoutesAvailable:
      const s = {...state};
      s[action.uuid] = action.config;
      return s;
    default:
      return state;
  }
}

export const navigationStateName = 'navigationFeature';

export interface NavigationReducerState {
  productRoutes: ProductRoutesState;
}

export const navigationReducers: ActionReducerMap<NavigationReducerState> = {
  productRoutes: navigationStateReducer,
};

export const getNavigationFeatureState = createFeatureSelector<NavigationReducerState>(
  navigationStateName
);
