<a href="javascript:void(0)" (click)="modalService.open(information, { size: 'xl' , centered: true, windowClass: 'xxl-modal'})">
  <svg-icon name="info" svgClass="icon x1-5 sanakey-color fill"></svg-icon>
</a>

<!-- Header Definition -->
<ng-template #modalHeaderPrint let-modal="modal" let-title="title">
  <div class="modal-header">
    <h4 class="modal-title">{{title}}&nbsp;
      <button class="btn btn-primary" aria-label="drucken" printSectionId="print-section" [useExistingCss]="true"
              ngxPrint>
        <fa-icon [icon]="printIcon"></fa-icon>
      </button>
    </h4>
    <button type="button" class="btn-close" aria-label="schließen" (click)="modal.dismiss()">
    </button>
  </div>
</ng-template>
<!-- Info Modal -->
<ng-template #information let-modal>
  <ng-container *ngTemplateOutlet="modalHeaderPrint; context: {modal:modal, title:'Kontaktmöglichkeiten'}">
  </ng-container>
  <div id="print-section" class="p-3 pb-5 bg-light">
    <h4>Sie haben Fragen? Unser Team steht Ihnen gerne zur Verfügung. Sie erreichen uns am Besten per E-Mail:</h4>
    <div class="container">
      <div class="row">
        <san-visitenkarte class="col-xl-4 col-lg-6"
                          abteilung="Allgemeine / technische Fragen zur Funktion des Sanakey-Portals®"
                          email="service@sanakey-portal.de"
                          servicezeiten="Montag bis Freitag 11 Uhr bis 13 Uhr"
                          telefonnummer="030 3100 78 999 (Servicehotline der Sanakey-Gruppe)"></san-visitenkarte>
        <san-visitenkarte class="col-xl-4 col-lg-6"
                          abteilung="Fragen zur Abrechnung / Verträgen zur besonderen Versorgung § 140a SGB V"
                          email="abrechnung@sanakey-contract.de"
                          servicezeiten="Montag bis Freitag 11 Uhr bis 13 Uhr"
                          telefonnummer="030 3100 78 999 (Servicehotline der Sanakey-Gruppe)"
                          logo="assets/logos/sanakey-contract.png"></san-visitenkarte>
        <san-visitenkarte class="col-xl-4 col-lg-6"
                          abteilung="Fragen zum Onkogister® / Fragen zu DIFAscience®"
                          organisation="DIFA | Deutsches Institut für Fachärztliche Versorgungsforschung GmbH"
                          email="onkogister@difa-vf.de"
                          secondEmail="difascience@difa-vf.de"
                          servicezeiten="Montag bis Freitag 11 Uhr bis 13 Uhr"
                          telefonnummer="030 3100 78 999 (Servicehotline der Sanakey-Gruppe)"
                          logo="assets/logos/difa.png"></san-visitenkarte>
      </div>
    </div>
    <h4 class="mt-3">Hilfe:</h4>
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-lg-6">
          <div class="sanakey-kachel hoverable" (click)="goToAuthenticator()">
            <strong>Einrichtung Authenticator App</strong>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 mt-2 mt-lg-0">
          <div class="sanakey-kachel hoverable" (click)="goToFAQ()">
            <strong>FAQ</strong>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 mt-2 mt-lg-2 mt-xl-0">
          <div class="sanakey-kachel hoverable" (click)="goToTeamviewerDownload()">
            <strong>Fernwartung</strong>
            <small class="ms-auto">Download nur in Absprache mit dem Service-Team.</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
