import {Component, HostBinding, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {ClientService} from "../../api/client.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'san-pin-partner',
  templateUrl: './pin-partner.component.html',
  styleUrls: ['./pin-partner.component.scss']
})
export class PinPartnerComponent implements OnInit {
  @HostBinding('class.d-block') dblock = true;

  @Input() token: string;
  @Input() wrongCredentials = false;
  @Input() sending = false;

  @Output() anmeldenClicked = new EventEmitter<{ pin: string }>();

  hrefDatenschutzbestimmungen = environment.datenschutzbestimmungen;
  hrefNutzungsbedingungen = environment.nutzungsbedingungen;

  spinnerIcon = faSpinner;

  akzeptiertBedingungen = false;
  code: string = "";


  constructor() {
  }

  ngOnInit(): void {
  }

  onCodeChanged($event: string) {
    this.code = $event;
  }

  onCodeCompleted($event: string) {
    this.code = $event;
  }

  onAnmeldenClicked() {
    if (!this.code || this.code.length !== 5) return;
    this.anmeldenClicked.emit({pin: this.code})
  }
}
