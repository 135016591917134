<div class="modal-body">
  <h2>
    Versorgungsforschung von Fachärzten für Fachärzte
    <button type="button" class="close p-2" aria-label="schließen"
            (click)="alsWeggeklicktMarkieren()">
      <span aria-hidden="true">&times;</span>
    </button>
  </h2>
  <p class="p-3 text-center">
    Webinar im Rahmen des 7. SpiFa-Fachärztetages: Prof. Dr. Peter-Jürgen Goebell (BvDU) und Dr. Kerstin Bode-Greuel
    (DIFA) stellen, am 16. April, ab 13.45 Uhr DIFA Science, den vielfältigen Nutzen fachärzteeigener
    Versorgungsforschung
    vor. Nehmen Sie teil und stellen Sie Ihre Fragen live!
  </p>
  <p class="p-3 text-center">
    <span class="btn btn-primary" (click)="alsGelesenMarkieren();link.click()">Anmeldung</span>
    <a class="text-hide" href="https://fachaerztetag.spifa.de/anmeldung/ticket-webinar-difa/" #link
       target="_blank">link</a>
  </p>
  <div class="pics">
    <img class="pic" src="assets/popups/difa-fachaerztetag-2021/Duo.jpg">
  </div>
</div>
