import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {BenutzermanagementAPIService} from "../../api/benutzermanagement/benutzermanagement-api.service";
import {AppToastService} from "../../toast/app-toast.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'san-difa-fachaerztetag2021',
  templateUrl: './difa-fachaerztetag2021.component.html',
  styleUrls: ['./difa-fachaerztetag2021.component.scss']
})
export class DifaFachaerztetag2021Component implements OnInit {

  static ID = "DIFA Fachärztetag 2021";

  constructor(private activeModal: NgbActiveModal, private bmClient: BenutzermanagementAPIService,
              private toastService: AppToastService, private http$: HttpClient) { }

  ngOnInit(): void {
  }

  alsGelesenMarkieren() {
    this.activeModal.dismiss();
    this.bmClient.setPopupViewed(DifaFachaerztetag2021Component.ID, false).subscribe();
  }

  alsWeggeklicktMarkieren() {
    this.activeModal.dismiss();
    this.bmClient.setPopupViewed(DifaFachaerztetag2021Component.ID, true).subscribe();
  }
}
