<div class="text-center">
  <h5>Sie haben die Teilnahme widerrufen.</h5>
  <p>
    Sie haben Ihre Teilnahme am Versorgungsvertrag erfolgreich widerrufen. <br/>
    Ihr behandelnder Arzt und Ihre Krankenkasse werden über den Widerruf informiert.
  </p>
  <div>
    <button class="btn btn-primary" [disabled]="!teilnahmeerklaerung" (click)="downloadTneClicked.emit()">
      Widerspruch herunterladen<span>&nbsp;<fa-icon *ngIf="!teilnahmeerklaerung" [icon]="loadingIcon"
                                                    animation="spin-pulse"></fa-icon></span>
    </button>
    <br class="d-md-none"/>
    <button class="btn btn-primary ms-md-3 mt-3 mt-md-0" (click)="abmeldenClicked.emit()">
      Abmelden
    </button>
  </div>
</div>
