<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Diese Webseite verwendet Cookies</h4>
</div>
<div class="modal-body">
  <p>
    Wir nutzen Cookies auf unserer Website. Einige von ihnen sind essenziell und technisch notwendig, während andere uns
    helfen, diese Website für Sie zu verbessern.
  </p>
  <div class="container">
    <div class="row">
      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="required" disabled>&nbsp;technisch notwendig
        </label>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="tracking">&nbsp;Statistiken
        </label>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label>
          <input type="checkbox" [(ngModel)]="auswahl_merken">&nbsp;Auswahl merken
        </label>
      </div>
    </div>
  </div>
 </div>
<div class="modal-footer flex-row justify-content-between">
  <button type="button" class="btn btn-outline" (click)="auswahlBestaetigen()">Auswahl bestätigen</button>
<!--  <button type="button" class="btn btn-primary" (click)="alleAkzeptieren(false)">Alle akzeptieren</button>-->
  <button type="button" class="btn btn-primary" (click)="alleAkzeptieren(true)">Alle akzeptieren & merken</button>
</div>
<div class="modal-footer flex-row justify-content-center">
  <a href="https://www.sanakey.de/sanakey-portal/impressum" target="_blank">Impressum</a>
  <a href="https://www.sanakey.de/sanakey-portal/datenschutz" target="_blank">Datenschutzbestimmungen</a>
</div>
