import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {ArztDatenResponse, ClientService, PreviewResponse} from '../../api/client.service';

@Component({
  selector: 'san-infotext-arztdaten-qrtne',
  templateUrl: './infotext-arztdaten-qrtne.component.html',
  styleUrls: ['./infotext-arztdaten-qrtne.component.scss']
})
export class InfotextArztdatenQRTNEComponent implements OnInit {
  sending: any;
  spinnerIcon = faSpinner;

  @Input() uuid: string;
  @Input() versorgungsVertragID: number;
  @Input() state: ArztDatenResponse;
  @Input() krankenkassen: {name: string, id: number}[];

  @Output() weiterClicked = new EventEmitter();

  loading = true;
  error = false;

  constructor(private vvApi: ClientService) {
  }

  ngOnInit(): void {
  }

  onWeiterClicked() {
    this.weiterClicked.emit();
  }

  ladeArztDaten() {
    // only once
    if (this.state && this.state.arzt) {
      return;
    }
    this.loading = true;
    this.vvApi.getArztDaten(this.uuid, this.versorgungsVertragID).subscribe((response) => {
      this.state.arzt = response.arzt;
      this.state.vertrag = response.vertrag;
      for (const kk of response.krankenkassen) {
        this.krankenkassen.push(kk);
      }
      this.loading = false;
    }, error1 => {
      this.error = true;
      this.loading = false;
    });
  }
}
