<ng-template #rt let-r="result" let-t="term">
  <ngb-highlight [result]="r.name" [term]="t" class="text-wrap"></ngb-highlight>
</ng-template>

<div class="container h-100">
  <!-- mobile view -->
  <div class="row row-cols-1">
    <div class="col">
      <h5>Bitte erfassen Sie zuerst Ihre persönlichen Daten</h5>

      <form class="p-3" [formGroup]="baseDataForm" (ngSubmit)="checkReady()">
        <div class="input-group">
          <label class="small w-100" title="Ihre E-Mail Adresse">
            E-Mail Adresse*
          </label>
          <input type="text" class="form-control" placeholder="E-Mail Adresse"
                 formControlName="email"
                 aria-label="E-Mail Adresse"
                 tabindex="1"
                 autocomplete="off">
        </div>
        <san-form-input-error
          [visible]="!baseDataForm.controls.email.pristine && baseDataForm.controls.email.errors && baseDataForm.controls.email.errors.required"
          message="Bitte geben Sie Ihre E-Mail Adresse an"></san-form-input-error>
        <san-form-input-error
          [visible]="!baseDataForm.controls.email.pristine && baseDataForm.controls.email.errors && baseDataForm.controls.email.errors.email"
          message="Die angegebene E-Mail Adresse ist nicht gültig"></san-form-input-error>
        <div class="input-group mt-3">
          <label class="small w-100" title="Versichertennummer bei Ihrer Krankenkasse; erforderlich"
                 (click)="tooltip.show()">
            Versichertennummer*
            <san-tooltip
              #tooltip
              text="Ihre Versichertennummer finden Sie z.B. auf der Vorderseite Ihrer Krankenversichertenkarte. Diese ist 10-stellig und beginnt mit einem Großbuchstaben gefolgt von 9 Ziffern."></san-tooltip>
          </label>
          <input type="text" class="form-control" placeholder="Versichertennummer"
                 formControlName="versicherten_nummer"
                 aria-label="Versichertennummer"
                 tabindex="1"
                 autocomplete="off"
                 maxlength="10"
                 (input)="baseDataForm.patchValue({versicherten_nummer: baseDataForm.get('versicherten_nummer').value?.toUpperCase()})">
        </div>
        <san-form-input-error
          [visible]="!baseDataForm.controls.versicherten_nummer.pristine && baseDataForm.controls.versicherten_nummer.errors && baseDataForm.controls.versicherten_nummer.errors.required"
          message="Bitte geben Sie Ihre Versichertennummer an"></san-form-input-error>
        <san-form-input-error
          [visible]="!baseDataForm.controls.versicherten_nummer.pristine && baseDataForm.controls.versicherten_nummer.errors && baseDataForm.controls.versicherten_nummer.errors.gueltigeKVNR"
          message="Die angegebene Versichertennummer ist nicht gültig"></san-form-input-error>

        <div class="input-group mt-3">
          <label class="small w-100" title="Krankenkasse">
            Krankenkasse*
            <san-tooltip
              #tooltip
              text="Ist Ihre Krankenkasse hier nicht aufgeführt? Bitte kontaktieren Sie Ihre Arztpraxis, eventuell nimmt die Krankenkasse (noch) nicht am Vertrag teil oder Ihre Arztpraxis hat Ihre Krankenkasse noch nicht aktiviert."></san-tooltip>
          </label>
          <input type="text"
                 class="form-control"
                 placeholder="Krankenkasse"
                 aria-label="Krankenkasse"
                 tabindex="1"
                 autocomplete="off"
                 [ngbTypeahead]="krankenkasseAuswahlConfig.titleAuswahl"
                 [inputFormatter]="formatter"
                 [resultTemplate]="rt"
                 (focus)="krankenkasseAuswahlConfig.titleFocused$.next($any($event).target.name)"
                 formControlName="krankenkasse"
                 [editable]="false"
          >
        </div>
        <san-form-input-error
          [visible]="!baseDataForm.controls.krankenkasse.pristine && baseDataForm.controls.krankenkasse.errors && baseDataForm.controls.krankenkasse.errors.required"
          message="Bitte wählen Sie Ihre Krankenkasse aus der Liste"></san-form-input-error>
      </form>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col text-center">
      <button class="btn btn-primary m-lg-5" (click)="zurueck.emit()">Zurück</button>
    </div>
    <div class="col text-center">
      <button class="btn btn-primary m-lg-5" (click)="checkReady()">Weiter</button>
    </div>
  </div>
</div>
