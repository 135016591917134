// import {config as difaConfig} from '../difa/module.config';
import {config as vvConfig} from '../versorgungsvertraege/module.config';
import {config as doksConfig} from '../dokumente-und-vertraege/module.config';
import {config as lexonlineConfig} from '../lex-online/module.config';
import {config as hdrgConfig} from '../hdrg/module.config';
import {config as arztListeConfig} from '../arzt-liste/module.config';

export const mapping: {
  [key: string]: {
    url: string,
    activationAction?: () => void,
  }
} = {};

// mapping[difaConfig.UUID] = {url: 'difa'};
mapping[vvConfig.UUID] = {url: 'versorgungsvertraege'};
mapping[doksConfig.UUID] = {url: 'dokumente-vertraege'};
mapping[lexonlineConfig.UUID] = {url: 'lex-online'};
mapping[hdrgConfig.UUID] = {url: 'hdrg'};
mapping[arztListeConfig.UUID] = {url: 'arzt-liste'};
mapping.stammdaten = {url: 'stammdaten'};
mapping.kontaktundhilfe = {url: 'kontakt-und-hilfe'};
