import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PreviewResponse} from '../../api/client.service';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {PSECredentials} from '../../api/models';

@Component({
  selector: 'san-widerruf',
  templateUrl: './widerruf.component.html',
  styleUrls: ['./widerruf.component.scss']
})
export class WiderrufComponent implements OnInit {
  @Input() state: PreviewResponse;
  @Input() sending = false;
  @Input() credentials: PSECredentials;
  @Input() teilnahmeerklaerung: boolean;

  @Output() bestaetigenClicked = new EventEmitter();
  @Output() abmeldenClicked = new EventEmitter();
  @Output() downloadTneClicked = new EventEmitter();

  spinnerIcon = faSpinner;

  constructor() {
  }

  ngOnInit(): void {
  }

  onBestaetigenClicked() {
    if (this.sending) { return; }
    this.bestaetigenClicked.emit();
  }

}
