import {
  AfterContentInit, AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentFactoryResolver, ComponentRef,
  ContentChildren, EventEmitter, HostBinding, HostListener, Input, OnChanges,
  OnInit, Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {StepComponent} from './step/step.component';
import {Router} from '@angular/router';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {animate, sequence, state, style, transition, trigger} from '@angular/animations';

export interface PageChangedEvent {
  first: boolean;
  last: boolean;
  indexBefore: number;
  indexAfter: number;
  totalPages: number;
}

export interface ClickEvent {
  first: boolean;
  last: boolean;
  buttonText: string;
  currentIndex: number;
  totalPages: number;
}

@Component({
  selector: 'san-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  animations: [
    trigger('vis', [
      // ...
      state('hidden', style({
        transform: 'scale(0)',
      })),
      state('visible', style({
        transform: 'scale(1)',
      })),
      transition('visible => hidden', [
        sequence([
          animate('0.4s ease-in-out'),
        ]),
      ]),
      transition('hidden => visible', [
        sequence([
          animate('0.4s ease-in-out')
        ]),
      ]),
    ]),
    trigger('mr', [
      // ...
      state('hidden', style({
        'margin-left': 0,
      })),
      state('visible', style({
        'margin-left': '28px',
      })),
      transition('visible => hidden', [
        sequence([
          animate('0.4s ease-in-out'),
        ]),
      ]),
      transition('hidden => visible', [
        sequence([
          animate('0.4s ease-in-out')
        ]),
      ]),
    ]),
  ],
})
export class StepperComponent implements OnInit, AfterContentInit {
  @Input() leftButtonText: string = undefined;
  @Input() rightButtonText: string = undefined;
  @Input() leftButtonDisabled = false;
  @Input() rightButtonDisabled = false;
  @Input() rightButtonAnimated = false;
  @Input() autoFontSize = true;
  @Input() autoBubbleSize = true;
  @Input() showSteps = true;
  @Input() hideBottomButtons = false;

  @Input() @HostBinding('class.fullHeight') fullHeight = true;

  @Output() pageChanged = new EventEmitter<PageChangedEvent>();
  @Output() leftButtonClicked = new EventEmitter<ClickEvent>();
  @Output() rightButtonClicked = new EventEmitter<ClickEvent>();

  @ContentChildren(StepComponent) inputSteps: QueryList<StepComponent>;
  @ViewChild('dynamic') viewContainerRef;

  iconSpinner = faSpinner;
  public steps: StepComponent[];
  nSteps = 0;
  currentStepIndex = 0;
  currentStep: StepComponent;
  visibility: boolean[] = [];
  private switching = false;

  constructor(private router: Router, private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.changeDetector.detectChanges();
  }

  ngAfterContentInit(): void {
    this.steps = this.inputSteps.toArray();
    this.nSteps = this.steps.length;
    this.visibility = new Array(this.nSteps).fill(false);
    this.visibility[0] = true;
    this.pageChanged.emit({first: true, indexAfter: 0, indexBefore: 0, last: false, totalPages: this.nSteps});
    this.currentStep = this.steps[0];
    this.steps[0].entering.emit();
  }

  goToStep(i: number, force = false) {
    if (!force && this.switching) {
      return;
    }
    if (!force && this.steps[i].disabled) {
      return;
    }
    if (i === this.currentStepIndex) {
      return;
    }

    this.switching = true;
    this.visibility[i] = true;

    let currentStepIndex = this.currentStepIndex;
    let targetStepIndex = i;

    // aktiviere alle steps
    // alles in steps[currentStepIndex, targetStepIndex] auf visibility: true setzen
    while (currentStepIndex !== targetStepIndex) {
      this.visibility[currentStepIndex] = true;
      if (currentStepIndex < targetStepIndex) {
        // weiter
        currentStepIndex++;
      } else {
        // zurück
        currentStepIndex--;
      }
    }

    currentStepIndex = this.currentStepIndex;
    targetStepIndex = i;

    setTimeout(() => {
      // alles in steps[currentStepIndex, targetStepIndex) auf visibility: false setzen
      // oder anders rum, wenn runter
      while (currentStepIndex !== targetStepIndex) {
        // Prüfung, ob: in der zwischenzeit wurde der aktuelle step wieder aktiviert
        if (this.currentStepIndex !== currentStepIndex) {
          this.visibility[currentStepIndex] = false;
        }
        if (currentStepIndex < targetStepIndex) {
          // weiter
          currentStepIndex++;
        } else {
          // zurück
          currentStepIndex--;
        }
      }
      this.switching = false;
    }, 400);

    this.steps[this.currentStepIndex].leaving.emit();
    this.pageChanged.emit({
      first: i === 0,
      indexAfter: i,
      indexBefore: this.currentStepIndex,
      last: i === this.nSteps - 1,
      totalPages: this.nSteps
    });
    this.currentStepIndex = i;
    this.currentStep = this.steps[i];
    this.steps[i].entering.emit();
    this.changeDetector.detectChanges();
  }

  weiter(force = false) {
    const nextStep = Math.min(this.currentStepIndex + 1, this.nSteps - 1);
    this.goToStep(nextStep, force);
  }

  zurueck() {
    const nextStep = Math.max(0, this.currentStepIndex - 1);
    this.goToStep(nextStep);
  }

  onLeftButtonClicked() {
    this.leftButtonClicked.emit({
      buttonText: this.leftButtonText,
      currentIndex: this.currentStepIndex,
      first: this.currentStepIndex === 0,
      last: this.currentStepIndex === this.nSteps - 1,
      totalPages: this.nSteps
    });
  }

  onRightButtonClicked() {
    this.rightButtonClicked.emit({
      buttonText: this.rightButtonText,
      currentIndex: this.currentStepIndex,
      first: this.currentStepIndex === 0,
      last: this.currentStepIndex === this.nSteps - 1,
      totalPages: this.nSteps
    });
  }

  goToStepViaComponent(step: StepComponent, force = false) {
    for (let i = 0; i < this.steps.length; i++) {
      if (this.steps[i] === step) {
        this.goToStep(i, force);
        return;
      }
    }
    console.warn('Ziel-Komponente nicht gefunden');
  }

  next(force: boolean = false) {
    if (this.currentStepIndex >= this.steps.length - 1) {
      return;
    }
    this.goToStep(this.currentStepIndex + 1, force);
  }
}
