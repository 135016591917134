/* tslint:disable:variable-name */
import {PaginatedResult} from '../../shared/models.includes';

export type TfaMethod = 'EMail' | 'TOTP';

export interface AuthDeviceData {
  username: string;
  password: string;
}

export interface SetupDeviceData {
  username: string;
  password: string;
  method: TfaMethod;
}

export interface AuthData {
  username: string;
  password: string;
  otp_token: string;
  method: TfaMethod;
  device_name?: string;
}

export interface ValidateUsernameData {
  username: string;
}

export class CredentialsCorrectResponse {
  options: TfaMethod[];
}

export class DeviceRegistrationRequiredResponse {
  options: TfaMethod[];
}

export class AuthDeviceSetupResponse201 {
  public qrcode: string;
  // tslint:disable-next-line:variable-name
  public alt_code: string;
  public method: string;
}

export class AuthResponse {
  message: string;
  // tslint:disable-next-line:variable-name
  user_id: number;
  // tslint:disable-next-line:variable-name
  device_id: number;
  device_name: number;
  created: string;
  role: string;
  token: {
    key: string,
    id: number
  };
  username: string;
}

export interface ResetPasswordData {
  username: string;
  gRecaptchaResponse: string;
}

export enum ResetPasswordResponse {
  OK,
  DataInvalid,
  EmailAmbigious,
  ServiceUnavailable
}

export enum ResetPasswordConfirmResponse {
  OK,
  DataInvalid,
  PasswordTooWeak,
  TokenExpired,
  ServiceUnavailable,
}

export interface PasswordStrengthDataResponse {
  strength_data: {
    id: number,
    value: string,
    alternative_value: string,
    value_second: string,
    user_changeable: boolean,
    user_uuid: string,
    created: string,
    is_set: boolean,
    status: string,
    user: number,
    extradata: {
      name: string,
      namespace: string,
      display_field_name: string,
      display_help: string,
      display_required_for: string,
      display_placeholder: string,
      display_description: string,
      allow_blank: boolean,
      category: string,
      needs_manual_validation: boolean,
      type: string,
      id: number,
      serverside_validators: string
    }
  }[]
}

export class ExtraData {
  name: string;
  namespace: string;
  id: number;
  display_field_name: string;
  display_help: string;
  display_required_for: string;
  display_placeholder: string;
  display_description: string;
  allow_blank: boolean;
  category: string;
  needs_manual_validation: boolean;
  type: string;
  serverside_validators: string[];
}

export class FullExtraData extends ExtraData {
  visible: boolean;
  user_changeable: boolean;
  user_creatable: boolean;
  required: boolean;
}

export class ExtraEnum extends ExtraData {
  created: string;
  has_default: boolean;
  user_changeable_rule_version: string;
  user_changeable_rule: string;
  user_creatable_rule_version: string;
  user_creatable_rule: string;
  admin_changeable: boolean;
  is_standalone: boolean;
  visible_rule_version: string;
  visible_rule: string;
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  auto_generated: boolean;
  auto_generated_rule: string;
  auto_generated_rule_version: string;
  auto_generated_guard: string;
  auto_generated_guard_dependencies: boolean;
  strict: boolean;
  default: number;
  validation_code: any[];
  update_triggers: any[];
  auto_generated_dependencies: any[];
  enum: {
    id: number,
    name: string
    choices: {
      id: number,
      name: string
    }[]
  };
}


export interface DoctorRegistrationRequest {
  username: string;
  password: string;
  role: string;
  email: string;
  gRecaptchaResponse: string;
  accepted_agb: boolean;
  accepted_agb_version: number;
  accepted_privacy_statement: boolean;
  accepted_privacy_statement_version: number;
  firstname: string;
  lastname: string;
  lanr: string;
  bsnr: string;
  is_association_member: boolean;
  salutation: string;
  title: string;
  association_memberships: {
    name: string,
    number: string
  }[];
}


export interface DifaRegistrationRequest {
  username: string;
  password: string;
  role: string;
  email: string;
  gRecaptchaResponse: string;
  accepted_agb: boolean;
  accepted_agb_version: number;
  accepted_privacy_statement: boolean;
  accepted_privacy_statement_version: number;
  firstname: string;
  lastname: string;
  lanr: string;
  bsnr: string;
  is_association_member: boolean;
  salutation: string;
  title: string;
  association_memberships: {
    name: string,
    number: string
  }[];
  // DIFA Spezifisch
  strasse: string;
  hausnummer: string;
  postleitzahl: string;
  ort: string;
  telefonnummer: string;
  faxnummer: string;
  mobilnummer?: string;
  pvs?: string;
  arzt_informations_system: string;
  akzeptierte_vertraege: string[];
  kontext: string;
  krebsregister_melder_id?: string;
  bic?: string;
  kontoinhaber: string;
  iban: string;
  krebsregister: string;
}

export class DoctorRegistrationResponse {
  check_key: string;
}

export interface RegistrationStateRequest {
  check_key: string;
}

export class FeldFehler {
  constructor(public feld_name: string, public fehler_nachrichten: string[]) {
  }
}

export class DataValidationErrorResponse {
  constructor(public fehler: FeldFehler[]) {
  }
}

export class GeneralErrorResponse {
  constructor(public message: string = 'Ein unbekannter Fehler ist aufgetreten') {
  }
}


export type RegistrationResponseTypes = DoctorRegistrationResponse | DataValidationErrorResponse | GeneralErrorResponse;

export class RegistrationStateResponse {
  register_doc_ready: boolean;
}

export class Product {
  name: string;
  is_internal_product: boolean;
  is_module: boolean;
  uuid: string;
  status: 'aktiv' | 'aktivierbar' | 'aktivierbar durch Aktion' | 'nicht aktivierbar' | 'nicht überprüfbar';
  nicht_terminierte_behebbare_regeln: {terminating: string, name: string, display_solution: string}[];
  required_shared_data: ExtraData[];
  is_static_link: boolean;
  single_sign_on_url: string;
  host: string;
}

export class Jwt {
  encoded_jwt: string;
  jwt: {
    signature: string;
    header: {
      alg: string;
      typ: string;
    };
    payload: {
      iss: string;
      aud: string;
      exp: number;
      nbf: number;
      iat: number;
      sub: string;
      on_behalf_of: string
    }
  };
}

export enum ExtradataInstanceOrdering {
  na,
}

export class ExtradataInstance {
  id: number;
  value: any;
  alternative_value: any;
  value_second: any;
  user_changeable: true;
  user_uuid: string;
  created: string;
  is_set: true;
  status: string;
  user: number;
  extradata: ExtraData;
}

export type ExtradataInstancePage = PaginatedResult<ExtradataInstance, ExtradataInstanceOrdering>;

export class BilderResponseElement {
  image: string;
  bezeichnung: string;
  link: string;
}

export class Device {
  id: number;
  name: string;
  confirmed: boolean;
}

export enum PwdChangeResponse {
  success,
  errWrongCredentials,
  errPasswordTooWeak,
  errUnknown,
}

export class KrankenkassenData {
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  name: string;
  logo: string;
}
