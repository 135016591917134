<div class="container h-100 d-flex flex-column">
  <div class="flex-grow-1" [innerHTML]="state?.text_widerruf"></div>
  <!-- both -->
  <div class="text-center">
    <button class="btn btn-primary" [disabled]="sending"
            (click)="abmeldenClicked.emit()">
      Abmelden
    </button>
    <br class="d-md-none"/>
<!--    <button class="btn btn-primary" [disabled]="!teilnahmeerklaerung" (click)="downloadTneClicked.emit()">-->
<!--      herunterladen<span>&nbsp;<fa-icon *ngIf="!teilnahmeerklaerung" [icon]="spinnerIcon"-->
<!--                                                    animation="spin-pulse"></fa-icon></span>-->
<!--    </button>-->
<!--    <br class="d-md-none"/>-->
    <button #btnBestaetigen class="btn btn-primary mt-3 mt-md-0 ms-md-3" [disabled]="sending"
            (click)="onBestaetigenClicked()">
      Teilnahme widerrufen<span *ngIf="sending">&nbsp;<fa-icon [icon]="spinnerIcon" animation="spin-pulse"></fa-icon></span>
    </button>
  </div>
</div>
