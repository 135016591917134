import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';

export const ROUTE_DATA_BREADCRUMB = 'breadcrumb';

export interface Breadcrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'san-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  private breadcrumbs$: Subscription;
  breadcrumbs: Array<Breadcrumb> = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.breadcrumbs$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
    ).subscribe(event => {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.breadcrumbs$.unsubscribe();
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '',
                  breadcrumbs: Array<Breadcrumb> = []): Array<Breadcrumb> {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data[ROUTE_DATA_BREADCRUMB];
      if (!(label === null || label === undefined)) {
        breadcrumbs.push({label, url});
      }

      return this.buildBreadCrumb(child, url, breadcrumbs);
    }
  }

}
