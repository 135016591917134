<h4>Einschreibung nicht erfolgreich</h4>
<p>
  Zur Teilnahme an Verträgen zur besonderen Versorgung muss Ihre behandelnde Ärztin individuelle Verträge
  mit jeder Krankenkasse schließen. Ihre Krankenkasse bietet den gewählten Vertrag zur besonderen Versorgung
  noch nicht an oder ihr behandelnder Arzt hat diesen noch nicht abgeschlossen.
</p>
<p>
  Sollten Sie Fragen zur Teilnahme an Verträgen zur besonderen Versorgung haben, melden Sie sich bei Ihrem behandelnden
  Arzt, Ihrer Krankenkasse oder schreiben Sie uns eine E-Mail an
  <a href="mailto:service@sanakey-portal.de">service@sanakey-portal.de</a>.
</p>
