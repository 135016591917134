import {AfterContentInit, AfterViewInit, Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from 'src/environments/environment';
import {getExtradataInstanceFeatureState} from "../../reducers/userdata.reducer";
import {LoadingState} from "../../shared/reducer.includes";
import {Store} from "@ngrx/store";
import {Subscription} from "rxjs";
import {ClickEvent, StepperComponent} from "../../shared/stepper/stepper.component";
import {BenutzermanagementAPIService} from "../../api/benutzermanagement/benutzermanagement-api.service";
import {AppToastService} from "../../toast/app-toast.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";

@Component({
  selector: 'san-agb-datenschutz-aktualisierung',
  templateUrl: './agb-datenschutz-aktualisierung.component.html',
  styleUrls: ['./agb-datenschutz-aktualisierung.component.scss']
})
export class AgbDatenschutzAktualisierungComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewInit {
  @HostBinding('class.p-3') pad = true;

  environment = environment;

  @ViewChild('stepperComponent') stepperComponent: StepperComponent;

  loading = true;
  sending = false;

  anrede = '';

  nutzungAkzeptiert = false;
  datenschutzAkzeptiert = false;

  private benutzerEDISub$: Subscription;
  leftButtonDisabled = false;
  leftButtonText = '';
  rightButtonDisabled = true;
  rightButtonText = '';

  constructor(private store: Store, private bmClient: BenutzermanagementAPIService, private router: Router,
              private toastService: AppToastService, private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
  }

  init() {
    this.benutzerEDISub$ = this.store.select(getExtradataInstanceFeatureState).subscribe(value => {
      if (value?.ExtradataInstances.loadingState !== LoadingState.Loaded) return;

      const userData = {
        vorname: '',
        nachname: '',
        anrede: '',
        titel: '',
      }

      for (let d of value.ExtradataInstances.alleExtradataInstances) {
        const key = `${d.extradata.namespace}/${d.extradata.name}`;
        switch (key) {
          case 'Sanakey/Vorname':
            userData.vorname = d.value;
            break;
          case 'Sanakey/Nachname':
            userData.nachname = d.value;
            break;
          case 'Sanakey/Anrede':
            userData.anrede = d.value;
            break;
          case 'Sanakey/Titel':
            userData.titel = d.value;
            break;
        }
      }

      this.anrede = `Sehr geehrte`;

      if (userData.anrede === 'Herr') {
        this.anrede += 'r Herr'
      } else if (userData.anrede !== 'Frau') {
        this.anrede += '(r) Frau/Herr'
      }

      if (userData.titel) {
        this.anrede += ' ' + userData.titel
      }

      if (userData.nachname) {
        this.anrede += ' ' + userData.nachname
      }

      this.loading = false;
      this.goToStep1()

    })
  }

  ngOnDestroy(): void {
    this.benutzerEDISub$?.unsubscribe();
  }

  goToStep1() {
    this.stepperComponent.goToStep(0, true)
    this.leftButtonText = 'Ablehnen';
    this.rightButtonText = 'Akzeptieren';
  }

  goToStep2() {
    this.stepperComponent.goToStep(1, true)
    this.leftButtonText = 'Trotzdem ablehnen';
    this.rightButtonText = 'zurück';
  }

  calculateValidity() {
    setTimeout(() => {
      this.rightButtonDisabled = this.stepperComponent?.currentStepIndex === 0
      this.rightButtonDisabled &&= !this.nutzungAkzeptiert || !this.datenschutzAkzeptiert;
      this.rightButtonDisabled ||= this.sending
      this.leftButtonDisabled = this.sending
    })
  }

  ngAfterViewInit(): void {
    setTimeout(this.init.bind(this))
  }

  leftButtonClicked($event: ClickEvent) {
    if ($event.first) {
      // Ablehnen
      this.goToStep2()
    } else {
      // Ablehnen bestätigt
      this.logOut()
    }
  }

  rightButtonClicked($event: ClickEvent) {
    if ($event.first) {
      // Zustimmen
      this.zustimmen()
    } else {
      // Zurück
      this.goToStep1()
    }
  }

  private zustimmen() {
    this.sending = true
    this.calculateValidity()
    this.benutzerEDISub$ = this.store.select(getExtradataInstanceFeatureState).subscribe(value => {
      if (value?.ExtradataInstances.loadingState !== LoadingState.Loaded) return;

      for (let d of value.ExtradataInstances.alleExtradataInstances) {
        const key = `${d.extradata.namespace}/${d.extradata.name}`;
        if (key === 'Sanakey/AGB akzeptiert (Version)' && d.value < environment.version_agb) {
          this.bmClient.updateUserdata({id: d.id}, environment.version_agb).subscribe(
            value1 => {
              this.toastService.show('AGB akzeptiert', '', 'success')
            },
            error => {
              this.toastService.show('Fehler', 'Die AGB konnte nicht akzeptiert werden', 'danger')
            })
        }
        if (key === 'Sanakey/Datenschutzbestimmungen akzeptiert (Version)' && d.value < environment.version_datenschutz) {
          this.bmClient.updateUserdata({id: d.id}, environment.version_datenschutz).subscribe(
            value1 => {
              this.toastService.show('Datenschutzbestimmungen akzeptiert', '', 'success')
            },
            error => {
              this.toastService.show('Fehler',
                'Die Datenschutzbestimmungen konnte nicht akzeptiert werden', 'danger')
            })
        }
      }

      this.closePopup();
    });
  }

  private logOut() {
    console.warn('Force Logout - AGB oder Datenschutz nicht akzeptiert')
    this.bmClient.logOut().subscribe();
    this.router.navigateByUrl('/');
    this.closePopup();
  }

  private closePopup() {
    this.activeModal.close(false)
  }
}
