import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable, Subscriber} from 'rxjs';
import {AppToastService} from '../../toast/app-toast.service';
import {map, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {FormGroup} from '@angular/forms';
import {PSECredentials} from './models';

export interface B64FileResponse {
  b64data: string;
  filename: string;
}

export interface PreviewResponse {
  text_bedingungen: string;
  text_datenschutz: string;
  text_therapieinhalte: string;
  text_teilnahmeerklaerung: string;
  text_widerruf: string;
  beginn_teilnahmezeitraum: string;
  teilnahmeerklaerung_abrufbar: boolean;
  link_valid_until: string;
  signed: boolean;
  sign_datetime: string;
  canceled: boolean;
  sign_cancel_datetime: string;
  arzt: {
    anrede_titel_nachname: string,
    lanr: string,
    bsnr: string,
    adresse: string,
    telefonnummer: string
  };
  patient: {
    vorname: string,
    nachname: string,
    versicherten_nummer: string,
    strasse: string,
    hausnummer: string,
    plz: string,
    ort: string,
    land: string,
    geburtsdatum: string
  };
  krankenkasse: {
    name: string,
    abkuerzung: string,
    logo: string
  };
}

export interface ArztDatenResponse {
  arzt: {
    anrede_titel_nachname: string,
    lanr: string,
    bsnr: string,
    adresse: string,
    telefonnummer: string
  };
  vertrag: {
    name: string;
    text_teilnahmeerklaerung_selbsteinschreibung: string;
    text_teilnahmeerklaerung_datenschutz_selbsteinschreibung: string;
    text_bedingungen_selbsteinschreibung: string;
    text_therapieinhalte_selbsteinschreibung: string;
    text_widerruf_selbsteinschreibung: string;
    // vertragsbeginn: Date;
  };
  krankenkassen: { name: string, id: number }[];
}

export interface ArztDatenResponseMitInputForm extends ArztDatenResponse {
  input_form: FormGroup;
}

export interface PartnerPreviewResponse extends PreviewResponse {
  partner_einschreibung: {
    vorname: string,
    nachname: string,
    geburtsdatum: string,
  };
}

export interface ArztDatenResponse {
  arzt: {
    anrede_titel_nachname: string,
    lanr: string,
    bsnr: string,
    adresse: string,
    telefonnummer: string
  };
}

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  baseURL = environment.patienten_selbst_einschreibung_url;

  constructor(private store: Store,
              private toastService: AppToastService,
              private httpClient: HttpClient) {
  }

  private ensureBaseUrlAndAuthSetAction(resolve, reject, counter = 0) {
    if (this.baseURL !== null) {
      return resolve();
    }
    if (++counter >= 40) { // ~ 20 seconds
      return reject();
    }
    setTimeout(this.ensureBaseUrlAndAuthSetAction.bind(this), 500, resolve, reject, counter);
  }

  private ensureBaseUrlSet() {
    return new Promise(this.ensureBaseUrlAndAuthSetAction.bind(this));
  }

  private withBaseUrl<T>(a: () => Observable<T>) {
    let subscriber: Subscriber<T> = null;
    this.ensureBaseUrlSet().then(() => {
      a().subscribe(subscriber);
    });
    return new Observable<T>(theSubscriber => {
      subscriber = theSubscriber;
    }).pipe(take(1));
  }

  patientenSelbstEinschreibungPreview(credentials: PSECredentials) {
    return this.withBaseUrl<PreviewResponse>(() =>
      this.httpClient.post(`${this.baseURL}/patientenselbsteinschreibung/preview/`,
        {...credentials},
        {/* Options */})
        .pipe(map(r => {
          return r as PreviewResponse;
        })));
  }

  partnerSelbstEinschreibungPreview(credentials: { link_token: string, pin: string }) {
    return this.withBaseUrl<PartnerPreviewResponse>(() =>
      this.httpClient.post(`${this.baseURL}/partnerselbsteinschreibung/preview/`,
        {...credentials},
        {/* Options */})
        .pipe(map(r => {
          return r as PartnerPreviewResponse;
        })));
  }

  patientenSelbstEinschreibungTNE(credentials: {
    link_token?: string, pin?: string,
    geburtsdatum?: string, versicherten_nummer?: string, tnz_beginn?: string
  }) {
    return this.withBaseUrl<B64FileResponse>(() =>
      this.httpClient.post(`${this.baseURL}/patientenselbsteinschreibung/tne/`,
        {...credentials},
        {/* Options */})
        .pipe(map(r => {
          return r as B64FileResponse;
        })));
  }

  partnerSelbstEinschreibungTNE(credentials: { link_token: string, pin: string }) {
    return this.withBaseUrl<B64FileResponse>(() =>
      this.httpClient.post(`${this.baseURL}/partnerselbsteinschreibung/tne/`,
        {...credentials},
        {/* Options */})
        .pipe(map(r => {
          return r as B64FileResponse;
        })));
  }

  patientenSelbstEinschreibungSign(credentials: {
    link_token: string,
    pin: string,
    patient_akzeptiert_teilnahmebedingungen: boolean,
    patient_akzeptiert_datenschutzbestimmungen: boolean,
    patient_akzeptiert_sanakeyportal_nutzungsbedingungen: boolean,
    patient_akzeptiert_sanakeyportal_datenschutzbestimmungen: boolean,
  }) {
    return this.withBaseUrl<PreviewResponse>(() =>
      this.httpClient.post(`${this.baseURL}/patientenselbsteinschreibung/sign/`,
        {...credentials},
        {/* Options */})
        .pipe(map(r => {
          return r as PreviewResponse;
        })));
  }

  patientenSelbstEinschreibungSignV2(credentials: {
    uuid: string,
    versorgungsvertragId: number,
    tnz_beginn: string,
    patientenDaten: {
      versicherten_nummer: string,
      vorname: string,
      nachname: string,
      geschlecht: string,
      geburtsdatum: string,
      krankenkasse: number,
      email: string,
    },
    patient_akzeptiert_teilnahmebedingungen: boolean,
    patient_akzeptiert_datenschutzbestimmungen: boolean,
    patient_akzeptiert_sanakeyportal_nutzungsbedingungen: boolean,
    patient_akzeptiert_sanakeyportal_datenschutzbestimmungen: boolean,
  }) {
    return this.withBaseUrl<PreviewResponse>(() =>
      this.httpClient.post(`${this.baseURL}/patientenselbsteinschreibung/signv2/`,
        {...credentials},
        {/* Options */})
        .pipe(map(r => {
          return r as PreviewResponse;
        })));
  }

  partnerSelbstEinschreibungSign(credentials: {
    link_token: string,
    pin: string,
    partner_akzeptiert_teilnahmebedingungen: boolean,
    partner_akzeptiert_datenschutzbestimmungen: boolean,
    partner_akzeptiert_sanakeyportal_nutzungsbedingungen: boolean,
    partner_akzeptiert_sanakeyportal_datenschutzbestimmungen: boolean,
  }) {
    return this.withBaseUrl<PartnerPreviewResponse>(() =>
      this.httpClient.post(`${this.baseURL}/partnerselbsteinschreibung/sign/`,
        {...credentials},
        {/* Options */})
        .pipe(map(r => {
          return r as PartnerPreviewResponse;
        })));
  }

  patientenSelbstEinschreibungCancel(credentials: PSECredentials) {
    return this.withBaseUrl<PreviewResponse>(() =>
      this.httpClient.post(`${this.baseURL}/patientenselbsteinschreibung/cancel/`,
        {...credentials},
        {/* Options */})
        .pipe(map(r => {
          return r as PreviewResponse;
        })));
  }

  partnerSelbstEinschreibungCancel(credentials: {
    link_token: string,
    pin: string,
  }) {
    return this.withBaseUrl<PartnerPreviewResponse>(() =>
      this.httpClient.post(`${this.baseURL}/partnerselbsteinschreibung/cancel/`,
        {...credentials},
        {/* Options */})
        .pipe(map(r => {
          return r as PartnerPreviewResponse;
        })));
  }

  getArztDaten(uuid: string, versorgungsVertragID: number) {
    return this.withBaseUrl<ArztDatenResponse>(() =>
      this.httpClient.post(`${this.baseURL}/patientenselbsteinschreibung/arztDaten/`,
        {uuid, versorgungsVertragID},
        {/* Options */})
        .pipe(map(r => {
          const ret = r as ArztDatenResponse;
          ret.krankenkassen.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
          return ret;
        })));
  }
}
